import { isClientSide } from "@keepeek/commons";
import {
  BasketKey,
  BusinessFilter,
  BusinessFilterNature,
  DataViewKey,
  KpkFilterModifierType,
  References,
} from "@keepeek/commons";
import { atomFamily } from "recoil";

import { convertToLightBusinessFilter, decodeFilters } from "../../lib/business-filter-utils";
import { localStorageEffect } from "../../lib/recoil-persist";
import { Filter } from "../../models/configuration/components/businessFilter";
import { getParameterByName } from "../dataView/utils";

/**
 * Represents a light version of a BusinessFilter with the strict minimum to optimize the size of the URL in which this filter is stored.
 */
export type LightBusinessFilter = {
  // Nature
  n: BusinessFilterNature;
  // Filter
  f: LightKpkFilter;
  // Hide
  h?: boolean;
};
/**
 * Represents a light version of a KpkFilter with the strict minimum to optimize the size of the URL in which this filter is stored.
 */
type LightKpkFilter = {
  //  ID
  i: string;
  // Label (only for facet)
  l?: string;
  // Values
  v: Array<LightKpkFilterValue>;
  // Modifier type
  m: KpkFilterModifierType | undefined;
};
/**
 * Represents a light version of a KpkFilterValue with the strict minimum to optimize the size of the URL in which this filter is stored.
 */
export type LightKpkFilterValue = {
  // ID
  i: any;
  // Label
  l: string;
  // Title
  t?: string;
  // selected
  s: boolean | null;
};

export const businessFilterShow = atomFamily<boolean, DataViewKey>({
  key: "BusinessFilterShow",
  default: false,
  effects: (key) => [localStorageEffect(`BusinessFilterShow-${key}`)],
});

export const businessFilterConfiguration = atomFamily<Filter[], DataViewKey>({
  key: "BusinessFilterConfiguration",
  default: [],
});

export const lightBusinessFilterActiveFilters = atomFamily<
  LightBusinessFilter[],
  DataViewKey | BasketKey
>({
  key: "LightBusinessFilterActiveFilters",
  default: (key) => {
    // Load the current filter on first print
    if (key === DataViewKey.SEARCH) {
      if (isClientSide()) {
        // first, try to find if old url parameter is used (if ever URLs had been bookmarked or used in widgets for example)
        const oldUrlParameter = getParameterByName("f");
        if (oldUrlParameter) {
          return convertToLightBusinessFilter(
            decodeFilters<BusinessFilter[]>(oldUrlParameter) || [],
          );
        } else {
          // no old URL parameter => let's extract from new parameter "lbf"
          return decodeFilters<LightBusinessFilter[]>(getParameterByName("lbf") || "") || [];
        }
      }
    }
    return [];
  },
});

export const expandedBusinessFiltersAtom = atomFamily<
  BusinessFilter["filter"]["id"][],
  DataViewKey
>({
  key: "ExpandedBusinessFilters",
  default: [],
  effects: (key) => [localStorageEffect(`ExpandedBusinessFilters-${key}`)],
});

export const uniqueReferencesAtom = atomFamily<References, DataViewKey>({
  key: "UniqueReferencesAtom",
  default: {},
});
