import { useEffect, useState } from "react";

import { buildThemeWithLegacyOptions } from "@keepeek/refront-components";
import { CustomerAppTheme } from "@keepeek/refront-customers";
import { Theme } from "@mui/material";
import { ThemeOptions } from "@mui/material/styles/createTheme";
import { deepmerge } from "@mui/utils";
import { colord, extend } from "colord";
import namesPlugin from "colord/plugins/names";
import * as CSS from "csstype";
import cloneDeep from "lodash/cloneDeep";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";

import { CONFIGURATION_SECTION_PATH } from "../components/layout/adminMenu";
import { Theme as AdminThemeConfiguration } from "../models/configuration/theme";
import { useConfiguration } from "../providers/config/hooks";
import { customerAppThemeSelector } from "../providers/config/selectors/init";
import useFrontEdit from "../providers/frontEdit/hooks/useFrontEdit";
import { getCurrentContentEditsSelector } from "../providers/frontEdit/selectors/currentContentEdits";
import logger from "./logger-utils";
// eslint-disable-next-line jest/require-hook
extend([namesPlugin]);

const buildCustomerTheme = (
  customerAppTheme: CustomerAppTheme | undefined,
  adminThemeConfiguration: AdminThemeConfiguration | undefined,
): Theme => {
  const { primary, secondary, error, text, borderRadius, extendThemeOptions } =
    adminThemeConfiguration ?? {};
  const adminThemeOptions: ThemeOptions = {
    palette: {
      ...(primary &&
        primary.main &&
        colord(primary.main).isValid() && {
          primary: {
            main: colord(primary.main).toHex(),
          },
        }),

      ...(secondary &&
        secondary.main &&
        colord(secondary.main).isValid() && {
          secondary: {
            main: colord(secondary.main).toHex(),
          },
        }),

      ...(error &&
        error.main &&
        colord(error.main).isValid() && {
          error: {
            main: colord(error.main).toHex(),
          },
        }),

      ...(text &&
        (text.primary || text.secondary || text.disabled || text.hint) && {
          text: {
            ...(text.primary &&
              colord(text.primary).isValid() && {
                primary: colord(text.primary).toHex(),
              }),
            ...(text.secondary &&
              colord(text.secondary).isValid() && {
                secondary: colord(text.secondary).toHex(),
              }),
            ...(text.disabled &&
              colord(text.disabled).isValid() && {
                disabled: colord(text.disabled).toHex(),
              }),
            ...(text.hint &&
              colord(text.hint).isValid() && {
                hint: colord(text.hint).toHex(),
              }),
          },
        }),
    },
    shape: {
      ...(borderRadius && { borderRadius }),
    },
    // historical overrides... i don't know why :
    components: {
      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: ".25rem",
          },
        },
      },
    },
  };

  let themeOptions = deepmerge<ThemeOptions>(
    customerAppTheme?.materialThemeOptions ?? {},
    adminThemeOptions,
  );
  // are there overloads of the theme from the admin front?
  if (extendThemeOptions) {
    try {
      themeOptions = deepmerge<ThemeOptions>(themeOptions, JSON.parse(extendThemeOptions));
    } catch (error) {
      logger.error(`buildCustomerTheme - extendThemeOptions is invalid to parse: ${error}`);
    }
  }
  try {
    return buildThemeWithLegacyOptions(themeOptions);
  } catch (error) {
    logger.error("buildCustomerTheme - extendThemeOptions is invalid", error);
  }
  return buildThemeWithLegacyOptions();
};

/**
 * Hook allowing to return a theme, a theme without font and only the fonts
 */
export type UseCustomerTheme = {
  theme: Theme;
  themeWithoutCustomFonts: Theme;
  customFonts: CSS.AtRule.FontFace[] | undefined;
};
export const useCustomerTheme = (): UseCustomerTheme => {
  const customerThemeQuery = useRecoilValueLoadable(customerAppThemeSelector);
  const [customerTheme, setCustomerTheme] = useState<CustomerAppTheme | undefined>(undefined);
  let adminThemeConfiguration = useConfiguration<AdminThemeConfiguration>(
    CONFIGURATION_SECTION_PATH.THEME,
  );
  useEffect(() => {
    if (customerThemeQuery.state === "hasValue") {
      setCustomerTheme(customerThemeQuery.contents);
    }
  }, [customerThemeQuery.contents, customerThemeQuery.state]);

  const { frontEdit } = useFrontEdit();
  const currentContentEdit = useRecoilValue(
    getCurrentContentEditsSelector({
      endpoint: `${CONFIGURATION_SECTION_PATH.THEME}`,
    }),
  );
  const currentEditData = currentContentEdit ? currentContentEdit.data : customerTheme;

  if (frontEdit) {
    adminThemeConfiguration = {
      ...adminThemeConfiguration,
      primary: {
        ...adminThemeConfiguration?.primary,
        ...(colord(currentEditData.primary?.main).isValid() && {
          main: colord(currentEditData.primary.main).toHex(),
        }),
      },
      secondary: {
        ...adminThemeConfiguration?.secondary,
        ...(colord(currentEditData.secondary?.main).isValid() && {
          main: colord(currentEditData.secondary.main).toHex(),
        }),
      },
      error: {
        ...adminThemeConfiguration?.error,
        ...(colord(currentEditData.error?.main).isValid() && {
          main: colord(currentEditData.error.main).toHex(),
        }),
      },
      text: {
        ...adminThemeConfiguration?.text,
        ...(colord(currentEditData.text?.primary).isValid() && {
          primary: colord(currentEditData.text.primary).toHex(),
        }),
        ...(colord(currentEditData.text?.secondary).isValid() && {
          secondary: colord(currentEditData.text.secondary).toHex(),
        }),
        ...(colord(currentEditData.text?.disabled).isValid() && {
          disabled: colord(currentEditData.text.disabled).toHex(),
        }),
        ...(colord(currentEditData.text?.hint).isValid() && {
          hint: colord(currentEditData.text.hint).toHex(),
        }),
      },
    };
  }

  const theme = buildCustomerTheme(customerTheme, adminThemeConfiguration);
  let customFonts: CSS.AtRule.FontFace[] | undefined;
  const themeWithoutCustomFonts = cloneDeep(theme);
  if (theme.components?.MuiCssBaseline?.styleOverrides?.hasOwnProperty("@font-face")) {
    const tempFonts = theme?.components?.MuiCssBaseline?.styleOverrides?.["@font-face"];
    customFonts = Array.isArray(tempFonts) ? tempFonts : [tempFonts];
    delete themeWithoutCustomFonts?.components?.MuiCssBaseline?.styleOverrides?.["@font-face"];
  }
  return {
    theme,
    themeWithoutCustomFonts,
    customFonts,
  };
};
