// This key is also defined in next.config.js to have a default value
// but this file is not in TS, se we keep it here for usage in code
export const DIDOMI_API_KEY = "1f37e84d-382f-45aa-a203-ad18d173d704";

export enum DidomiVendorId {
  Sentry = "c:sentry",
  Google = "google",
  GoogleAnalytics = "c:googleana-4TXnJigR",
}

export enum DidomiNotice {
  Sentry = "ZRn8D2A7",
  GoogleAnalytics = "4qC44FA7",
  SentryAndGoogleAnalytics = "cy76qZwZ",
}
