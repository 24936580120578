import { isClientSide, IsJsonString } from "@keepeek/commons";
import { AtomEffect, DefaultValue } from "recoil";

const localStorageEffect =
  (key: string): AtomEffect<any> =>
  ({ setSelf, onSet }) => {
    if (isClientSide()) {
      const savedValue = localStorage.getItem(key);

      if (savedValue !== null && IsJsonString(savedValue)) {
        setSelf(JSON.parse(savedValue));
      }

      onSet((newValue: any) => {
        if (newValue instanceof DefaultValue) {
          localStorage.removeItem(key);
        } else {
          localStorage.setItem(key, JSON.stringify(newValue));
        }
      });
    }
  };

export { localStorageEffect };
