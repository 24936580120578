import { IncomingMessage, ServerResponse } from "http";

import { getDam, KpkApiUser } from "@keepeek/api-client";
import {
  API_NOT_ALLOWED,
  checkUserIsTech,
  ERROR_CODE_QUERY_PARAM,
  JWT_TOKEN_IS_EMPTY_MSG,
} from "@keepeek/commons";
import { KeycloakConfig } from "keycloak-js";
import getConfig from "next/config";

import { CONFIGURATION_SECTION_PATH } from "../components/layout/adminMenu";
import { Global } from "../models/configuration/global";
import { getAxiosServerInstance } from "./axios/axios-utils";
import { JsonConfig } from "./commonPagePropsUtil";
import { getAdminConfigSectionData } from "./config/customer-config-utils";
import logger from "./logger-utils";
import Problem from "./problems/Problem";

export async function checkTechAdminAccess(
  req: IncomingMessage,
  res: ServerResponse,
): Promise<KpkApiUser> {
  try {
    const jwtToken = await getAndCheckJwtToken(req, res);
    return checkTechAdminAccessWithToken(jwtToken);
  } catch (error) {
    throw new Problem("about:blank", "Forbidden", 403);
  }
}

export async function checkTechAdminAccessWithToken(jwtToken: string): Promise<KpkApiUser> {
  try {
    // Locale is not important here
    const user = await checkUserIsTech(
      getAxiosServerInstance({
        jwtToken,
      }),
    );
    return user;
  } catch (error) {
    throw new Problem("about:blank", "Forbidden", 403);
  }
}

export async function getAndCheckJwtToken(
  req: IncomingMessage,
  _res: ServerResponse,
): Promise<string> {
  const jwtToken = req.headers.authorization;
  if (!jwtToken) {
    throw new Error(JWT_TOKEN_IS_EMPTY_MSG);
  }
  const damAccess = await getDam(getAxiosServerInstance({ jwtToken }));
  if (damAccess.status !== 200) {
    throw new Error(API_NOT_ALLOWED);
  }
  return jwtToken;
}

/**
 * Redirect to index page or custom URL depending of error code.
 * to be called only from "getServerSideProps".
 * @param errorCode error code
 * @param resolvedUrl server side resolvedUrl, it will be used to redirect user to initialy targeted URL after Keycloak authentication
 * @param customerConfig admin customer configuration
 */
export function redirectToIndexOrCustomUrl(
  errorCode: string,
  resolvedUrl: string,
  customerConfig: JsonConfig[],
) {
  const redirectUrl = `/?pathRedirect=${resolvedUrl}&${ERROR_CODE_QUERY_PARAM}=${encodeURIComponent(
    errorCode,
  )}`;
  const customRedirectUrl = getCustomRedirectUrl(errorCode, customerConfig);
  return {
    redirect: {
      destination: customRedirectUrl || redirectUrl,
      permanent: false,
    },
  };
}

function getCustomRedirectUrl(errorCode: string, customerConfig: JsonConfig[]): string | null {
  if (errorCode === API_NOT_ALLOWED) {
    const configSectionGlobal = getAdminConfigSectionData<Global>(
      customerConfig,
      CONFIGURATION_SECTION_PATH.GLOBAL,
    );
    if (configSectionGlobal?.keycloakConfiguration?.customUrlNotAllowed) {
      // this case is currently used in the context with a compass page where we want to redirect the user to the compass page rather than the landing page
      try {
        const customUrl = new URL(configSectionGlobal?.keycloakConfiguration?.customUrlNotAllowed);
        customUrl.searchParams.append(ERROR_CODE_QUERY_PARAM, API_NOT_ALLOWED);
        return customUrl.href;
      } catch (err) {
        logger.error("can't build custom URL for API_NOT_ALLOWED redirect");
      }
    }
  }
  return null;
}

/**
 * Redirect to home page.
 * to be called only from "getServerSideProps".
 */
export function redirectToHome() {
  return {
    redirect: {
      destination: `/home`,
      permanent: false,
    },
  };
}

// Get KC config
const { publicRuntimeConfig } = getConfig();

export function getKeycloakConfig(): KeycloakConfig | undefined {
  logger.debug("getKeycloakConfig - init with", publicRuntimeConfig.keycloak);
  if (
    !publicRuntimeConfig.keycloak ||
    !publicRuntimeConfig.keycloak.url ||
    !publicRuntimeConfig.keycloak.clientId ||
    !publicRuntimeConfig.keycloak.realm
  ) {
    logger.debug("getKeycloakConfig - config undefined");
    return undefined;
  }
  return {
    url: publicRuntimeConfig.keycloak.url,
    clientId: publicRuntimeConfig.keycloak.clientId,
    realm: publicRuntimeConfig.keycloak.realm,
  };
}
