import { useEffect } from "react";

import { removeUndefinedDeep, useCurrentUser } from "@keepeek/commons";
import * as Sentry from "@sentry/nextjs";
import { useRouter } from "next/router";

import { initSentryClient } from "../../../lib/sentry";
import { useCurrentLocale } from "../../i18n/hooks";
import { useDidomi } from "../didomi/hooks";
import { DidomiVendorId } from "../didomi/utils";

export const useSentry = () => {
  // Check if we can activate Sentry from Didomi feedback
  const { CWT, didomi } = useDidomi();
  useEffect(() => {
    if (didomi?.getUserConsentStatusForVendor(DidomiVendorId.Sentry)) {
      Sentry.init(initSentryClient);
    } else {
      // for anything else, deactivate Sentry
      // We cannot run Sentry without consent
      Sentry.init({ dsn: "" });
    }
    // Listen to CWT because it will reload the check when the user give consent or remove it
  }, [didomi, CWT]);

  // Track router
  const router = useRouter();
  useEffect(() => {
    Sentry.addBreadcrumb({
      category: "pathname",
      message: router.pathname,
      level: "info",
      data: router,
    });
  }, [router]);

  // Track locale
  const locale = useCurrentLocale();
  useEffect(() => {
    Sentry.addBreadcrumb({
      category: "locale",
      message: locale,
      level: "info",
    });
  }, [locale]);

  // Track current user
  const { currentUser } = useCurrentUser();
  useEffect(() => {
    // Be sure to track the user only when we have consent #doublecheck
    if (currentUser.id && didomi?.getUserConsentStatusForVendor(DidomiVendorId.Sentry)) {
      const sentryUser: Sentry.User = {
        ...removeUndefinedDeep(currentUser),
        id: `${currentUser.id}`,
      };
      Sentry.setUser(sentryUser);
    } else {
      // Flush user when not authenticated
      Sentry.configureScope((scope) => scope.setUser(null));
    }
    // Listen to CWT because it will reload the check when the user give consent or remove it
  }, [currentUser, CWT, didomi]);
};
