export interface FieldsSections {
    default?:       FieldsSectionsItemSchema[];
    metamodel?:     Metamodel[];
    metamodelType?: MetamodelTypeObject[];
}

export interface FieldsSectionsItemSchema {
    type:  Type;
    value: any;
}

export enum Type {
    Field = "field",
    Section = "section",
}

export interface Metamodel {
    fieldsSections: FieldsSectionsItemSchema[];
    metamodelId:    number[];
}

export interface MetamodelTypeObject {
    fieldsSections: FieldsSectionsItemSchema[];
    metamodelType:  MetamodelTypeEnum[];
}

export enum MetamodelTypeEnum {
    Document = "DOCUMENT",
    Other = "OTHER",
    Picture = "PICTURE",
    Sound = "SOUND",
    Video = "VIDEO",
}
