import React, { FC, useEffect, useState } from "react";

import { isClientSide } from "@keepeek/commons";
import { useRouter } from "next/router";
import Script from "next/script";

import { CONFIGURATION_SECTION_PATH } from "../../components/layout/adminMenu";
import { Global } from "../../models/configuration/global";
import { useConfiguration } from "../../providers/config/hooks";
import { useDidomi } from "../../providers/tools/didomi/hooks";
import { DidomiVendorId } from "../../providers/tools/didomi/utils";

type GoogleAnalyticsProps = {};

const GoogleAnalytics: FC<GoogleAnalyticsProps> = function () {
  const router = useRouter();

  const globalConf = useConfiguration<Global>(CONFIGURATION_SECTION_PATH.GLOBAL);
  const googleAnalyticsId = globalConf?.googleAnalyticsId;
  const [canGA, setCanGA] = useState<boolean>(false);
  const { didomi, CWT } = useDidomi();

  useEffect(() => {
    if (didomi?.getUserConsentStatusForVendor(DidomiVendorId.GoogleAnalytics)) {
      setCanGA(true);
    } else {
      setCanGA(false);
    }
  }, [didomi, CWT]);

  // Track router change
  useEffect(() => {
    function eventHandler(url: string) {
      if (
        googleAnalyticsId &&
        isClientSide() &&
        typeof (window as any).gtag !== "undefined" &&
        canGA
      ) {
        (window as any).gtag.pageview(url);
      }
    }

    router.events.on("routeChangeComplete", eventHandler);
    return router.events.off("routeChangeComplete", eventHandler);
  }, [canGA, googleAnalyticsId, router]);

  if (!googleAnalyticsId) {
    return null;
  }

  return (
    <>
      <Script
        id="googleAnalyticsGTag"
        key="googleAnalyticsGTag"
        async
        type="didomi/javascript"
        data-vendor={DidomiVendorId.GoogleAnalytics}
        src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`}
      />
      <Script
        id="googleAnalyticsDataLayer"
        key="googleAnalyticsDataLayer"
        type="didomi/javascript"
        data-vendor={DidomiVendorId.GoogleAnalytics}
        dangerouslySetInnerHTML={{
          __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${googleAnalyticsId}', {
          page_path: window.location.pathname,
          anonymize_ip: true
        });
      `,
        }}
      />
    </>
  );
};

export default GoogleAnalytics;
