import { NextjsOptions } from "@sentry/nextjs/types/utils/nextjsOptions";
import { SamplingContext } from "@sentry/types";
import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig();

import { BUILD_TIMESTAMP } from "../../build-timestamp";

// Change this var to true to allow Sentry to track events in dev mode
// eslint-disable-next-line jest/require-hook
let iAmCurrentlyTestingSentry: boolean = publicRuntimeConfig.sentry.devTest;
// Be sure to override nothing in other mode
if (process.env.NODE_ENV !== "development") {
  iAmCurrentlyTestingSentry = false;
}

export const environmentSentry = (): string => {
  if (process.env.NODE_ENV === "development") {
    return "development";
  }
  if (publicRuntimeConfig.customerId === "keepeek") {
    return "staging";
  }
  return "production";
};

export const dsnSentry = (): string => {
  // do not track in dev
  if (process.env.NODE_ENV === "development" && !iAmCurrentlyTestingSentry) {
    return "";
  }
  return (
    publicRuntimeConfig.sentry.dsn ||
    "https://b0e49e62320e486a8c435ac8819d2d4d@o1112474.ingest.sentry.io/6143626"
  );
};

// See this doc: https://gist.github.com/impressiver/5092952
// Ignore list based off: https://gist.github.com/1878283
const ravenOptions = {
  // Will cause a deprecation warning, but the demise of `ignoreErrors` is still under discussion.
  // See: https://github.com/getsentry/raven-js/issues/73
  ignoreErrors: [
    // Random plugins/extensions
    "top.GLOBALS",
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose",
    // Facebook borked
    "fb_xd_fragment",
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
    // Generic error code from errors outside the security sandbox
    // You can delete this if using raven.js > 1.0, which ignores these automatically.
    "Script error.",
  ],
  ignoreUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    // Api Health Check
    /api\/health/i,
  ],
};

const tracesSampler = (samplingContext: SamplingContext): number => {
  if (process.env.NODE_ENV === "development" && !iAmCurrentlyTestingSentry) {
    return 0;
  }
  // Reject any "/api/health" related
  if (samplingContext.transactionContext.name == "GET /api/health") {
    return 0; // never send transactions with name GET /api/health
  }
  return publicRuntimeConfig.sentry.tracesSampleRate || 0.2;
};

export const initSentryClient: NextjsOptions = {
  dsn: dsnSentry(),
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampler,
  debug: publicRuntimeConfig.sentry.debug || false,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  release: publicRuntimeConfig.coreVersion,
  environment: environmentSentry(),
  initialScope: {
    tags: {
      version: publicRuntimeConfig.coreVersion,
      buildTimestamp: BUILD_TIMESTAMP,
      customerId: publicRuntimeConfig.customerId,
      apiEndPoint: publicRuntimeConfig.apiEndpoint,
      environmentColor: publicRuntimeConfig.environment,
    },
  },
  beforeSend: (event) => {
    // Reject any "/api/health" related
    if (JSON.stringify(event).includes("/api/health")) {
      return null;
    }
    return event;
  },
  ...ravenOptions,
};

export const initSentryServer: NextjsOptions = {
  ...initSentryClient,
};
