import {
  createResourceFile,
  deleteResourceFile,
  replaceResourceFile,
  getResourceFile,
} from "@keepeek/api-client";
import { useCurrentUser } from "@keepeek/commons";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useRecoilStateLoadable } from "recoil";

import { getAxiosClientInstance } from "../../../lib/axios/axios-utils";
import logger from "../../../lib/logger-utils";
import { resourcesQuerySelector } from "../selector";

export type KpkResource = {
  url: string;
  uuid: string;
  fileName: string;
};

export type Resource = {
  url?: string;
  id: string;
  fileName?: string;
};

export type KpkFileResource = {
  file: {
    url: string;
    uuid: string;
    fileName: string;
  };
};

export const useFileResource = (): {
  resources: { resources: Resource[] | undefined; totalCount: number | undefined };
  createResource: (file: Blob, type: string, id: string) => Promise<string | null | undefined>;
  replaceResource: (file: Blob, type: string, id: string) => Promise<string | null | undefined>;
  removeResource: (type: string, id: string, fileName?: string) => void;
  getUrlResource: (type: string, id: string) => Promise<string>;
} => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [resourceQuery, setResources] = useRecoilStateLoadable(resourcesQuerySelector);
  const {
    resources,
    totalCount,
  }: { resources: Resource[] | undefined; totalCount: number | undefined } =
    resourceQuery.state === "hasValue"
      ? resourceQuery.contents
      : { resources: undefined, totalCount: undefined };
  const { loadingAssignedGroups, isTech } = useCurrentUser();
  const createResource = async (
    file: Blob,
    type: string,
    id: string,
  ): Promise<string | undefined | null> => {
    try {
      if (!isTech && !loadingAssignedGroups) {
        logger.error("No rights to create a resource");
        return null;
      }

      if (!id) {
        logger.info("Can't create a resource without id");
        return;
      }

      if (!type) {
        logger.info("Can't create a resource without type");
        return;
      }

      const result = await createResourceFile(getAxiosClientInstance(), {
        path: id,
        type,
        file,
        axiosRequestConfig: { headers: { "Content-Type": file.type } },
      });

      const url: string = result.headers.location;
      const newResources = [
        ...(resources ? resources : []),
        { id, url, fileName: url.substring(url.lastIndexOf("/") + 1) },
      ];
      setResources({ resources: newResources, totalCount });

      return url;
    } catch (error) {
      if (error.response && error.response.status === 415) {
        enqueueSnackbar(t("admin:resource.invalid-type.error"), {
          variant: "warning",
        });
      } else {
        enqueueSnackbar(t("admin:resource.upload.error"), {
          variant: "error",
        });
      }
    }
  };
  const replaceResource = async (
    file: Blob,
    type: string,
    id: string,
  ): Promise<string | null | undefined> => {
    if (!isTech && !loadingAssignedGroups) {
      logger.error("No rights to replace a resource");
      return null;
    }
    if (!id) {
      logger.info("Can't replace a resource without id");
      return;
    }

    if (!type) {
      logger.info("Can't replace a resource without type");
      return;
    }

    try {
      const result = await replaceResourceFile(getAxiosClientInstance(), {
        path: id,
        type,
        file,
        axiosRequestConfig: { headers: { "Content-Type": file.type } },
      });
      const url = result.headers.location;
      if (!resources?.find((r) => r.id === id)) {
        const newResources = [...(resources ? resources : []), { id, url }];
        setResources({ resources: newResources, totalCount });
      }
      return url;
    } catch (error) {
      if (error.response && error.response.status === 415) {
        enqueueSnackbar(t("admin:resource.invalid-type.error"), {
          variant: "warning",
        });
      } else {
        enqueueSnackbar(t("admin:resource.upload.error"), {
          variant: "error",
        });
      }
    }
  };
  const removeResource = async (type: string, id: string, fileName?: string) => {
    if (!isTech && !loadingAssignedGroups) {
      logger.error("No rights to remove a resource");
      return null;
    }
    if (!id) {
      logger.info("Can't remove a resource without id");
      return;
    }
    if (!type) {
      logger.info("Can't remove a resource without type");
      return;
    }
    try {
      await deleteResourceFile(getAxiosClientInstance(), {
        path: id,
        type: type,
        fileName,
      });
      const newResources: Resource[] | undefined = resources?.filter(
        (r) => r.fileName !== fileName,
      );
      setResources({ resources: newResources, totalCount });
    } catch (error) {
      logger.error("Error when remove resource: ", error);
    }
  };

  const getUrlResource = async (type: string, id: string) => {
    if (!id) {
      logger.info("Can't get a resource without id");
      return;
    }
    if (!type) {
      logger.info("Can't get a resource without type");
      return;
    }
    try {
      const result = await getResourceFile(getAxiosClientInstance(), {
        path: id,
        type,
      });

      return result.request.responseURL;
    } catch (error) {
      logger.error("Error when get resource: ", error);
    }
  };

  return {
    resources: { resources, totalCount },
    createResource,
    replaceResource,
    removeResource,
    getUrlResource,
  };
};
