import {
  customerCommonOverrideAtom,
  FetcherMode,
  fetcherModeAtom,
  fetcherTokenIdAtom,
  getAxiosInstanceAtom,
  getLoggerAtom,
  useShare,
} from "@keepeek/commons";
import { useRouter } from "next/router";
import { SetRecoilState } from "recoil";

import { getAxiosClientInstance } from "../../lib/axios/axios-utils";
import { CommonPageProps } from "../../lib/commonPagePropsUtil";
import { getCustomerConfig } from "../../lib/config/customer-config-utils";
import logger from "../../lib/logger-utils";
import { initConfigApiAtom } from "../config/atoms/init";

export const useMandatoryInitialRecoilState = (
  pageProps: CommonPageProps,
): {
  setMandatoryInitializeRecoilState: (set: SetRecoilState) => void;
} => {
  const router = useRouter();
  const { isShare, tokenId } = useShare(router.pathname, router.query.tokenId);

  function setMandatoryInitializeRecoilState(set: SetRecoilState) {
    // Set logger mandatory information:
    set(getLoggerAtom, () => () => logger as unknown as Console);
    // Set axios mandatory information:
    set(getAxiosInstanceAtom, () => () => getAxiosClientInstance());
    // Set fetcher mandatory information:
    let mode = FetcherMode.LOGGED;
    if (isShare) {
      mode = FetcherMode.SHARE;
    }
    set(fetcherModeAtom, mode);
    set(fetcherTokenIdAtom, tokenId);
    set(customerCommonOverrideAtom, getCustomerConfig().overrides);

    set(initConfigApiAtom, {
      clientIp: pageProps.clientIp,
      theme: pageProps.theme,
      config: pageProps.config,
    });
  }

  return {
    setMandatoryInitializeRecoilState,
  };
};
