import { atom } from "recoil";

export type CurrentEditKey = {
  configSection: string;
  widgetId?: string;
};

export const frontEditCurrentEditKeysState = atom<CurrentEditKey[]>({
  key: "FrontEditCurrentEditKeysState",
  default: [],
});
