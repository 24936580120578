import { FC, useEffect } from "react";

import { BaseComponentProps } from "@keepeek/refront-components";
import * as Sentry from "@sentry/nextjs";
import { OptionsObject, useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import { adaptAxiosHttpError, getAxiosClientInstance } from "../../lib/axios/axios-utils";
import { Message } from "../../lib/commonPagePropsUtil";

export type KErrorsProps = {
  initialMessages?: Message[];
} & BaseComponentProps;

/**
 * Manage ReFront errors
 */
const KError: FC<KErrorsProps> = function ({ initialMessages, children }) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const snackbarOpt: OptionsObject = {
    preventDuplicate: true,
  };

  // On init if we have a message from SSR
  // Pop a snackbar
  if (initialMessages) {
    initialMessages.forEach((m) => {
      let message: string | undefined;
      if (m.i18nKey) {
        message = t(m.i18nKey);
      } else {
        message = m.text;
      }
      Sentry.captureException({ m, message });
      enqueueSnackbar(message, {
        ...snackbarOpt,
        variant: m.type || "default",
        autoHideDuration: m.autoHideDurationMs ? m.autoHideDurationMs : 10000,
      });
    });
  }

  // We use a useEffect to have access to snackbar and translation more easily
  // But !!! be sure to avoid stacking interceptors creation
  useEffect(() => {
    if (!(global as any).AXIOS_INTERCEPTORS_KERROR) {
      getAxiosClientInstance().interceptors.response.use(
        (response) => response,
        (error) => {
          const adaptedHttpError = adaptAxiosHttpError(error);
          if (adaptedHttpError) {
            enqueueSnackbar(t(adaptedHttpError.errorKey, error), {
              ...snackbarOpt,
              ...adaptedHttpError.snackbarOpt,
            });
          }
          return Promise.reject(error);
        },
      );
      (global as any).AXIOS_INTERCEPTORS_KERROR = "init";
    }
    // Interceptor can stack over and over, so we don't want it to be refreshed by any deps
    // Disable deps to be sure that it won't stack
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};

export default KError;
