import {
  createAdvancedSearch,
  createPublicShareAdvancedSearch,
  getAdvancedSearch,
  getPublicShareAdvancedSearch,
  KpkApiAdvancedSearch,
  KpkApiAdvancedSearchFilter,
  KpkApiGetAdvancedSearchParams,
  KpkApiPagedResult,
  KpkApiSearchMediasEmbeddedMapping,
} from "@keepeek/api-client";
import {
  buildParamsForShare,
  FetcherMode,
  fetcherTokenIdAtom,
  shareTokenAxiosHeadersSelector,
  isAuthenticatedSelector,
} from "@keepeek/commons";
import { AxiosResponse } from "axios";
import { selectorFamily } from "recoil";

import { getAxiosClientInstance } from "../../../lib/axios/axios-utils";

export type DataviewFetcher = {
  /**
   * Fetcher to create an advanced search
   */
  createAdvancedSearch: (
    filter: KpkApiAdvancedSearchFilter[],
  ) => Promise<AxiosResponse<KpkApiAdvancedSearch>> | Promise<undefined>;
  /**
   * Fetcher to get advanced search result
   */
  getAdvancedSearch: (
    searchParams: KpkApiGetAdvancedSearchParams,
  ) =>
    | Promise<AxiosResponse<KpkApiPagedResult<KpkApiSearchMediasEmbeddedMapping>>>
    | Promise<undefined>;
};

/**
 * Dataview fetcher with default to logged api paths
 */
export const dataViewFetcherSelector = selectorFamily<DataviewFetcher, FetcherMode>({
  key: "DataViewFetcherSelector",
  get:
    (mode) =>
    ({ get }) => {
      switch (mode) {
        case FetcherMode.SHARE:
          const tokenId = get(fetcherTokenIdAtom);
          const headers = get(shareTokenAxiosHeadersSelector);

          return {
            createAdvancedSearch: (filter) => {
              return createPublicShareAdvancedSearch(
                getAxiosClientInstance(),
                buildParamsForShare(
                  {
                    filter,
                  },
                  headers,
                  tokenId,
                ),
              );
            },
            getAdvancedSearch: (params) => {
              return getPublicShareAdvancedSearch(
                getAxiosClientInstance(),
                buildParamsForShare(params, headers, tokenId),
              );
            },
          };

        default:
          const isAuthenticated = get(isAuthenticatedSelector);
          if (!isAuthenticated) {
            return {
              createAdvancedSearch: () => Promise.resolve(undefined),
              getAdvancedSearch: () => Promise.resolve(undefined),
            };
          }

          return {
            createAdvancedSearch: (filter) => {
              return createAdvancedSearch(getAxiosClientInstance(), {
                filter,
              });
            },
            getAdvancedSearch: (searchParams) => {
              return getAdvancedSearch(getAxiosClientInstance(), searchParams);
            },
          };
      }
    },
});
