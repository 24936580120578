import {
  KpkApiAdvancedSearchCriteria,
  KpkApiAdvancedSearchFilter,
  KpkApiFacet,
} from "@keepeek/api-client";
import { KpkMedia, BusinessFilter, BusinessFilterNature, KpkFilterType } from "@keepeek/commons";

import { convertToKpkApiAdvancedSearchFilter } from "../business-filter-utils";

export type SearchId = null | {
  advancedSearchId: string | null;
  expirationDate: string | undefined;
};

export type ParsedSearchResult = {
  medias: KpkMedia[];
  facets: Array<KpkApiFacet>;
  totalCount: number;
  searchId: SearchId;
};

export enum FqType {
  Facet = "facet:",
  Basket = "basketId:",
}

export function extractAdvancedFilters(
  filters: Array<BusinessFilter>,
  criterias: KpkApiAdvancedSearchCriteria[],
): KpkApiAdvancedSearchFilter[] {
  const ret: KpkApiAdvancedSearchFilter[] = [];
  if (filters.length === 0) {
    return ret;
  }
  filters.forEach((filter) => {
    if (filter.nature === BusinessFilterNature.ADVANCED) {
      const kpkApiAdvancedFilter = convertToKpkApiAdvancedSearchFilter(filter.filter, criterias);
      if (kpkApiAdvancedFilter) {
        ret.push(kpkApiAdvancedFilter);
      }
    }
  });

  return ret;
}

export function hasCriterias(filters?: BusinessFilter[]): boolean {
  if (!filters || filters.length === 0) {
    return false;
  }
  return filters.some(
    (f) => f.nature === BusinessFilterNature.ADVANCED && f.filter?.values?.length > 0,
  );
}

export function extractQueryFiltersForFieldParameter(filters?: BusinessFilter[]): string[] {
  if (!filters || filters.length === 0) {
    return [];
  }

  return filters.reduce((acc: string[], f: BusinessFilter) => {
    switch (f.nature) {
      case BusinessFilterNature.ADVANCED: {
        if (f.filter && f.filter.values && f.filter.type === KpkFilterType.Text) {
          f.filter.values.forEach((v) => {
            acc.push(`${f.filter.id}:${v.label}`);
          });
        }
        break;
      }
      default:
        break;
    }
    return acc;
  }, []);
}

export function extractQueryFilters(filters?: BusinessFilter[]): string[] {
  if (!filters || filters.length === 0) {
    return [];
  }

  return filters.reduce((acc: string[], f: BusinessFilter) => {
    switch (f.nature) {
      case BusinessFilterNature.FACET: {
        const facetId = f.filter.id;
        f.filter.values
          .filter((f) => f.selected)
          .forEach((v) => {
            const facetValue = v.id;
            acc.push(`${FqType.Facet}${facetId}:${facetValue}`);
          });
        break;
      }
      case BusinessFilterNature.BASKET: {
        const basketId = f.filter.values[0].id;
        acc.push(`${FqType.Basket}${basketId}`);
        break;
      }
      default:
        break;
    }

    return acc;
  }, []);
}
