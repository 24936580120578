import { useMemo, useState } from "react";

import { IChangeEvent, UiSchema } from "@rjsf/core";
import { JSONSchema7Definition } from "json-schema";
import { debounce } from "lodash";
import { useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from "recoil";

import { getPathFromSectionDataKey } from "../../../containers/FrontEdit/utils";
import { resourceUploadLoadingState } from "../../admin/resource/atoms";
import { frontEditState } from "../atoms/frontEdit";
import {
  CurrentContentEdit,
  frontEditCurrentContentEditsState,
} from "../atoms/frontEditCurrentContentEdits";
import { CurrentEditKey } from "../atoms/frontEditCurrentEditKeys";
import { getDataOfMultipleSectionsSelector } from "../selectors/schema";
import { replaceItemAtIndex } from "../utils";
import useFrontEditModal from "./useFrontEditModal";

export type SectionData = {
  key: CurrentEditKey;
  configurationData: any;
  jsonSchema: JSONSchema7Definition | JSONSchema7Definition[] | undefined;
  jsonUiSchema: UiSchema | undefined;
};

type UseFrontEditCurrentEdit = {
  sectionsData: SectionData[] | null;
  mergeDataWithLocalChange: SectionData[] | null;
  loading: boolean;
  handleSubmit: () => void;
  handleChangeAndSubmit: (e: IChangeEvent, sectionData: SectionData) => void;
  handleChange: (e: IChangeEvent, sectionData: SectionData) => void;
  resourceUploadLoading: boolean;
};

type UseFrontEditCurrentEditOptions = {
  forceEditMode?: boolean;
};

const debounceCallback = debounce((cb) => {
  cb();
}, 500);

export default function useFrontEditCurrentEdit(
  keys: CurrentEditKey[],
  options?: UseFrontEditCurrentEditOptions,
): UseFrontEditCurrentEdit {
  const { forceEditMode } = options ?? { forceEditMode: false };
  const resourceUploadLoading = useRecoilValue(resourceUploadLoadingState);
  const [localContentsEdits, setLocalContentsEdits] = useState<CurrentContentEdit[]>([]);

  const { setIsOpen } = useFrontEditModal();
  const frontEdit = useRecoilValue(frontEditState);
  const sectionsDataLoadable = useRecoilValueLoadable(
    // Avoid loading if we're not in edit mode
    getDataOfMultipleSectionsSelector({ keys: frontEdit || forceEditMode ? keys : [] }),
  );
  const sectionsData =
    sectionsDataLoadable.state === "hasValue" ? sectionsDataLoadable.contents : null;

  const loading = sectionsDataLoadable.state === "loading";

  const setCurrentContentEdits = useSetRecoilState(frontEditCurrentContentEditsState);

  const updateConfigDuringFrontEdit = () => {
    localContentsEdits.forEach((ncce) => {
      setCurrentContentEdits((currentContentEdits) => {
        const index = currentContentEdits.findIndex(
          (currentContentEdit) => currentContentEdit.endpoint === ncce.endpoint,
        );
        if (index === -1) {
          return [...currentContentEdits, ncce];
        } else {
          return replaceItemAtIndex(currentContentEdits, index, ncce);
        }
      });
    });
  };

  const handleSubmit = () => {
    // Send the local modification to the whole content to publish
    updateConfigDuringFrontEdit();
    // Empty the local modifications
    setLocalContentsEdits([]);
    // Close the modal in case it was open
    setIsOpen(false);
  };

  const handleChangeAndSubmit = (e: IChangeEvent, sectionData: SectionData) => {
    handleChange(e, sectionData);
    debounceCallback.cancel();
    debounceCallback(handleSubmit);
  };

  const handleChange = (e: IChangeEvent, sectionData: SectionData) => {
    const { key, jsonSchema, jsonUiSchema } = sectionData;
    const endpoint = getPathFromSectionDataKey(key);
    const index = localContentsEdits.findIndex(
      (localContentsEdit) => localContentsEdit.endpoint === endpoint,
    );
    if (index !== -1) {
      setLocalContentsEdits((prev) =>
        replaceItemAtIndex(prev, index, {
          key,
          endpoint,
          data: e.formData,
          jsonSchema,
          jsonUiSchema,
        }),
      );
    } else {
      setLocalContentsEdits((prev) => [
        ...prev,
        {
          key,
          endpoint,
          data: e.formData,
          jsonSchema,
          jsonUiSchema,
        },
      ]);
    }
  };

  const mergeDataWithLocalChange = useMemo(() => {
    return (
      sectionsData?.map((sectionData) => {
        const correspondingData = localContentsEdits.find((lce) => lce.key === sectionData.key);
        if (correspondingData) {
          return {
            ...sectionData,
            configurationData: correspondingData.data,
          };
        }
        return sectionData;
      }) ?? null
    );
  }, [localContentsEdits, sectionsData]);

  return {
    sectionsData,
    mergeDataWithLocalChange,
    loading,
    handleSubmit,
    handleChangeAndSubmit,
    handleChange,
    resourceUploadLoading,
  };
}
