import { KpkApiAdvancedSearchFilter } from "@keepeek/api-client";
import { fetcherModeAtom } from "@keepeek/commons";
import trim from "lodash/trim";
import { selector, selectorFamily } from "recoil";

import { CONFIGURATION_SECTION_PATH } from "../../../components/layout/adminMenu";
import { WidgetConfigurations } from "../../../containers/widgets/models";
import { getWidgetConfigurationsByTemplates } from "../../../containers/widgets/utils/getWidgetConfigurations";
import { getAdminConfigSectionData } from "../../../lib/config/customer-config-utils";
import logger from "../../../lib/logger-utils";
import { convertToPermanentFilters } from "../../../lib/permanent-filters-utils";
import { Basket } from "../../../models/configuration/components/basket";
import { DownloadManager } from "../../../models/configuration/components/downloadManager";
import { Template } from "../../../models/configuration/definitions/template";
import { Global } from "../../../models/configuration/global";
import { Element } from "../../../models/configuration/pages/element";
import { Search } from "../../../models/configuration/pages/search";
import { Key, Theme } from "../../../models/configuration/theme";
import { configSectionFetcherSelector } from "../../fetcher/selectors/configSection";
import { jsonConfigSelector } from "../selectors/init";
import { isTemplatesConfiguredWithWidget, KTemplatesTemplatesName } from "../utils";

export const configSectionDataByPathSelector = selectorFamily<
  any | undefined,
  CONFIGURATION_SECTION_PATH
>({
  key: "ConfigSectionByNameSelector",
  get:
    (name) =>
    ({ get }) => {
      const jsonConfig = get(jsonConfigSelector);
      return getAdminConfigSectionData(jsonConfig, name);
    },
});

export const configSectionGlobalSelector = selector<Global | undefined>({
  key: "ConfigSectionGlobalSelector",
  get: ({ get }) => get(configSectionDataByPathSelector(CONFIGURATION_SECTION_PATH.GLOBAL)),
});

export const configSectionThemeSelector = selector<Theme | undefined>({
  key: "ConfigSectionThemeSelector",
  get: ({ get }) => get(configSectionDataByPathSelector(CONFIGURATION_SECTION_PATH.THEME)),
});

export const configSectionComponentDownloadManagerSelector = selector<DownloadManager | undefined>({
  key: "ConfigSectionComponentDownloadManagerSelector",
  get: ({ get }) =>
    get(configSectionDataByPathSelector(CONFIGURATION_SECTION_PATH.COMPONENTS_DOWNLOAD_MANAGER)),
});

export const configSectionComponentBasketSelector = selector<Basket | undefined>({
  key: "ConfigSectionComponentBasketSelector",
  get: ({ get }) =>
    get(configSectionDataByPathSelector(CONFIGURATION_SECTION_PATH.COMPONENTS_BASKET)),
});

export const initCustomerConfigSectionPageSearch: Search = {
  sort: {},
  functionalFieldsToDisplay: {},
  technicalFieldsToDisplay: {},
  visualAlerts: [],
  showBackgroundUrl: false,
  backgroundColor: "black",
};

export const configSectionPageSearchSelector = selector<Search | undefined>({
  key: "ConfigSectionPageSearchSelector",
  get: ({ get }) => {
    const { pageSearchSection } = get(configSectionFetcherSelector(get(fetcherModeAtom)));

    const searchPageConfig = configSectionDataByPathSelector(pageSearchSection);
    if (!searchPageConfig) {
      return initCustomerConfigSectionPageSearch;
    }
    return get(searchPageConfig);
  },
});

export const configSectionPageElementSelector = selector<Element | undefined>({
  key: "ConfigSectionPageElementSelector",
  get: ({ get }) => {
    const { pageElementSection } = get(configSectionFetcherSelector(get(fetcherModeAtom)));

    return get(configSectionDataByPathSelector(pageElementSection));
  },
});

export const configSectionGlobalPermanentFiltersSelector = selector<
  Array<KpkApiAdvancedSearchFilter>
>({
  key: "ConfigSectionGlobalPermanentFiltersSelector",
  get: ({ get }) => {
    const configSectionGlobal = get(configSectionGlobalSelector);
    return convertToPermanentFilters(configSectionGlobal?.permanentFilters);
  },
});

// Deprecated selector
export const configSectionThemeAssetSelector = selectorFamily<string[], Key>({
  key: "ConfigSectionThemeAssetImageSelector",
  get:
    (assetKey: Key) =>
    ({ get }) => {
      const configSectionTheme = get(configSectionThemeSelector);
      const asset = configSectionTheme?.assets?.find((a) => a.key === assetKey);

      if (asset && asset.values && asset.values.length > 0 && trim(asset.values[0]) !== "") {
        return asset.values;
      }
      return [""];
    },
});

export const configSectionKTemplateByPosition = selectorFamily<
  { templates: Template[] | undefined; widgetsConfiguration: WidgetConfigurations | undefined },
  {
    // add locale deps in order to retrieve the configuration of widgets coming from the API with the correct language when local change
    locale: string;
    templatePagePosition?: CONFIGURATION_SECTION_PATH;
    templatesName?: KTemplatesTemplatesName;
    // attention, this string must only contain a stringified version of the TemplateSchema [] object !
    defaultTemplatesStringify?: string;
  }
>({
  key: "ConfigSectionKTemplateByPosition",
  get:
    ({
      templatePagePosition,
      templatesName = KTemplatesTemplatesName.Templates,
      defaultTemplatesStringify = "",
    }) =>
    ({ get }) => {
      const configSections = get(jsonConfigSelector);

      if (!configSections) {
        return { templates: undefined, widgetsConfiguration: undefined };
      }
      const configSection = getAdminConfigSectionData<Template>(
        configSections,
        templatePagePosition,
      );

      let templates = configSection?.[templatesName];

      // Get default template if there is none configured :
      if (!isTemplatesConfiguredWithWidget(templates) && defaultTemplatesStringify) {
        try {
          templates = JSON.parse(defaultTemplatesStringify);
        } catch (error) {
          logger.error("error during parse defaultTemplatesStringify", error);
        }
      }

      const widgetsConfiguration = getWidgetConfigurationsByTemplates({
        configSections,
        templates,
      });

      return {
        templates,
        widgetsConfiguration,
      };
    },
});
