import { KpkApiAdvancedSearchFilter, KpkApiAdvancedSearchFilterType } from "@keepeek/api-client";
import { dayjs } from "@keepeek/refront-components";

export function getParameterByName(name: string, url = window.location.href): string | null {
  name = name.replace(/[[\]]/g, "\\$&");
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export const advancedSearchIsExpired = (expirationDate: string) => {
  // Check if the expiration date is not passed
  return dayjs().isSame(dayjs(expirationDate)) || dayjs().isAfter(dayjs(expirationDate));
};

export const mergePermanentFilters = (
  filters: KpkApiAdvancedSearchFilter[],
  permanentFilters: KpkApiAdvancedSearchFilter[],
): KpkApiAdvancedSearchFilter[] => {
  // Merge only when needed
  if (filters.length === 0 && permanentFilters.length === 0) {
    // if no filters at all
    return [];
  } else if (filters.length === 0) {
    // if no user filters
    return permanentFilters;
  } else if (permanentFilters.length === 0) {
    // if no permanent filters
    return filters;
  }

  // Merge
  let mergedFilters: KpkApiAdvancedSearchFilter[] = [];
  let tempPermaFilters: KpkApiAdvancedSearchFilter[] = [...permanentFilters];
  const evaluation = (pf: KpkApiAdvancedSearchFilter, f: KpkApiAdvancedSearchFilter): boolean =>
    (pf && pf.internalFieldName) === (f && f.internalFieldName);
  filters.forEach((filter) => {
    const matchIndex: number = tempPermaFilters.findIndex((pf) => evaluation(pf, filter));
    if (matchIndex === -1) {
      // no match in permanent filter : add filter without merge
      mergedFilters.push(filter);
    } else if (matchIndex !== -1) {
      // Do the reconciliation
      switch (filter.type) {
        case KpkApiAdvancedSearchFilterType.Folderfield:
          // For the folder, use only the one from the user filter
          mergedFilters.push(filter);
          break;
        default: {
          // Without reconciliation rules
          if (filter.modifier === tempPermaFilters[matchIndex].modifier) {
            // check if they will do the same in order to reconciliate
            const mergedFilter: KpkApiAdvancedSearchFilter = {
              ...filter,
              values: [...(filter?.values ?? []), ...(tempPermaFilters[matchIndex]?.values ?? [])],
            };
            mergedFilters.push(mergedFilter);
          } else {
            // if not, push both
            mergedFilters.push(filter);
            mergedFilters.push(tempPermaFilters[matchIndex]);
          }
          break;
        }
      }
      // Then remove from temp
      delete tempPermaFilters[matchIndex];
    }
  });
  // Remove empty values from our delete
  tempPermaFilters = tempPermaFilters.filter((pf) => pf);
  // add our reconciliation with the rest of our perma filters
  mergedFilters = [...mergedFilters, ...tempPermaFilters];

  return mergedFilters;
};
