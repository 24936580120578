import {
  KpkApiAdvancedSearchCriteria,
  KpkApiAdvancedSearchCriteriaFieldType,
  KpkApiAdvancedSearchCriteriaType,
  KpkApiAdvancedSearchFilter,
  KpkApiAdvancedSearchFilterFieldType,
  KpkApiAdvancedSearchFilterModifier,
  KpkApiAdvancedSearchFilterType,
  KpkApiFacet,
  KpkApiFacetType,
  KpkApiSheetField,
} from "@keepeek/api-client";
import {
  IsJsonString,
  BusinessFilter,
  BusinessFilterNature,
  KpkFilter,
  KpkFilterModifier,
  KpkFilterModifierType,
  KpkFilterType,
  KpkFilterValue,
  FunctionOverrideKey,
  FunctionOverrideReturn,
} from "@keepeek/commons";
import sortBy from "lodash/sortBy";
import trim from "lodash/trim";
import LZString from "lz-string";
import { getI18n } from "react-i18next";

import logger from "../lib/logger-utils";
import {
  Filter,
  OrderDirectionFacetValues,
  OrderTypeFacetValues,
  Type,
} from "../models/configuration/components/businessFilter";
import { LightBusinessFilter, LightKpkFilterValue } from "../providers/businessFilter/atoms";
import { getInternalNameWithoutLocale, MediaField } from "./field-utils";
import { getCustomerOverrideFunction } from "./overrides";
import { FqType } from "./search/search-media";

export const FOLDERS_FACET_NAME_BEGIN = "folders";
export const FQ_PARAMETER = "fq";
export const MINUTES_UNIT = "m";

export function convertBusinessFilterToKpkFilter(businessFilter: BusinessFilter): KpkFilter {
  return {
    ...businessFilter.filter,
    id: `${businessFilter.nature}-${businessFilter.filter.id}`,
  };
}

export function convertKpkFilterToBusinessFilter(kpkFilter: KpkFilter): BusinessFilter {
  const nature = kpkFilter.id.split("-")[0] as BusinessFilterNature;
  return {
    nature: nature,
    filter: {
      ...kpkFilter,
      id: kpkFilter.id.substr(nature.length + 1),
    },
  };
}

export function getBusinessFilterFullTextValue(
  businessFilters: Array<BusinessFilter> | undefined,
): string {
  const fullTextFilterValue = getBusinessFilterFirstValue(
    businessFilters,
    BusinessFilterNature.FULL_TEXT,
    BusinessFilterNature.FULL_TEXT,
  );
  if (fullTextFilterValue) {
    return fullTextFilterValue.id;
  }
  return "";
}

export function getBusinessFilterFirstValue(
  businessFilters: Array<BusinessFilter> | undefined,
  nature: BusinessFilterNature,
  filterId: string,
): KpkFilterValue | undefined {
  const filter = businessFilters?.find(
    (f) => f.nature === nature && f.filter.id === filterId,
  )?.filter;
  if (filter && filter.values && filter.values.length > 0) {
    return filter.values[0];
  }
}

export function createFullTextBusinessFilter(search: string, filterLabel: string): BusinessFilter {
  return {
    nature: BusinessFilterNature.FULL_TEXT,
    filter: {
      id: BusinessFilterNature.FULL_TEXT,
      values: [
        {
          id: search,
          label: search,
          selected: null,
        },
      ],
      type: KpkFilterType.Text,
      label: filterLabel,
      modifiers: null,
    },
  };
}

/**
 * Create a Business Filter of BASKET Nature
 * @param basketId basket id to filter on
 */
export function createBasketBusinessFilter(basketId: number): BusinessFilter {
  return {
    nature: BusinessFilterNature.BASKET,
    filter: {
      id: BusinessFilterNature.BASKET,
      label: "basketId",
      type: KpkFilterType.Basket,
      modifiers: null,
      values: [
        {
          id: basketId,
          label: "basketId",
          title: "",
          selected: null,
        },
      ],
    },
    hide: true,
  };
}

export function createAdvancedBusinessFilter(
  fieldId: string,
  query: string,
  filterLabel: string,
  searchSeparator?: string,
): BusinessFilter {
  let separator = /\s/;
  if (searchSeparator) {
    separator = new RegExp(searchSeparator);
  }
  const querySplit: string[] = query
    .split(separator)
    .map((value) => trim(value))
    .filter((value) => value !== "");
  const filterValues: KpkFilterValue[] = querySplit.map((value) => ({
    id: value,
    label: value,
    selected: null,
  }));
  return {
    nature: BusinessFilterNature.ADVANCED,
    hide: true,
    filter: {
      id: fieldId,
      label: filterLabel,
      modifiers: [{ type: KpkFilterModifierType.EqualsOne, selected: true }],
      type: KpkFilterType.Text,
      values: filterValues,
    },
  };
}

export function createFolderBusinessFilter(
  folderId: number,
  folderName: string,
  filterLabel: string,
): BusinessFilter {
  return {
    nature: BusinessFilterNature.ADVANCED,
    filter: {
      id: MediaField.FOLDER_ID,
      label: filterLabel,
      modifiers: [{ type: KpkFilterModifierType.EqualsOne, selected: true }],
      type: KpkFilterType.Folder,
      values: [
        {
          id: folderId,
          label: folderName,
          selected: null,
        },
      ],
    },
  };
}

export function createFacetForBounceSearch(
  facetId: string,
  language: string,
  label: string,
  value: string,
  type?: KpkApiFacetType,
  synonym?: string,
): KpkApiFacet {
  return {
    id: `${facetId}.${language.toUpperCase()}`,
    title: label,
    type,
    _embedded: {
      entry: [
        {
          value,
          path: synonym ? (value += `###${synonym}`) : "",
        },
      ],
    },
  };
}

export const convertKpkApiFacetToBusinessFilter = (
  kpkApiFacet: KpkApiFacet,
  hideFacetOccurrences?: boolean,
  sortDirection?: "DESC" | "ASC",
  sortByOccurrences?: boolean,
  isSelected?: boolean,
): BusinessFilter => {
  if (
    kpkApiFacet &&
    kpkApiFacet._embedded &&
    kpkApiFacet._embedded.entry &&
    kpkApiFacet.id?.startsWith(FOLDERS_FACET_NAME_BEGIN)
  ) {
    kpkApiFacet = {
      ...kpkApiFacet,
      _embedded: {
        ...kpkApiFacet._embedded,
        entry: kpkApiFacet._embedded?.entry.map((e) => {
          return {
            ...e,
            path: e.value,
            value: e.value ? e.value.substr(e.value.lastIndexOf("#") + 1) : "",
          };
        }),
      },
    };
  }
  let entries = sortBy(kpkApiFacet._embedded?.entry, [
    function (entry) {
      return sortByOccurrences ? entry.count : entry.value;
    },
  ]);
  if (sortDirection === "DESC") {
    entries = entries.reverse();
  }

  return {
    nature: BusinessFilterNature.FACET,
    filter: {
      id: kpkApiFacet.id || "",
      label: kpkApiFacet.title || "",
      type: KpkFilterType.Checkbox,
      modifiers: null,
      values:
        entries.map((e) => {
          let label = hideFacetOccurrences ? e.value : `${e.value} (${e.count})`;
          let title = hideFacetOccurrences
            ? e.path
              ? e.path
              : e.value
            : e.path
            ? `${e.path.replaceAll("#", "/")} (${e.count})`
            : `${e.value.replaceAll("#", "/")} (${e.count})`;
          let newId = e.value;
          const selected = isSelected ? isSelected : false;
          if (kpkApiFacet.type === KpkApiFacetType.Microthesaurus) {
            label = getLastThesaurusTerm(title);
          } else if (kpkApiFacet.type === KpkApiFacetType.Date) {
            const date = new Date(Date.parse(e.value));
            title = hideFacetOccurrences
              ? date.getFullYear()
              : `${date.getFullYear()} (${e.count})`;
            label = title;
            newId = date.getTime();
          }
          return {
            id: newId,
            label,
            title,
            selected,
            mediaSearchLink:
              e._links && e._links["kpk:media-search"] ? e._links["kpk:media-search"].href : "",
            path: e.path ? e.path : "",
          } as KpkFilterValue;
        }) || [],
    },
  };
};

function getLastThesaurusTerm(title: string): string {
  if (!title || title.indexOf(">") <= 0) {
    return title;
  }
  return title.substr(title.lastIndexOf(">") + 2);
}

export const convertToKpkApiAdvancedSearchFilter = (
  filter: KpkFilter,
  criterias: KpkApiAdvancedSearchCriteria[],
): KpkApiAdvancedSearchFilter | null => {
  // Try to find if this function has been overridden :
  const customFunction: FunctionOverrideReturn[FunctionOverrideKey.ConvertToKpkApiAdvancedSearchFilter] =
    getCustomerOverrideFunction<FunctionOverrideKey.ConvertToKpkApiAdvancedSearchFilter>(
      FunctionOverrideKey.ConvertToKpkApiAdvancedSearchFilter,
      { filter },
    );
  if (customFunction) {
    return customFunction;
  }

  const criteria = criterias.find((c) => c.internalFieldName === filter.id);
  if (!criteria || !criteria.fieldType || !criteria.type) {
    logger.info(
      `No data from API for advanced filter ${filter.id} - ${criteria}`,
      filter,
      criteria,
    );
    return null;
  }
  if (!filter.modifiers) {
    logger.warn(`Advanced filter modifiers is empty ${filter.id}`, filter);
    return null;
  }
  const modifier = filter.modifiers.find((m) => m.selected);
  if (!modifier) {
    logger.warn(`Advanced filter have no selected modifier ${filter.id}`, filter);
    return null;
  }
  const convertedModifier = modifier.type as unknown as KpkApiAdvancedSearchFilterModifier;
  if (!convertedModifier) {
    logger.warn(`Advanced filter modifier can't be converted to API modifier ${filter.id}`, filter);
    return null;
  }
  let values;
  if (filter.type === KpkFilterType.Radio) {
    values = filter.values.filter((v) => v.selected).map((v) => v.id);
  } else if (filter.type === KpkFilterType.Duration) {
    values = filter.values.map((v) => adaptDuration(v.id, v.label));
  } else {
    values = filter.values.map((v) => v.id);
  }
  return {
    internalFieldName: filter.id,
    fieldType: criteria.fieldType as unknown as KpkApiAdvancedSearchFilterFieldType,
    showSub: filter.type === KpkFilterType.Folder,
    values,
    modifier: convertedModifier,
    type: criteria.type as unknown as KpkApiAdvancedSearchFilterType,
  };
};

/**
 * Return a facet filter list (multiple facet because of i18n...)
 */
function buildFacetFilterList(filterConfig: Filter, facets: KpkApiFacet[]): BusinessFilter[] {
  const ret: BusinessFilter[] = [];
  // get facet from api result :
  const facetList = facets.filter(
    (f) =>
      f.id === filterConfig.name ||
      f.id?.startsWith(`${filterConfig.name}.`) ||
      (filterConfig.name === MediaField.FOLDER_ID && f.id?.startsWith(FOLDERS_FACET_NAME_BEGIN)),
  );
  if (!facetList || facetList.length === 0) {
    logger.debug(`No data from API for facet filter ${filterConfig}`, filterConfig);
    return ret;
  }
  for (const facet of facetList) {
    const sortDirection: OrderDirectionFacetValues =
      filterConfig?.orderDirectionFacetValues || OrderDirectionFacetValues.Asc;
    const sortByOccurrences: boolean =
      filterConfig?.orderTypeFacetValues === OrderTypeFacetValues.Occurrences || false;
    const hideFacetOccurrences: boolean = filterConfig?.hideFacetOccurrences || false;
    ret.push(
      convertKpkApiFacetToBusinessFilter(
        facet,
        hideFacetOccurrences,
        sortDirection,
        sortByOccurrences,
      ),
    );
  }
  return ret;
}

/**
 * Return an advanced filter list (multiple filter because of i18n...)
 */
export function buildAdvancedFilterList(
  internalName: string,
  sheetFields: KpkApiSheetField[] | undefined,
  criterias: KpkApiAdvancedSearchCriteria[] | undefined,
): BusinessFilter[] {
  const ret: BusinessFilter[] = [];
  if (!internalName) {
    return ret;
  }
  // first, try to find exact match for internal field name :
  const filteredCriteriaList = criterias?.filter((c) => c.internalFieldName === internalName) ?? [];
  // if nothing found, try to find with "startsWith" to find i18n criteria field :
  if ((!filteredCriteriaList || filteredCriteriaList.length === 0) && criterias) {
    filteredCriteriaList.push(
      ...criterias.filter((c) => c.internalFieldName?.startsWith(`${internalName}.`)),
    );
  }
  if (!filteredCriteriaList || filteredCriteriaList.length === 0) {
    logger.info(`No data from API for advanced filter ${internalName}`);
    return ret;
  }
  for (const criteria of filteredCriteriaList) {
    if (!criteria.availableModifiers) {
      logger.info(`No availableModifiers from API for advanced filter ${internalName}`);
      continue;
    }
    const filterType = convertKpkApiAdvancedSearchCriteriaTypeToKpkFilterType(criteria.type);
    if (!filterType) {
      logger.info("this filter type is not implemented", criteria);
      continue;
    }
    let filterLabel = internalName;
    const internalFieldName = getInternalNameWithoutLocale(criteria.internalFieldName || "");
    if (!internalFieldName) {
      continue;
    }
    if (criteria.fieldType === KpkApiAdvancedSearchCriteriaFieldType.Metafield) {
      const sheetField = sheetFields?.find((sf) => sf.id === internalFieldName);
      if (sheetField && sheetField.title) {
        filterLabel = sheetField.title;
      }
    } else if (criteria.fieldType === KpkApiAdvancedSearchCriteriaFieldType.Mediafield) {
      filterLabel = getI18n().t(`mediafield_${internalFieldName}`);
    }
    const values: Array<KpkFilterValue> = [];
    let modifiers: Array<KpkFilterModifier> | null = criteria.availableModifiers.map(
      (apiModifier) => {
        return {
          selected: null,
          type: apiModifier as unknown as KpkFilterModifierType,
        };
      },
    );

    // Special treatment for advanced search filters of radio type :
    // We force the modifiers and values to only YES / NO choice :
    if (filterType === KpkFilterType.Radio) {
      values.push(
        {
          id: true,
          label: getI18n().t("business-filter.advanced.radio.yes.label"),
          selected: false,
        },
        {
          id: false,
          label: getI18n().t("business-filter.advanced.radio.no.label"),
          selected: false,
        },
      );
      modifiers = [
        {
          type: KpkFilterModifierType.ContainsOne,
          selected: true,
        },
      ];
    }
    ret.push({
      nature: BusinessFilterNature.ADVANCED,
      filter: {
        id: criteria.internalFieldName || "",
        label: filterLabel,
        values,
        modifiers,
        type: filterType,
      },
    });
  }
  return ret;
}

/**
 * Extract from filters configuration (BDD refront configuration) available business filters.
 */
export async function getAvailableBusinessFiltersFromConfig(
  filtersConfiguration: Filter[],
  facets: KpkApiFacet[] | undefined,
  sheetFields: KpkApiSheetField[] | undefined,
  criterias: KpkApiAdvancedSearchCriteria[] | undefined,
): Promise<BusinessFilter[]> {
  const ret: BusinessFilter[] = [];
  if (!filtersConfiguration || filtersConfiguration.length === 0) {
    return ret;
  }
  for (const filterConfig of filtersConfiguration) {
    switch (filterConfig.type) {
      case Type.Facet:
        ret.push(...buildFacetFilterList(filterConfig, facets || []));
        break;
      case Type.Advanced:
        ret.push(...buildAdvancedFilterList(filterConfig.name || "", sheetFields, criterias));
        break;
    }
  }
  return ret;
}

function convertKpkApiAdvancedSearchCriteriaTypeToKpkFilterType(
  advancedSearchType?: KpkApiAdvancedSearchCriteriaType,
): KpkFilterType | null {
  switch (advancedSearchType) {
    case KpkApiAdvancedSearchCriteriaType.Textarea:
    case KpkApiAdvancedSearchCriteriaType.Textfield:
    case KpkApiAdvancedSearchCriteriaType.Emailfield:
    case KpkApiAdvancedSearchCriteriaType.Extensionfield:
      return KpkFilterType.Text;
    case KpkApiAdvancedSearchCriteriaType.Folderfield:
      return KpkFilterType.Folder;
    case KpkApiAdvancedSearchCriteriaType.Datefield:
    case KpkApiAdvancedSearchCriteriaType.Datetimefield:
      return KpkFilterType.Date;
    case KpkApiAdvancedSearchCriteriaType.Checkboxfield:
      return KpkFilterType.Radio;
    case KpkApiAdvancedSearchCriteriaType.Durationfield:
      return KpkFilterType.Duration;
    default:
      return null;
  }
}

/**
 * allows to convert business filters into light business filter
 */
export function convertToLightBusinessFilter(
  businessFilters: BusinessFilter[],
): LightBusinessFilter[] {
  return businessFilters.map((bf) => {
    let values: Array<LightKpkFilterValue>;
    switch (bf.nature) {
      case BusinessFilterNature.FACET:
        // for FACET nature, only the selected facet is kept in values
        values = bf.filter.values
          .filter((v) => v.selected)
          .map((v) => {
            let labelAndSynonym = v.id;
            if (v.mediaSearchLink && bf.filter.id && bf.filter.type === KpkFilterType.Checkbox) {
              // Get label and synonym in mediaSearch link fq parameter
              const parameterValueFqs: string[] = new URLSearchParams(v.mediaSearchLink).getAll(
                FQ_PARAMETER,
              );
              if (parameterValueFqs) {
                const parameterValueFq = parameterValueFqs.find((fq) => fq.includes(bf.filter.id));
                if (parameterValueFq && parameterValueFq.includes(FqType.Facet)) {
                  labelAndSynonym = parameterValueFq.replaceAll(FqType.Facet, "");
                  labelAndSynonym = labelAndSynonym.replaceAll(`${bf.filter.id}:`, "");
                }
              }
            }
            if (v.path) {
              labelAndSynonym = v.path;
            }
            return {
              i: labelAndSynonym,
              l: v.label,
              t: v.title,
              s: v.selected,
            };
          });
        break;
      default:
        // for OTHER nature type, we keep ALL the values
        values = bf.filter.values.map((v) => {
          return {
            i: v.id,
            l: v.label,
            t: v.title,
            s: v.selected,
          };
        });
    }
    return {
      n: bf.nature,
      f: {
        i: bf.filter.id,
        l: bf.nature === BusinessFilterNature.FACET ? bf.filter.label : undefined,
        t: bf.filter.type,
        m: bf.filter.modifiers?.find((m) => m.selected)?.type || undefined,
        v: values,
      },
      h: bf.hide,
    };
  });
}

/**
 * allows you to convert light business filters into business filters
 */
export function convertToBusinessFilter(
  lightBusinessFilters: LightBusinessFilter[],
  sheetFields: KpkApiSheetField[] | undefined,
  criterias: KpkApiAdvancedSearchCriteria[] | undefined,
): BusinessFilter[] {
  const ret: BusinessFilter[] = [];
  lightBusinessFilters.forEach((lbf) => {
    try {
      switch (lbf.n) {
        case BusinessFilterNature.FACET:
          ret.push({
            nature: lbf.n,
            filter: {
              id: lbf.f.i,
              label: lbf.f.l || "",
              type: KpkFilterType.Checkbox,
              values: lbf.f.v.map((v) => {
                return {
                  id: v.i,
                  label: v.l || "",
                  title: v.t,
                  selected: v.s,
                };
              }),
              modifiers: null,
            },
            hide: lbf.h,
          });
          break;
        case BusinessFilterNature.FULL_TEXT:
          ret.push(
            createFullTextBusinessFilter(
              lbf.f.v[0].i,
              getI18n().t("business-filter.full-text.label"),
            ),
          );
          break;
        case BusinessFilterNature.BASKET:
          ret.push(createBasketBusinessFilter(lbf.f.v[0].i));
          break;
        case BusinessFilterNature.ADVANCED:
          const internalName = lbf.f.i;
          const lbfValues = lbf.f.v;
          const currentSelectedModifierType = lbf.f.m;
          const advancedFilter = buildAdvancedFilterList(internalName, sheetFields, criterias).find(
            (af) => af.filter.id === internalName,
          );
          if (!advancedFilter) {
            logger.error(`can't find light business filter for internalName=${internalName}`);
            break;
          }
          // values => we take the values that were present in the light business filter
          advancedFilter.filter.values = lbfValues.map((v) => {
            return {
              id: v.i,
              label: v.l,
              title: v.t,
              selected: v.s,
            };
          });
          // set current selected modifier
          if (advancedFilter.filter.modifiers) {
            advancedFilter.filter.modifiers = advancedFilter.filter.modifiers.map((m) => {
              return {
                ...m,
                selected: m.type === currentSelectedModifierType ? true : null,
              };
            });
          } else if (currentSelectedModifierType) {
            advancedFilter.filter.modifiers = [];
            advancedFilter.filter.modifiers.push({
              selected: true,
              type: currentSelectedModifierType,
            });
          }
          ret.push(advancedFilter);
          break;
        default:
          logger.error(`light filter business of nature ${lbf.n} is not implemented`);
      }
    } catch (error) {
      logger.error(`can't convert light business filter to business filter lbf=${lbf}`, error);
    }
  });
  return ret;
}

/**
 * allows you to convert business filters into light business filters and to encode them with LZString
 */
export function encodeToLightBusinessFilters(filters: BusinessFilter[]): string | null {
  if (filters) {
    return LZString.compressToEncodedURIComponent(
      JSON.stringify(convertToLightBusinessFilter(filters)),
    );
  } else {
    return null;
  }
}

/**
 * allows to decode business filters / light business filters from a character string using LZString
 */
export function decodeFilters<T extends BusinessFilter[] | LightBusinessFilter[]>(
  compressedBusinessFilter: string,
): T | null {
  if (compressedBusinessFilter) {
    const result = LZString.decompressFromEncodedURIComponent(compressedBusinessFilter);
    if (result && IsJsonString(result)) {
      return JSON.parse(result);
    }
  }
  return null;
}

/**
 * Return filters for current local if localized, otherwise, return filter without change
 */
export function getFiltersForCurrentLocale(
  filters: BusinessFilter[],
  currentLocal: string | undefined,
): BusinessFilter[] {
  logger.debug("[getFiltersForCurrentLocale]", filters, currentLocal);
  const ret: BusinessFilter[] = [];
  filters.forEach((f) => {
    if (f.filter.id.includes(".")) {
      // i18n filter => try find filter for current local
      const filterIdWithoutI18n = getInternalNameWithoutLocale(f.filter.id);
      const currentLocalFilter = filters.find(
        (i18nFilter) =>
          i18nFilter.filter.id.includes(`${filterIdWithoutI18n}.${currentLocal?.toUpperCase()}`) &&
          i18nFilter.nature === f.nature,
      );
      if (currentLocalFilter) {
        // filter found for current local
        if (!ret.includes(currentLocalFilter)) {
          logger.debug("[getFiltersForCurrentLocale] add currentLocalFilter", currentLocalFilter);
          ret.push(currentLocalFilter);
        } else {
          logger.debug(
            "[getFiltersForCurrentLocale] currentLocalFilter already added",
            currentLocalFilter,
          );
        }
      } else {
        // filter not found for current local => get first filter founded :
        const firstI18nFilter = filters.find(
          (i18nFilter) =>
            i18nFilter.filter.id.includes(`${filterIdWithoutI18n}.`) &&
            i18nFilter.nature === f.nature,
        );
        if (firstI18nFilter) {
          if (!ret.includes(firstI18nFilter)) {
            logger.debug("[getFiltersForCurrentLocale] add firstI18nFilter", firstI18nFilter);
            ret.push(firstI18nFilter);
          } else {
            logger.debug(
              "[getFiltersForCurrentLocale] firstI18nFilter already added",
              firstI18nFilter,
            );
          }
        } else {
          logger.warn(
            `[getFiltersForCurrentLocale] can't find filter for filter id ${filterIdWithoutI18n}`,
          );
        }
      }
    } else {
      logger.debug("[getFiltersForCurrentLocale] no i18n filter", f);
      // no i18n filter, add filter without treatment :
      ret.push(f);
    }
  });
  logger.debug("[getFiltersForCurrentLocale] ret", ret);
  return ret;
}

function adaptDuration(id: number, label: string): number {
  let newId = id;
  if (label.includes(MINUTES_UNIT)) {
    newId = id * 60;
  }
  return newId;
}
