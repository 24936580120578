import { atom } from "recoil";

// Are we in front edit sate
export const frontEditState = atom<boolean>({
  key: "FrontEditState",
  default: false,
});

// Keep a state of how much the front edit has been closed
// this is used for cache invalidation in multiple selectors
// It is maintainted by the number of time we close the front edit
export const frontEditHasBeenClosedXTimesState = atom<number>({
  key: "FrontEditHasBeenClosedXTimesState",
  default: 0,
});
