import { CustomerAppTheme } from "@keepeek/refront-customers";
import { UiSchema } from "@rjsf/core";
import { JSONSchema7Definition } from "json-schema";

import { Config } from "../../lib/commonPagePropsUtil";
import { Template } from "../../models/configuration/definitions/template";
import { Global } from "../../models/configuration/global";

export type InitConfigApi = { config?: Config; theme: CustomerAppTheme; clientIp: string };

export enum KTemplatesTemplatesName {
  Templates = "templates",
  TemplatesLayout = "templatesLayout",
  TemplatesFooter = "templatesFooter",
}

/**
 * Check that a template is present with at least one column and one widget
 */
export function isTemplatesConfiguredWithWidget(templates: Template[] | undefined): boolean {
  if (!templates || templates.length === 0) {
    return false;
  }
  return templates.some(
    (t) => t.columns && t.columns.some((c) => c.widgets && c.widgets.length > 0),
  );
}

export function isPageVisible(config: Global | undefined, pageName: string): boolean {
  if (!config || !pageName) {
    return true;
  }

  let pageWithoutQuery = pageName;
  if (pageName.indexOf("?") > 0) {
    pageWithoutQuery = pageName.split("?")[0];
  }

  if (config && config?.pages && config?.pages?.visibility) {
    const visible = config?.pages?.visibility?.find((page) => page === pageWithoutQuery);
    return visible !== undefined;
  }
  return true;
}

export type FilterJSONSchemaAgainstRuleProps = {
  schemaToFilter: JSONSchema7Definition;
  uiSchema?: UiSchema;
  ruleCallback: (uiSchema: UiSchema) => boolean;
  shouldEvaluateCallback?: (props: FilterJSONSchemaAgainstRuleProps) => boolean;
  checkOnRequired?: boolean;
};

export const filterJSONSchemaAgainstRule = ({
  schemaToFilter,
  uiSchema,
  ruleCallback,
  shouldEvaluateCallback = () => true,
  checkOnRequired = true,
}: FilterJSONSchemaAgainstRuleProps): void => {
  if (
    shouldEvaluateCallback({
      schemaToFilter,
      uiSchema,
      ruleCallback,
      shouldEvaluateCallback,
      checkOnRequired,
    })
  ) {
    Object.keys(schemaToFilter).forEach((key) => {
      if (key === "properties" && schemaToFilter["type"] === "object") {
        Object.keys(schemaToFilter["properties"]).forEach((property) => {
          if (!uiSchema || !(property in uiSchema) || !ruleCallback(uiSchema)) {
            delete schemaToFilter["properties"][property];
          } else {
            filterJSONSchemaAgainstRule({
              schemaToFilter: schemaToFilter["properties"][property],
              uiSchema: uiSchema[property],
              ruleCallback,
              shouldEvaluateCallback,
              checkOnRequired,
            });
          }
        });
      } else if (key === "items" && schemaToFilter["type"] === "array") {
        filterJSONSchemaAgainstRule({
          schemaToFilter: schemaToFilter["items"],
          uiSchema: uiSchema?.["items"],
          ruleCallback,
          shouldEvaluateCallback,
          checkOnRequired,
        });
      } else if (key === "required" && checkOnRequired) {
        // check if required field is still in schema ? if not, clean required field
        schemaToFilter[key] = schemaToFilter[key].filter((field) => {
          for (const property of Object.keys(schemaToFilter["properties"])) {
            if (property === field) {
              return true;
            }
          }
          return false;
        });
      }
    });
  }
};
