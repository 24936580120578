import React, { FC } from "react";

import { FunctionOverrideKey } from "@keepeek/commons";
import { ComponentOverrideKey } from "@keepeek/refront-customers";
import getConfig from "next/config";
import Head from "next/head";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { useRecoilValueLoadable } from "recoil";

import { CONFIGURATION_SECTION_PATH } from "../../components/layout/adminMenu";
import { getKeycloakConfig } from "../../lib/authentication";
import { CommonPageProps } from "../../lib/commonPagePropsUtil";
import { getCustomerOverrideComponent, getCustomerOverrideFunction } from "../../lib/overrides";
import { Key, Theme } from "../../models/configuration/theme";
import { useConfiguration } from "../../providers/config/hooks";
import { configSectionThemeAssetSelector } from "../../providers/config/selectors";

export type KHeadProps = Pick<CommonPageProps, "title" | "og" | "description">;

const { publicRuntimeConfig } = getConfig();

const KHead: FC<KHeadProps> = function ({ title, description, og }) {
  const faviconQuery = useRecoilValueLoadable(configSectionThemeAssetSelector(Key.ImageFavicon));
  const [favicon] = faviconQuery.state === "hasValue" ? faviconQuery.contents : [undefined];
  const configTheme = useConfiguration<Theme>(CONFIGURATION_SECTION_PATH.THEME);
  let faviconSrc: string | undefined = favicon;
  if (configTheme && configTheme?.favicon) {
    faviconSrc = configTheme?.favicon?.url;
  }
  const router = useRouter();
  const { t } = useTranslation();

  const titleRendered = title ? `${title} — ${t("app.title")}` : t("app.title");

  return (
    <Head>
      {faviconSrc && (
        <>
          <link
            key="favicon32"
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/api/favicon?w=32"
          />
          <link
            key="favicon192"
            rel="icon"
            type="image/png"
            sizes="192x192"
            href="/api/favicon?w=192"
          />
        </>
      )}
      <title key="title">{titleRendered}</title>
      {description && <meta key="description" content={description} />}
      {(title || og?.title) && (
        <meta key="ogTitle" property="og:title" content={og?.title ?? title} />
      )}
      {og?.type && <meta key="ogType" property="og:type" content={og.type} />}
      {og?.url && <meta key="ogUrl" property="og:url" content={og.url} />}
      {og?.image && <meta key="ogImage" property="og:image" content={og.image} />}
      {(description || og?.description) && (
        <meta
          key="ogDescription"
          property="og:description"
          content={og?.description ?? description}
        />
      )}
      <meta key="ogSiteName" property="og:site_name" content={og?.siteName ?? t("app.title")} />
      <meta
        key="viewport"
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width"
      />
      <meta
        key="CSP"
        httpEquiv="Content-Security-Policy"
        content={getCSPHeaderValues({ isRmm: router.pathname === "/player/rmm" })}
      />
      <meta key="robots" name="robots" content="noindex, nofollow" />
    </Head>
  );
};

/**
 * Content Security Policy (CSP) is a computer security standard introduced to prevent cross-site scripting (XSS),
 * clickjacking and other code injection attacks resulting from execution of malicious content in the trusted web page context
 */
function getCSPHeaderValues({ isRmm = false }: { isRmm: boolean }) {
  const rules: Array<string> = [];
  const domainToAllowList = [
    // Google analytics
    "https://www.googletagmanager.com",
    "https://www.google-analytics.com",
    // Keepeek stats
    "https://stats.keepeek.com",
    // MonacoEditor (and other lib ?)
    "https://cdn.jsdelivr.net",
    // PDF lib (and other lib ?)
    "https://cdnjs.cloudflare.com",
    "http://cdnjs.cloudflare.com",
    // Sentry
    "https://o1112474.ingest.sentry.io",
    // Didomi
    "https://sdk.privacy-center.org/",
    "https://api.privacy-center.org/",
  ];

  domainToAllowList.push(...getCustomDomainToAllow());

  const keycloakConfig = getKeycloakConfig();
  if (keycloakConfig && keycloakConfig.url) {
    try {
      const url = new URL(keycloakConfig.url);
      domainToAllowList.push(url.origin);
    } catch (error) {
      console.error("can't add keycloak URL to allowed domain list", error);
    }
  }
  if (publicRuntimeConfig.apiEndpoint) {
    try {
      const url = new URL(publicRuntimeConfig.apiEndpoint);
      domainToAllowList.push(url.origin);
    } catch (error) {
      console.error("can't add api URL to allowed domain list", error);
    }
  }

  if (isRmm) {
    // Enable all iframe src on Rmm page to display rich media detail
    rules.push("frame-src *");
  }
  rules.push(`default-src 'self' ${domainToAllowList.join(" ")}`);
  rules.push(`style-src * 'unsafe-inline'`);
  rules.push(`script-src 'self' 'unsafe-inline' 'unsafe-eval' ${domainToAllowList.join(" ")}`);
  rules.push(`font-src * data:`);
  rules.push(`worker-src blob:`);
  rules.push(`img-src * data: blob:`);
  rules.push(`media-src * blob:`);
  rules.push(`object-src 'none'`);
  rules.push(`connect-src *`);

  return rules.join(";");
}

function getCustomDomainToAllow() {
  const customDomains = getCustomerOverrideFunction<FunctionOverrideKey.CustomDomainsToAllow>(
    FunctionOverrideKey.CustomDomainsToAllow,
    {},
  );

  return customDomains?.domains || [];
}

export default getCustomerOverrideComponent<KHeadProps>(ComponentOverrideKey.KHead, KHead);
