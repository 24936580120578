import { Field } from "@rjsf/core";

/**
 *
 * Custom field to hide an object field. For example a deprecated field.
 */
const CustomFieldHiddenObject: Field = function () {
  return <></>;
};

export default CustomFieldHiddenObject;
