import { useCallback } from "react";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { getAxiosClientInstance } from "../../../lib/axios/axios-utils";
import { resourceUploadLoadingState } from "../../admin/resource/atoms";
import { useInitConfig } from "../../config/hooks/init";
import { frontEditHasBeenClosedXTimesState } from "../atoms/frontEdit";
import { frontEditCurrentContentEditsState } from "../atoms/frontEditCurrentContentEdits";
import { apiEncode } from "../utils/api-encode";
import useFrontEditContext from "./useFrontEditContext";

export type UseFrontEditActions = {
  cancelState: { disable: boolean; loading: boolean };
  publishState: { disable: boolean; loading: boolean };
  handleCancel: () => void;
  handlePublish: () => void;
  handleQuit: () => void;
};

export default function useFrontEditActions(): UseFrontEditActions {
  const { refreshConfig } = useInitConfig();
  const { setFrontEdit } = useFrontEditContext();
  const setHasBeenClosed = useSetRecoilState(frontEditHasBeenClosedXTimesState);

  const [currentContentEdits, setCurrentContentEdits] = useRecoilState(
    frontEditCurrentContentEditsState,
  );

  const resourceUploadLoading = useRecoilValue(resourceUploadLoadingState);

  const publishState = {
    disable: currentContentEdits.length === 0,
    loading: resourceUploadLoading,
  };
  const cancelState = {
    disable: currentContentEdits.length === 0,
    loading: false,
  };

  const handleCancel = () => {
    setCurrentContentEdits([]);
  };

  async function handlePublish() {
    for (const currentContentEdit of currentContentEdits) {
      const widgetId = currentContentEdit.key.widgetId;
      const configSection = currentContentEdit.key.configSection;
      const jsonData = JSON.stringify(
        apiEncode(
          currentContentEdit.jsonSchema,
          currentContentEdit.jsonUiSchema,
          currentContentEdit.data,
        ),
      );

      await getAxiosClientInstance().put(
        widgetId ? `/api/edit/widgets/${configSection}/${widgetId}` : `/api/edit/${configSection}`,
        jsonData,
        {
          baseURL: location.origin,
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
        },
      );
    }
    // Quit front edit
    handleQuit();
  }

  const handleQuit = useCallback(() => {
    // Leave edit mode
    setFrontEdit(false);
    // Remove history
    setCurrentContentEdits([]);
    // Keep count of how much the front edit has been closed
    // to have up to date data in selectors
    setHasBeenClosed((v) => v + 1);
    // get Updated config
    refreshConfig();
  }, [refreshConfig, setCurrentContentEdits, setFrontEdit, setHasBeenClosed]);

  return {
    cancelState,
    publishState,
    handleCancel,
    handlePublish,
    handleQuit,
  };
}
