import { ReactNode, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { CONFIGURATION_SECTION_PATH } from "../../../components/layout/adminMenu";
import { PageName } from "../../../containers/App/utils";
import { Template } from "../../../models/configuration/definitions/template";
import { Home } from "../../../models/configuration/pages/home";
import { Search } from "../../../models/configuration/pages/search";
import { useConfiguration } from "../../config/hooks";
import { CurrentEditKey } from "../atoms/frontEditCurrentEditKeys";
import { EditName } from "../models";
import { useFrontEditContentList } from "./useFrontEditContentList";
import useFrontEditWidgetList from "./useFrontEditWidgetList";

export type SectionEditable = {
  title?: string;
  editableContents: ReactNode[];
};

type UseFrontEditContentEditable = {
  sectionsEditable: SectionEditable[];
};

const mapBetweenContentPageKeyAndCurrentEditKeys = (key: PageName | EditName): CurrentEditKey[] => {
  switch (key) {
    case PageName.LANDING_PAGE:
      return [
        {
          configSection: CONFIGURATION_SECTION_PATH.LAYOUT_LANDING,
        },
      ];
    case EditName.THEME:
      return [{ configSection: CONFIGURATION_SECTION_PATH.THEME }];
    default:
      return [];
  }
};

export default function useFrontEditContentEditable(
  key: PageName | EditName,
): UseFrontEditContentEditable {
  const { t } = useTranslation();
  const homeConfiguration = useConfiguration<Home>(CONFIGURATION_SECTION_PATH.PAGES_HOME);
  const searchConfiguration = useConfiguration<Search>(CONFIGURATION_SECTION_PATH.PAGES_SEARCH);

  // Widgets are already loaded so we can use getter fonctions without keys system
  const { getWidgetsFromTemplate, getWidgetsFromTemplates } = useFrontEditWidgetList();

  // Use keys system here because we need to load content from API where widgets does not need it
  const { contentFromAdminSections } = useFrontEditContentList({
    keys: mapBetweenContentPageKeyAndCurrentEditKeys(key),
  });

  const sectionsEditable: SectionEditable[] = useMemo(() => {
    switch (key) {
      case PageName.HOME_PAGE:
        const homePageTemplates = homeConfiguration?.templates as Template[];
        return [
          {
            title: t("front-edit.section.widget.label"),
            editableContents: getWidgetsFromTemplate(homePageTemplates),
          },
        ];
      case PageName.SEARCH_PAGE:
        const searchPageTemplates: Template[][] = [
          searchConfiguration?.templates as Template[],
          searchConfiguration?.templatesFooter as Template[],
        ];
        return [
          {
            title: t("front-edit.section.widget.label"),
            editableContents: getWidgetsFromTemplates(searchPageTemplates),
          },
        ];
      case PageName.LANDING_PAGE:
      case EditName.THEME:
        return contentFromAdminSections;
      default:
        return [];
    }
  }, [
    contentFromAdminSections,
    getWidgetsFromTemplate,
    getWidgetsFromTemplates,
    homeConfiguration?.templates,
    key,
    searchConfiguration?.templates,
    searchConfiguration?.templatesFooter,
    t,
  ]);

  return {
    sectionsEditable,
  };
}
