import React from "react";

import { FieldFolder } from "@keepeek/refront-components";
import { Widget, WidgetProps } from "@rjsf/core";
import { useTranslation } from "react-i18next";

const CustomFolderWidget: Widget = function ({ onChange, value }: WidgetProps) {
  const { t } = useTranslation();

  return (
    <FieldFolder
      title={t("admin:folderSelectWidget.title")}
      forceSelection={true}
      multiple={false}
      onChange={(values) => onChange(values && values.length > 0 ? values[0] : null)}
      initialFolderIds={[value]}
    />
  );
};

export default CustomFolderWidget;
