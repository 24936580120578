import { useEffect } from "react";

import { DataViewKey, useStatsSearchHit } from "@keepeek/commons";
import { useRecoilState, useRecoilValue } from "recoil";

import logger from "../../../lib/logger-utils";
import { initConfigApiAtom } from "../../config/atoms/init";
import { useDataView } from "../../dataView/hooks/useDataView";
import { statsFolderIdForFolderHit, statsSearchValueForTextAtom } from "../atoms";

// this hook should only exist one time per page
// if not it will send the stats as much times as we have instanciated it
export const useStatsQueueListener = () => {
  const initConfig = useRecoilValue(initConfigApiAtom);
  const { sendFolderSearchHit, sendTextSearchHit } = useStatsSearchHit({
    ip: initConfig?.clientIp,
  });

  // Listen to the search dataView
  const { loading, totalCount } = useDataView(DataViewKey.SEARCH);

  // Folder case
  const [folderIdForFolderHit, setFolderIdForFolderHit] = useRecoilState(statsFolderIdForFolderHit);
  // If we have a folderId waiting to be sent, then resolve the count and send it
  useEffect(() => {
    if (folderIdForFolderHit && !loading && totalCount >= 0) {
      logger.debug(
        `useStatsSearchHitWithResult - sending stat for folder from queue with folderId ${folderIdForFolderHit} and total count of ${totalCount}`,
      );
      sendFolderSearchHit({ folderId: folderIdForFolderHit, resultCount: totalCount });
      setFolderIdForFolderHit(undefined);
    }
  }, [folderIdForFolderHit, loading, sendFolderSearchHit, setFolderIdForFolderHit, totalCount]);

  // Search by text case
  const [searchValueForText, setSearchValueForText] = useRecoilState(statsSearchValueForTextAtom);
  // If we have a text waiting to be sent, then resolve the count and send it
  useEffect(() => {
    if (searchValueForText && !loading && totalCount >= 0) {
      logger.debug(
        `useStatsSearchHitWithResult - sending stat for text search from queue with value ${searchValueForText} and total count of ${totalCount}`,
      );
      sendTextSearchHit({ searchValue: searchValueForText, resultCount: totalCount });
      setSearchValueForText(undefined);
    }
  }, [loading, searchValueForText, sendTextSearchHit, setSearchValueForText, totalCount]);
};
