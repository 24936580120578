import { getResourceFilesByType } from "@keepeek/api-client";
import { isAuthenticatedSelector } from "@keepeek/commons";
import { selector } from "recoil";

import {
  convertToKpkResources,
  resourceType,
} from "../../../lib/admin/react-json-schema/CustomSelectResource/utils";
import { getAxiosClientInstance } from "../../../lib/axios/axios-utils";
import logger from "../../../lib/logger-utils";
import { resourcesAtom } from "../atoms";
import { pageResourcesAtom } from "../atoms/pageResourcesAtom";
import { Resource } from "../hooks";

export const resourcesQuerySelector = selector<{
  resources: Resource[] | undefined;
  totalCount: number | undefined;
}>({
  key: "ResourcesQuerySelector",
  get: async ({ get }) => {
    const isAuthenticated = get(isAuthenticatedSelector);
    if (!isAuthenticated) {
      logger.debug("ResourcesQuerySelector can't fetch without authentication");
      return { resources: [], totalCount: undefined };
    }
    const page = get(pageResourcesAtom);
    get(resourcesAtom);
    const { data: kpkApiResources } = await getResourceFilesByType(getAxiosClientInstance(), {
      type: resourceType,
      page,
    });
    return {
      resources: convertToKpkResources(kpkApiResources._embedded?.["file-resource"]),
      totalCount: kpkApiResources.totalCount,
    };
  },
  set: (
    { set },
    newValue: { resources: Resource[] | undefined; totalCount: number | undefined },
  ) => {
    set(resourcesAtom, newValue);
  },
});
