import { FC } from "react";

import { i18n, Resource } from "i18next";
import { I18nextProvider } from "react-i18next";

import { getInstanceImmediate } from "./i18next/i18next-instance";
import useI18nLanguageSwitch from "./use-i18n-language-switch";

const I18nProvider: FC<{ initialI18nResourceBundle: Resource }> = function ({
  children,
  initialI18nResourceBundle,
}) {
  useI18nLanguageSwitch(initialI18nResourceBundle);

  // At that point the instance should be initialized
  //  - By getServerSideProps if on the server
  //  - By useI18nLanguageSwitch if on the client.
  // So we can get it synchronously
  const instance: i18n = getInstanceImmediate();

  return <I18nextProvider i18n={instance}>{children}</I18nextProvider>;
};

export default I18nProvider;
