import React from "react";

import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDayjs from "@mui/lab/AdapterDayjs";
import { TextField } from "@mui/material";
import { Widget, WidgetProps } from "@rjsf/core";
import { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";

const CustomDate: Widget = function ({ onChange, value, label }: WidgetProps) {
  const { t } = useTranslation();
  const handleChange = (date: Dayjs | null) => {
    if (date) {
      try {
        onChange(date.hour(0).minute(0).second(0).toISOString());
      } catch (err) {
        onChange("");
      }
    } else {
      onChange("");
    }
  };

  // we must force the value "null" if the value is "undefined" or "empty"
  // otherwise the date of the day will be automatically valued OR the field will be in error
  const pickerValue = value ? value : null;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        inputFormat={t("input.date.format")}
        value={pickerValue}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            sx={{
              width: 400,
            }}
            {...params}
          />
        )}
        clearable
      />
    </LocalizationProvider>
  );
};

export default CustomDate;
