import { isAuthenticatedSelector, removeUndefinedDeep } from "@keepeek/commons";
import { UiSchema } from "@rjsf/core";
import { JSONSchema7 } from "json-schema";
import { selectorFamily } from "recoil";

import logger from "../../../lib/logger-utils";
import { frontEditHasBeenClosedXTimesState } from "../atoms/frontEdit";
import { CurrentEditKey } from "../atoms/frontEditCurrentEditKeys";
import { SectionData } from "../hooks/useFrontEditCurrentEdit";
import { axiosFetch } from "../utils";
import { getCurrentContentEditsSelector } from "./currentContentEdits";

export const getJsonSchemaSelector = selectorFamily<JSONSchema7 | undefined, CurrentEditKey>({
  key: "GetJsonSchemaSelector",
  get:
    (key) =>
    async ({ get }) => {
      // Invalidate cache when closed to have up to date data on reopening
      get(frontEditHasBeenClosedXTimesState);

      const isAuthenticated = get(isAuthenticatedSelector);
      if (!isAuthenticated) {
        logger.debug("getJsonSchemaSelector can't fetch without authentication");
        return undefined;
      }
      if (key.configSection) {
        return axiosFetch(`/api/schema/${key.configSection}`);
      }
    },
});

export const getJsonSchemaUiSelector = selectorFamily<UiSchema | undefined, CurrentEditKey>({
  key: "GetJsonSchemaUiSelector",
  get:
    (key) =>
    async ({ get }) => {
      // Invalidate cache when closed to have up to date data on reopening
      get(frontEditHasBeenClosedXTimesState);

      const isAuthenticated = get(isAuthenticatedSelector);
      if (!isAuthenticated) {
        logger.debug("getJsonSchemaUiSelector can't fetch without authentication");
        return undefined;
      }
      if (key.configSection) {
        return axiosFetch(`/api/ui-schema/${key.configSection}`);
      }
    },
});

export const getConfigurationSelector = selectorFamily<Object, CurrentEditKey>({
  key: "GetConfigurationSelector",
  get:
    (key) =>
    async ({ get }) => {
      // Invalidate cache when closed to have up to date data on reopening
      get(frontEditHasBeenClosedXTimesState);

      const isAuthenticated = get(isAuthenticatedSelector);
      if (!isAuthenticated) {
        logger.debug("getWidgetEditoConfiguration can't fetch without authentication");
        return undefined;
      }
      if (key.widgetId && key.configSection) {
        return axiosFetch(`/api/edit/widgets/${key.configSection}/${key.widgetId}`);
      } else {
        return axiosFetch(`/api/edit/${key.configSection}`);
      }
    },
});

export const getDataOfMultipleSectionsSelector = selectorFamily<
  SectionData[],
  { keys: CurrentEditKey[] }
>({
  key: "getSchemasOfMultipleSectionsSelector",
  get:
    ({ keys }) =>
    ({ get }) => {
      const data: SectionData[] = removeUndefinedDeep(
        keys.map((key) => {
          // Get current edited content to display right data when close editor and re-open it
          const currentContentEdits = get(
            getCurrentContentEditsSelector({
              endpoint: key.widgetId ? `${key.configSection}/${key.widgetId}` : key.configSection,
            }),
          );
          const jsonSchemaValue: JSONSchema7 | undefined = get(getJsonSchemaSelector(key));
          const jsonUiSchemaValue: UiSchema | undefined = get(getJsonSchemaUiSelector(key));
          if (jsonSchemaValue && jsonUiSchemaValue) {
            return {
              key,
              jsonSchema: key.widgetId ? jsonSchemaValue.items : jsonSchemaValue,
              jsonUiSchema: key.widgetId ? jsonUiSchemaValue.items : jsonUiSchemaValue,
              configurationData: currentContentEdits
                ? currentContentEdits.data
                : get(getConfigurationSelector(key)),
            };
          }
          return undefined;
        }),
      );
      return data;
    },
});
