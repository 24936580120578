import { FC } from "react";

import { useShareToken } from "@keepeek/commons";
import { BaseComponentProps } from "@keepeek/refront-components";
import ErrorPage from "next/error";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";

import { KWaiter } from "../../components/common/KWaiter";
import { ShareAccessCode } from "../share/ShareAccessCode";

export type ShareCheckAccessProps = {} & BaseComponentProps;

/**
 * Check share access
 */
export const ShareCheckAccess: FC<ShareCheckAccessProps> = function ({ children }) {
  const { t } = useTranslation();
  const router = useRouter();
  // Prefetch share to check if token exists or if an access code is required
  const { loading, shareTokenNotFound, shareTokenUnauthorized } = useShareToken(
    router.pathname,
    router.query.tokenId,
  );

  return (
    <>
      {/** Keep access code dialog in React tree to display an error message for invalid access code */}
      <ShareAccessCode />
      {loading && <KWaiter />}
      {shareTokenNotFound && (
        <ErrorPage statusCode={404} title={t("share.error.token-not-found")} />
      )}
      {!loading && !shareTokenUnauthorized && !shareTokenNotFound && children}
    </>
  );
};
