import { useEffect } from "react";

import { KpkMedia, DataViewKey, BasketKey, References } from "@keepeek/commons";
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from "recoil";

import { Mode } from "../../../containers/DataView/Modes";
import { useLoadableErrorLogger } from "../../utils";
import {
  // TODO: Remove hardcoded displayMode when we're ready to release the Mode "list"
  // dataViewDisplayMode,
  dataViewTotalCount,
  shouldUpdateAdvancedSearchId,
} from "../atoms";
import { dataViewAdvancedSearchId } from "../selectors/create";
import { dataViewElements } from "../selectors/get";
import { advancedSearchIsExpired } from "../utils";

export type UseDataView = {
  elements: KpkMedia[];
  displayMode: Mode;
  isEmpty: boolean;
  loading: boolean;
  createLoading: boolean;
  totalCount: number;
  references: References;
  error: {
    create: undefined | any;
    get: undefined | any;
  };
};

export const useDataView = (key: DataViewKey | BasketKey): UseDataView => {
  // TODO: Remove hardcoded displayMode when we're ready to release the Mode "list"
  // const displayMode = useRecoilValue(dataViewDisplayMode(key));
  const displayMode: Mode = "mosaic";
  const createQuery = useRecoilValueLoadable(dataViewAdvancedSearchId(key));

  const shouldUpdateCreateQuery = useSetRecoilState(shouldUpdateAdvancedSearchId(key));
  const [totalCount, setTotalCount] = useRecoilState(dataViewTotalCount(key));

  const getQuery = useRecoilValueLoadable(dataViewElements(key));

  const createLoading = createQuery.state === "loading";

  const loading = getQuery.state === "loading";

  const elements = getQuery.state === "hasValue" ? getQuery.contents.elements : [];

  const references =
    getQuery.state === "hasValue"
      ? getQuery.contents.references
      : { notFound: [], found: [], all: [] };

  const isEmpty = elements.length === 0 && !createLoading && !loading && totalCount !== -1;

  // Update a deps for createQuery expiration
  useEffect(() => {
    if (createQuery.state === "hasValue" && createQuery.contents) {
      if (advancedSearchIsExpired(createQuery.contents.expirationDate)) {
        shouldUpdateCreateQuery((currVal) => (currVal += 1));
      }
    }
  }, [createQuery.contents, createQuery.state, shouldUpdateCreateQuery]);

  // Set count in an atom so we can keep the count even if the dataView is in loading state
  useEffect(() => {
    if (getQuery.state === "hasValue") {
      setTotalCount(getQuery.contents.totalCount);
    }
  }, [getQuery.contents, getQuery.state, setTotalCount]);

  // Error management
  useLoadableErrorLogger(createQuery, `useDataView createQuery ${key}`);
  useLoadableErrorLogger(getQuery, `useDataView getQuery ${key}`);
  const error: UseDataView["error"] = {
    create: createQuery.state === "hasError" ? createQuery.contents : undefined,
    get: getQuery.state === "hasError" ? getQuery.contents : undefined,
  };

  return {
    elements,
    displayMode,
    isEmpty,
    loading,
    createLoading,
    totalCount,
    references,
    error,
  };
};
