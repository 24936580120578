import React, { useState } from "react";

import { BasketToggler, KButton } from "@keepeek/refront-components";
import {
  Checkbox,
  FormControlLabel,
  Icon,
  Popover,
  Tab,
  Tabs,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import { Widget, WidgetProps } from "@rjsf/core";
import { colord, extend } from "colord";
import namesPlugin from "colord/plugins/names";
import { RgbaStringColorPicker } from "react-colorful";
import { useTranslation } from "react-i18next";

import { KIcon } from "../../../../components/common/KIcon";
import ApplicationExample from "../ApplicationExample";
// eslint-disable-next-line jest/require-hook
extend([namesPlugin]);

export enum SectionId {
  RootPrimaryMain = "root_primary_main",
  RootSecondaryMain = "root_secondary_main",
}
/**
 * Custom Color picker
 */
const CustomColorPicker: Widget = function ({
  onChange,
  value,
  label,
  id,
  formContext,
}: WidgetProps) {
  const { t } = useTranslation("admin");
  const [exampleValue, setExampleValue] = useState(value);
  const colorRGB = colord(value).isValid() ? colord(value).toRgbString() : value;
  const [pickerAnchorEl, setPickerAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const handlePickerOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setPickerAnchorEl(event.currentTarget);
  };
  const handlePickerClose = () => {
    setPickerAnchorEl(null);
    setExampleValue(value);
  };
  const pickerOpen = Boolean(pickerAnchorEl);
  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    // we do not control here what the user enters to give him time to write his color code (#FFF or red or etc...)
    // the control of the validity of the field will be carried out with the "blur" (=> handleInputBlur)
    onChange(event.target.value);
    if (colord(event.target.value).isValid()) {
      setExampleValue(event.target.value);
    }
  };

  const handlePickerChange = (newColor: string) => {
    onChange(colord(newColor).toHex());
  };

  const handleInputBlur = () => {
    if (colord(value).isValid()) {
      // we must convert the color into a
      // format recognized by MUI (for example, if the user enters "orange",
      // we must convert into hex code before saving the value in the database)
      onChange(colord(value).toHex());
      setExampleValue(colord(value).toHex());
    } else {
      onChange("");
    }
  };

  const applicationExample = () => {
    switch (id) {
      case SectionId.RootPrimaryMain:
        return (
          <ApplicationExample>
            <KButton
              key={"theme.applicationExample.downloadButton.label"}
              variant={"outlined"}
              color="primary"
              size="small"
              sx={{
                marginRight: 1,
                padding: (theme) => theme.spacing(0.5, 1),
                color: `${exampleValue} !important`,
                borderColor: `${exampleValue} !important`,
              }}
              startIcon={<KIcon>cloud_download</KIcon>}
              onClick={() => {}}
              onMouseEnter={() => {}}
            >
              {t("theme.applicationExample.downloadButton.label", { count: 3 })}
            </KButton>
            <BasketToggler
              key="basketTogglerExample"
              active
              count={4}
              icon={<Icon>shopping_basket</Icon>}
              onToggle={() => {}}
              sx={{
                color: `${exampleValue} !important`,
                borderColor: `${exampleValue} !important`,
                "& .BasketToggler-count": {
                  color: `${exampleValue} !important`,
                  borderColor: `${exampleValue} !important`,
                },
              }}
            />
          </ApplicationExample>
        );
      case SectionId.RootSecondaryMain:
        return (
          <ApplicationExample>
            <FormControlLabel
              key={id}
              control={
                <Checkbox
                  sx={{ color: `${exampleValue} !important` }}
                  checked
                  onChange={() => {}}
                  name={t("theme.applicationExample.filter.label")}
                  title={t("theme.applicationExample.filter.label")}
                  color="secondary"
                />
              }
              label={
                <Typography
                  variant="caption"
                  noWrap
                  display="block"
                  title={t("theme.applicationExample.filter.label")}
                >
                  {t("theme.applicationExample.filter.label")}
                </Typography>
              }
            />
            <Box
              key="tabExample"
              sx={{ borderBottom: 1, borderColor: `${exampleValue} !important` }}
            >
              <Tabs
                value={1}
                sx={{
                  "& .MuiTabs-indicator": {
                    backgroundColor: `${exampleValue} !important`,
                  },
                }}
              >
                <Tab
                  color="secondary"
                  sx={{
                    color: `${exampleValue} !important`,
                    borderColor: `${exampleValue} !important`,
                  }}
                  value={1}
                  label={t("theme.applicationExample.attachments.label")}
                />
              </Tabs>
            </Box>
          </ApplicationExample>
        );
      default:
        return <></>;
    }
  };

  return (
    <Box sx={{ padding: (theme) => theme.spacing(1) }}>
      <Popover
        open={pickerOpen}
        anchorEl={pickerAnchorEl}
        onClose={handlePickerClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        disableScrollLock={formContext && formContext.isFrontEdit}
      >
        <Box
          sx={{
            margin: 2,
          }}
        >
          <RgbaStringColorPicker color={colorRGB} onChange={handlePickerChange} />
        </Box>
      </Popover>
      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        <Box
          sx={{
            ...(value && { backgroundColor: value }),
            minWidth: 56,
            height: 56,
            mr: 0.5,
            borderRadius: 1,
            boxShadow: "rgb(50 50 93 / 11%) 0px 4px 6px, rgb(0 0 0 / 8%) 0px 1px 3px",
            border: "1px solid rgb(192 192 192)",
            cursor: "pointer",
          }}
          onClick={handlePickerOpen}
        />
        <TextField
          value={value}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          label={t(label)}
          InputLabelProps={{
            // we must manually control the shrink of the
            // field when its value is set from the picker otherwise the shrink does not work
            shrink: !!value,
          }}
          sx={{
            width: 500,
          }}
        />
      </Box>
      {applicationExample()}
    </Box>
  );
};

export default CustomColorPicker;
