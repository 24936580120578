import { useEffect } from "react";

import { currentLocaleAtom } from "@keepeek/commons";
import { useRouter } from "next/router";
import { useRecoilState } from "recoil";

export const useCurrentLocale = (): string => {
  const { locale: currentLocaleRouter, defaultLocale } = useRouter();
  const locale: string = currentLocaleRouter || defaultLocale || "";
  const [currentLocale, setCurrentLocale] = useRecoilState(currentLocaleAtom);
  useEffect(() => {
    if (locale) {
      setCurrentLocale(locale);
    }
  }, [locale, setCurrentLocale]);
  return currentLocale;
};
