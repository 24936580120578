import { isClientSide } from "@keepeek/commons";
import * as Sentry from "@sentry/nextjs";
import { AxiosInstance } from "axios";

import logger from "../../lib/logger-utils";

function addExecutionTimeLogging(axiosInstance: AxiosInstance) {
  axiosInstance.interceptors.request.use((x) => {
    // to avoid overwriting if another interceptor
    // already defined the same object (meta)
    // @ts-ignore : add custom objet : meta
    x.meta = x.meta || {};
    // @ts-ignore : add custom objet : meta
    x.meta.requestStartedAt = new Date().getTime();
    return x;
  });
  axiosInstance.interceptors.response.use((x) => {
    // @ts-ignore : use custom objet : meta
    const timeInMs = new Date().getTime() - x.config.meta.requestStartedAt;
    logger.debug(
      `[AXIOS] URL=${x.config.url} Method=${
        x.config.method
      } ExecutionTime(ms)=${timeInMs} Size(octet)=${x.headers["content-length"]} Accept-Language=${
        x.config?.headers?.["Accept-Language"] ?? ""
      } Status=${x.status}`,
    );
    return x;
  });
}

function addSentryErrorTracker(axiosInstance: AxiosInstance) {
  axiosInstance.interceptors.request.use(
    (res) => res,
    (error) => {
      Sentry.captureException(error);
      return Promise.reject(error);
    },
  );
  axiosInstance.interceptors.response.use(
    (res) => res,
    (error) => {
      Sentry.captureException(error);
      return Promise.reject(error);
    },
  );
}

const addAxiosInterceptors = (axiosInstance: AxiosInstance): void => {
  // Save axios setup in global object in developement mode.
  // Fast refresh execute all script files after a refresh and
  // Axios interceptors will be duplicated.
  // https://github.com/vercel/next.js/issues/7811
  if (process.env.NODE_ENV === "development") {
    if (!(global as any).AXIOS_INTERCEPTORS) {
      logger.debug("[axios] Add Axios interceptors development");
      addExecutionTimeLogging(axiosInstance);
      (global as any).AXIOS_INTERCEPTORS = "init";
    }
  } else {
    logger.debug("[axios] Add Axios interceptors production");
    addExecutionTimeLogging(axiosInstance);
    if (isClientSide()) {
      addSentryErrorTracker(axiosInstance);
    }
  }
};

export default addAxiosInterceptors;
