import {
  internalTechnicalFields,
  isEmbeddedField,
  isMediaField,
  isMediaLinksField,
  MandatoryLinksField,
  MandatoryMetaField,
} from "./field-utils";

/**
 * Format sort value to kpk api sort parameter
 */
export function formatSortValueForKpkApi(sort: string): string {
  const fieldName = sort.toString().split("-")[0];
  const order = sort.toString().split("-")[1];
  return `${fieldName} ${order.toLowerCase()}`;
}

/**
 * Format fields values to kpk api fields parameter
 */
export function formatFieldsForKpkApi(fields: Array<string>): string {
  const mediaFields: string[] = [];
  const metaFields: string[] = [];
  const mediaLinks: string[] = [];
  const embeddedFields: string[] = [];
  const fieldsUniq = [...new Set(fields)]; // removes duplicated fields
  fieldsUniq.forEach((field) => {
    if (isMediaField(field)) {
      mediaFields.push(field);
    } else if (isMediaLinksField(field)) {
      mediaLinks.push(field);
    } else if (isEmbeddedField(field)) {
      embeddedFields.push(field);
    } else {
      metaFields.push(field);
    }
  });
  const params: Array<string> = [];
  params.push(...mediaFields);
  if (metaFields.length > 0) {
    params.push(`_embedded{metadata.byId(${metaFields.join(",")})}`);
  }
  if (mediaLinks.length > 0) {
    params.push(`_links{${mediaLinks.join(",")}}`);
  }
  if (embeddedFields.length > 0) {
    params.push(`_embedded{${embeddedFields.join(",")}}`);
  }

  return params.join(",");
}

/**
 * Add technical and mandatory fields before execute kpk api request
 */
export function addTechnicalAndMandatoryFields(fields: Array<string>): Array<string> {
  const ret = [...fields];
  [
    ...internalTechnicalFields,
    ...Object.values(MandatoryMetaField),
    ...Object.values(MandatoryLinksField),
  ].forEach((f) => {
    if (!ret.includes(f as string)) {
      ret.push(f as string);
    }
  });
  return ret;
}
