import React, { FC, useState } from "react";

import { useShareToken } from "@keepeek/commons";
import { BaseComponentProps, KDialog } from "@keepeek/refront-components";
import { TextField } from "@mui/material";
import clsx from "clsx";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";

export type ShareAccessCodeProps = {} & BaseComponentProps;

export const ShareAccessCode: FC<ShareAccessCodeProps> = function ({ className, id }) {
  const [code, setCode] = useState<string>("");

  const router = useRouter();
  const { loading, shareAccessCodeModal, accessCodeError, setShareAccessCode } = useShareToken(
    router.pathname,
    router.query.tokenId,
  );

  const { t } = useTranslation();

  return (
    <KDialog
      id={id}
      testId="setAccessCodePopup"
      className={clsx(className, "ShareAccessCode", shareAccessCodeModal && "ShareAccessCode-open")}
      open={shareAccessCodeModal}
      actionLabel={t("share.set-access-code.action.access.label")}
      titleLabel={t("share.set-access-code.title.label")}
      loading={loading}
      dialogContent={
        <TextField
          autoFocus
          margin="dense"
          id="ShareAccessCode-input"
          data-testid="ShareAccessCodeField"
          label={t("share.set-access-code.name.label")}
          type="password"
          variant="standard"
          value={code}
          onChange={(event) => {
            setCode(event.target.value);
          }}
          error={accessCodeError}
          helperText={accessCodeError && t("share.set-access-code.error.invalid-access-code.label")}
          fullWidth
          onKeyPress={({ key }) => key === "Enter" && setShareAccessCode(code)}
        />
      }
      handleAction={() => setShareAccessCode(code)}
    />
  );
};
