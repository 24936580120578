import { KpkApiAdvancedSearchFilter } from "@keepeek/api-client";
import {
  fetcherModeAtom,
  isAuthenticatedSelector,
  AdvancedSearchSelector,
  BasketKey,
  DataViewKey,
} from "@keepeek/commons";
import { selectorFamily } from "recoil";

import { extractAdvancedFilters } from "../../../lib/search/search-media";
import { advancedSearchCriteriasSelector } from "../../advancedSearch/selectors";
import { businessFiltersActiveSelector } from "../../businessFilter/selectors";
import { configSectionGlobalPermanentFiltersSelector } from "../../config/selectors";
import { dataViewFetcherSelector } from "../../fetcher/selectors/dataview";
import { shouldNotCreateAdvancedId, shouldUpdateAdvancedSearchId } from "../atoms";
import { mergePermanentFilters } from "../utils";

export const dataViewAdvancedSearchFilterSelector = selectorFamily<
  KpkApiAdvancedSearchFilter[],
  DataViewKey | BasketKey
>({
  key: "DataViewAdvancedSearchFilterSelector",
  get:
    (key) =>
    ({ get }) => {
      const filters = get(businessFiltersActiveSelector(key));
      const permanentFilters = get(configSectionGlobalPermanentFiltersSelector);
      const criterias = get(advancedSearchCriteriasSelector) || [];

      const convertedFilters = extractAdvancedFilters(filters, criterias);
      const filter = mergePermanentFilters(convertedFilters, permanentFilters);

      return filter;
    },
});

export const dataViewAdvancedSearchIdQuery = selectorFamily<
  AdvancedSearchSelector | null,
  { update: number; isAuthenticated: boolean; filterString: string }
>({
  key: "DataViewAdvancedSearchIdQuery",
  get:
    ({ filterString }) =>
    async ({ get }) => {
      const { createAdvancedSearch } = get(dataViewFetcherSelector(get(fetcherModeAtom)));

      const filter: KpkApiAdvancedSearchFilter[] = JSON.parse(filterString);
      const createdAdvancedSearch = await createAdvancedSearch(filter);

      if (!createdAdvancedSearch) {
        return null;
      }

      const advancedSearch = {
        advancedSearchId: createdAdvancedSearch.data.id || "",
        expirationDate: createdAdvancedSearch.data.expirationDate || "",
      };
      return advancedSearch;
    },
});

export const dataViewAdvancedSearchId = selectorFamily<
  AdvancedSearchSelector | null,
  DataViewKey | BasketKey
>({
  key: "DataViewAdvancedSearchId",
  get: (key) =>
    function ({ get }) {
      // Let the possibility to avoid the call
      const shouldNot = get(shouldNotCreateAdvancedId(key));
      if (shouldNot) {
        return null;
      }

      // Need to use shouldUpdate to have the deps refresh because of expiration date
      const isAuthenticated = get(isAuthenticatedSelector);
      const update = get(shouldUpdateAdvancedSearchId(key));
      const filter = get(dataViewAdvancedSearchFilterSelector(key));

      const advancedSearch = get(
        dataViewAdvancedSearchIdQuery({
          update,
          isAuthenticated,
          filterString: JSON.stringify(filter).replaceAll("[{}]", "[]"),
        }),
      );

      return advancedSearch;
    },
});
