import React, { FC } from "react";

import {
  Browsers,
  currentBrowser,
  PageSecurisation,
  useAuthInit,
  useIsAuthenticated,
} from "@keepeek/commons";
import { Box } from "@mui/material";
import { KeycloakConfig } from "keycloak-js";
import { useRouter } from "next/router";

import logger from "../../lib/logger-utils";
import { useAuthRedirectUri } from "../../providers/auth/hooks/useAuthRedirectUri";
import { useKeycloakConfig } from "../../providers/auth/hooks/useKeycloakConfig";
import LoadingAuth from "../auth/LoadingAuth";
import { PagesPath } from "./utils";

type AuthProviderProps = { pageSecurisation?: PageSecurisation };

const AuthProvider: FC<AuthProviderProps> = function ({
  children,
  pageSecurisation = PageSecurisation.None,
}) {
  // init Keycloak configuration
  const { keycloakConfig } = useKeycloakConfig();
  const router = useRouter();

  if (
    !keycloakConfig &&
    pageSecurisation !== PageSecurisation.Disable &&
    router.pathname !== PagesPath.LANDING_PAGE
  ) {
    return (
      <>
        <LoadingAuth />
        <Box sx={{ display: "none" }}>{children}</Box>
      </>
    );
  }

  if (!keycloakConfig || pageSecurisation === PageSecurisation.Disable) {
    logger.debug("AuthProvider - Disabled because of page securisation policy on rendered page");

    return <>{children}</>;
  }
  return (
    <AuthConsumer pageSecurisation={pageSecurisation} kcConfig={keycloakConfig}>
      {children}
    </AuthConsumer>
  );
};

const AuthConsumer: FC<{
  kcConfig: KeycloakConfig | undefined;
  pageSecurisation: PageSecurisation;
}> = function ({ children, kcConfig, pageSecurisation }) {
  // Init Keycloak authentication configuration
  useAuthInit(kcConfig);
  const { isAuthenticated, loading } = useIsAuthenticated();
  const { redirectPath } = useAuthRedirectUri();
  const router = useRouter();

  if (!loading && !isAuthenticated && pageSecurisation === PageSecurisation.Full) {
    // Hard redirect for Firefox
    // It does not reevaluate the Redirect Page everytime if we use router.push in FF
    // This can cause a loop in FF
    if (currentBrowser() === Browsers.MozillaFirefox) {
      location.href = redirectPath;
    } else {
      router.push(redirectPath);
    }
    return <LoadingAuth />;
  }

  return <LoadingAuth>{children}</LoadingAuth>;
};

export default AuthProvider;
