import {
  KpkApiFacet,
  KpkApiGetAdvancedSearchParams,
  KpkApiPagedResult,
  KpkApiSearchMediasEmbeddedMapping,
} from "@keepeek/api-client";
import {
  fetcherModeAtom,
  currentLocaleAtom,
  sheetFieldsSelector,
  formQuerySelector,
  BasketKey,
  DataViewKey,
  KpkFilterType,
  KpkMedia,
  References,
  FunctionOverrideKey,
  customSelectorInOut,
  customerCommonOverrideAtom,
} from "@keepeek/commons";
import { selectorFamily } from "recoil";

import { getBusinessFilterFullTextValue } from "../../../lib/business-filter-utils";
import {
  convertToKpkMedia,
  getFieldsForApi,
  getVisualAlertsFieldsForApi,
} from "../../../lib/field-utils";
import {
  addTechnicalAndMandatoryFields,
  formatFieldsForKpkApi,
  formatSortValueForKpkApi,
} from "../../../lib/kpk-api-utils";
import logger from "../../../lib/logger-utils";
import {
  extractQueryFilters,
  extractQueryFiltersForFieldParameter,
  FqType,
  hasCriterias,
} from "../../../lib/search/search-media";
import { businessFiltersActiveSelector } from "../../businessFilter/selectors";
import {
  configSectionComponentDownloadManagerSelector,
  configSectionPageSearchSelector,
} from "../../config/selectors";
import { dataViewFetcherSelector } from "../../fetcher/selectors/dataview";
import { dataViewPage, dataViewSize, shouldRefreshGetAdvancedSearchQuery } from "../atoms";
import { advancedSearchIsExpired } from "../utils";
import { dataViewAdvancedSearchId } from "./create";
import { dataViewSortSelector } from "./sort";

type GetAdvancedSearchQueryKey = {
  searchParamsString: string;
  shouldRefreshId: number;
};

export const getAdvancedSearchQuery = selectorFamily<
  KpkApiPagedResult<KpkApiSearchMediasEmbeddedMapping> | undefined,
  GetAdvancedSearchQueryKey
>({
  key: "GetAdvancedSearchQuery",
  get:
    ({ searchParamsString }) =>
    async ({ get }) => {
      // We want the advanced search to be refreshed with current locale because of facet filtering returned by the API
      get(currentLocaleAtom);

      let searchParams: KpkApiGetAdvancedSearchParams = JSON.parse(searchParamsString);
      const { getAdvancedSearch } = get(dataViewFetcherSelector(get(fetcherModeAtom)));

      if (searchParams.fq?.some((q) => q.includes(FqType.Basket))) {
        // If we're in an advanced search filtered by basket
        // We want to handle the error 400 without notification at this stage
        searchParams = {
          ...searchParams,
          axiosRequestConfig: { disableNotificationErrorCodes: [400] },
        };
      }

      const advancedSearch = await getAdvancedSearch(searchParams);
      if (!advancedSearch) {
        return undefined;
      }

      const { data } = advancedSearch;
      return data;
    },
});

export const dataViewElements = selectorFamily<
  {
    elements: KpkMedia[];
    totalCount: number;
    facets: KpkApiFacet[];
    references: References;
  },
  DataViewKey | BasketKey
>({
  key: "DataViewElements",
  get:
    (key) =>
    ({ get }) => {
      const empty = {
        elements: [],
        totalCount: -1,
        facets: [],
        references: { notFound: [], found: [], all: [] },
      };

      // Custom IN
      const { advancedSearch, page, size, sort, sheetFields, filters, customFields } =
        customSelectorInOut(
          FunctionOverrideKey.DataViewElementsIn,
          {
            key,
            advancedSearch: get(dataViewAdvancedSearchId(key)),
            page: get(dataViewPage(key)),
            size: get(dataViewSize(key)),
            sort: get(dataViewSortSelector(key)),
            sheetFields: get(sheetFieldsSelector) ?? [],
            filters: get(businessFiltersActiveSelector(key)),
            customFields: [],
          },
          get(customerCommonOverrideAtom),
        );

      // Don't call if we don't have an advancedSearchId
      if (!advancedSearch) {
        logger.debug("dataViewElements : no advancedSearchId to make the call");
        return empty;
      }

      const customerConfigSectionPageSearch = get(configSectionPageSearchSelector);
      const { enableOtherFormats } = get(configSectionComponentDownloadManagerSelector) ?? {
        enableOtherFormats: undefined,
      };
      const fields = addTechnicalAndMandatoryFields([
        ...getFieldsForApi(
          customerConfigSectionPageSearch?.technicalFieldsToDisplay ?? {},
          key && key === BasketKey.PANEL,
        ),
        ...getFieldsForApi(customerConfigSectionPageSearch?.functionalFieldsToDisplay ?? {}),
        ...getVisualAlertsFieldsForApi(customerConfigSectionPageSearch?.visualAlerts ?? []),
        ...customFields,
        ...(enableOtherFormats ? ["attachments"] : []),
      ]);

      // Don't make the call if the expiration date is due
      // Wait for a new advanced search id
      if (advancedSearchIsExpired(advancedSearch?.expirationDate ?? "")) {
        logger.debug("dataViewElements : advancedSearchIsExpired");
        return empty;
      }

      const searchParams: KpkApiGetAdvancedSearchParams = {
        advancedSearchId: advancedSearch?.advancedSearchId ?? "",
        page,
        size,
        sort: formatSortValueForKpkApi(sort),
        fields: formatFieldsForKpkApi(fields),
        q: getBusinessFilterFullTextValue(filters),
        fq: extractQueryFilters(filters),
        f: extractQueryFiltersForFieldParameter(filters), //TODO : we have to add f parameters for advanced search because of API. Hope an update to use filters criterias in advanced search...
        notFoundReference: hasCriterias(filters),
      };

      const shouldRefreshId = get(shouldRefreshGetAdvancedSearchQuery(key));
      const advancedSearchResult = get(
        getAdvancedSearchQuery({
          searchParamsString: JSON.stringify(searchParams),
          shouldRefreshId,
        }),
      );
      let elementsOut: KpkMedia[] = [];
      if (advancedSearchResult?._embedded?.media && sheetFields) {
        elementsOut =
          advancedSearchResult._embedded.media
            .map((KpkApiMedia) =>
              convertToKpkMedia(get(formQuerySelector), KpkApiMedia, sheetFields),
            )
            .filter((kpkMedia): kpkMedia is KpkMedia => kpkMedia !== undefined) || [];
      }

      const notFoundReferences: string[] = [];
      const foundReferences: string[] = [];
      const allReferences: string[] = [];
      if (advancedSearchResult?._embedded?.["notFoundReference"]) {
        let i = 0;
        filters.forEach((f) => {
          // We don't need found and not found references for folders
          if (f.filter.type !== KpkFilterType.Folder) {
            const notFoundReferenceForFilter =
              advancedSearchResult._embedded?.["notFoundReference"][i];
            if (notFoundReferenceForFilter) {
              notFoundReferenceForFilter.forEach((r) => {
                notFoundReferences.push(r);
              });
            }
            f.filter.values.forEach((v) => {
              allReferences.push(v.label);
              if (!notFoundReferences.includes(v.label)) {
                foundReferences.push(v.label);
              }
            });
          }
          i++;
        });
      }

      const facetsOut: KpkApiFacet[] = [];
      if (advancedSearchResult?._embedded?.facet) {
        facetsOut.push(...advancedSearchResult._embedded.facet);
      }

      const referencesOut: References = {
        notFound: notFoundReferences,
        found: foundReferences,
        all: allReferences,
      };
      // Custom out
      const { elements, facets, totalCount, references } = customSelectorInOut(
        FunctionOverrideKey.DataViewElementsOut,
        {
          key,
          elements: elementsOut,
          facets: facetsOut,
          totalCount: advancedSearchResult?.totalCount ?? 0,
          references: referencesOut,
        },
        get(customerCommonOverrideAtom),
      );

      return {
        elements,
        facets,
        totalCount,
        references,
      };
    },
});
