import { isServerSide, parseStringParameter, removeHash } from "@keepeek/commons";
import { useRouter } from "next/router";

import { PagesPath } from "../../../containers/App/utils";

/**
 * Keycloak hook to compute redirect URI for login and logout
 */
export function useAuthRedirectUri(): {
  redirectPath: string;
  redirectUri: string;
} {
  const { locale, pathname, asPath, query } = useRouter();

  // Check if user come from a HTTP redirection on token error. It can occurs if unauthenticated user try to access a
  // page requiring an authentication.
  const pathRedirect = parseStringParameter(query.pathRedirect);

  let redirectPath: string;
  let redirectUri: string;
  if (isServerSide()) {
    redirectPath = "";
    redirectUri = "";
  } else {
    if (pathRedirect) {
      redirectPath = pathRedirect;
      redirectUri = `${window.location.origin}/${locale}/redirect?path=${redirectPath}`;
    } else {
      if (pathname === "/") {
        // Redirect to home after authentication
        redirectPath = PagesPath.HOME_PAGE;
        redirectUri = `${window.location.origin}/${locale}/redirect?path=${redirectPath}`;
      } else if (pathname === "/redirect") {
        // Avoid adding multiple /redirect in this case
        redirectPath = asPath;
        redirectUri = `${window.location.origin}/${locale}${redirectPath}`;
      } else {
        // Keep requested path
        redirectPath = asPath;
        redirectUri = `${window.location.origin}/${locale}/redirect?path=${redirectPath}`;
      }
    }
  }

  // Remove hash because Keycloak JavaScript adapter adds information while cheking authentication state and returning back
  // to ReFront: http://localhost:3000/redirect?path=/home#error=login_required&state=c2d7448c-b887-4e6b-8814-469590aea4f2
  // Redirect path should not contains those information or Keycloak adapter won't initialize correctly. onAuthError and
  // onInitError Keycloak events are trigerred and SSRKeycloakProvider will get stucked on LoadingComponent.

  return {
    redirectPath: removeHash(redirectPath),
    redirectUri: removeHash(redirectUri),
  };
}
