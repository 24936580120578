import React, { useEffect, useMemo } from "react";

import { FRONT_EDIT_SIDE_BAR_TAB, FrontEditPage } from "@keepeek/refront-components";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";

import { PageName, PagesPath } from "../../../containers/App/utils";
import { frontEditState } from "../atoms/frontEdit";
import {
  frontEditCurrentRouterPageState,
  frontEditSideBarContextState,
} from "../atoms/frontEditNavigation";
import useFrontEditContentEditable from "./useFrontEditContentEditable";

export type UseFrontEditContext = {
  frontEdit: boolean;
  setFrontEdit: (frontEdit: boolean) => void;
  editablePages: FrontEditPage[];
  sideBarContext: FRONT_EDIT_SIDE_BAR_TAB;
  handleTabChange: (event: React.SyntheticEvent, value: FRONT_EDIT_SIDE_BAR_TAB) => void;
  currentRouterPage: string;
  handlePageChange: (page: string) => void;
};

export default function useFrontEditContext(): UseFrontEditContext {
  const [frontEdit, setFrontEdit] = useRecoilState(frontEditState);

  const [sideBarContext, setSideBarContext] = useRecoilState(frontEditSideBarContextState);
  const [currentRouterPage, setCurrentRouterPage] = useRecoilState(frontEditCurrentRouterPageState);

  const router = useRouter();

  const mapPathNameToPageName = (pathName: string) => {
    switch (pathName) {
      case PagesPath.HOME_PAGE:
        return PageName.HOME_PAGE;
      case PagesPath.SEARCH_PAGE:
        return PageName.SEARCH_PAGE;
      case PagesPath.LANDING_PAGE:
        return PageName.LANDING_PAGE;
      case PagesPath.ELEMENT_PAGE:
        return PageName.ELEMENT_PAGE;
      default:
        return PageName.HOME_PAGE;
    }
  };

  const key = useMemo(
    () => mapPathNameToPageName(router?.pathname || PagesPath.HOME_PAGE),
    [router?.pathname],
  );

  const { sectionsEditable } = useFrontEditContentEditable(key);

  const handleTabChange = (_: React.SyntheticEvent, value: FRONT_EDIT_SIDE_BAR_TAB) => {
    setSideBarContext(value);
  };

  const { t } = useTranslation();

  const editablePages: FrontEditPage[] = useMemo(() => {
    return [
      {
        key: PageName.HOME_PAGE,
        label: t("front-edit.selection-modal.home.label") as string,
        pagePath: PagesPath.HOME_PAGE,
        sections: key === PageName.HOME_PAGE ? sectionsEditable : undefined,
      },
      {
        key: PageName.SEARCH_PAGE,
        label: t("front-edit.selection-modal.search.label"),
        pagePath: PagesPath.SEARCH_PAGE,
        sections: key === PageName.SEARCH_PAGE ? sectionsEditable : undefined,
      },
      {
        key: PageName.LANDING_PAGE,
        label: t("front-edit.selection-modal.landing.label"),
        pagePath: PagesPath.LANDING_PAGE,
        sections: key === PageName.LANDING_PAGE ? sectionsEditable : undefined,
      },
    ];
  }, [key, sectionsEditable, t]);

  const handlePageChange = (key: string) => {
    const selectedPage = editablePages.find((page) => page.key === key);
    if (selectedPage && sideBarContext === FRONT_EDIT_SIDE_BAR_TAB.PAGES) {
      router.push(selectedPage.pagePath);
    }
  };

  useEffect(() => {
    const currentPath = router?.pathname || PagesPath.HOME_PAGE;
    const currentPage = editablePages.find((page) => page.pagePath === currentPath);
    if (currentPage) {
      setCurrentRouterPage(currentPage.key);
    }
  }, [editablePages, router?.pathname, setCurrentRouterPage]);

  return {
    frontEdit,
    setFrontEdit,
    editablePages,
    sideBarContext,
    handleTabChange,
    currentRouterPage,
    handlePageChange,
  };
}
