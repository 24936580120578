export interface Template {
    backgroundColor?:      string;
    classNames?:           string;
    columns?:              Column[];
    container?:            boolean;
    itemTitle?:            string;
    margin?:               number;
    padding?:              number;
    verticallyExtendable?: boolean;
}

export interface Column {
    backgroundColor?: string;
    breakpoints?:     Breakpoint;
    classNames?:      string;
    itemTitle?:       string;
    widgets?:         WidgetSchema[];
}

export interface Breakpoint {
    lg?: number;
    md?: number;
    sm?: number;
    xl?: number;
    xs?: number;
}

export interface WidgetSchema {
    classNames?: string;
    id:          string;
    type:        Type;
}

export enum Type {
    Associations = "associations",
    Attachments = "attachments",
    Custom = "custom",
    Edito = "edito",
    Footer = "footer",
    Header = "header",
    Image = "image",
    Mosaic = "mosaic",
    Search = "search",
    Sheet = "sheet",
    Spacer = "spacer",
    Tabs = "tabs",
    Video = "video",
}
