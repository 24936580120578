import { sheetFieldsSelector, BasketKey, BusinessFilter, DataViewKey } from "@keepeek/commons";
import uniq from "lodash/uniq";
import { selectorFamily } from "recoil";

import {
  convertToBusinessFilter,
  convertToLightBusinessFilter,
  getAvailableBusinessFiltersFromConfig,
} from "../../lib/business-filter-utils";
import { advancedSearchCriteriasSelector } from "../advancedSearch/selectors";
import { dataViewPage, dataViewTotalCount } from "../dataView/atoms";
import { dataViewElements } from "../dataView/selectors/get";
import {
  businessFilterConfiguration,
  expandedBusinessFiltersAtom,
  lightBusinessFilterActiveFilters,
} from "./atoms";

export const businessFiltersAvailableSelector = selectorFamily<BusinessFilter[], DataViewKey>({
  key: "BusinessFiltersAvailableSelector",
  get:
    (key) =>
    ({ get }) => {
      try {
        const filtersConfig = get(businessFilterConfiguration(key));
        const sheetFields = get(sheetFieldsSelector);
        const criterias = get(advancedSearchCriteriasSelector) || [];
        const facets = get(dataViewElements(key)).facets;
        return getAvailableBusinessFiltersFromConfig(filtersConfig, facets, sheetFields, criterias);
      } catch (error) {
        return [];
      }
    },
});

export const businessFiltersActiveSelector = selectorFamily<
  BusinessFilter[],
  DataViewKey | BasketKey
>({
  key: "BusinessFiltersActiveSelector",
  get:
    (key) =>
    ({ get }) => {
      try {
        const sheetFields = get(sheetFieldsSelector);
        const criterias = get(advancedSearchCriteriasSelector) || [];
        const lightBusinessFilters = get(lightBusinessFilterActiveFilters(key));
        return convertToBusinessFilter(lightBusinessFilters, sheetFields, criterias);
      } catch (error) {
        return [];
      }
    },
  set:
    (key) =>
    ({ set }, newValue: []) => {
      // When updating filters, return to page one
      set(dataViewPage(key), 1);
      // We will load a new searchId so dump the sync totalCount
      set(dataViewTotalCount(key), -1);
      // Set the filters in order to trigger the reflow
      set(lightBusinessFilterActiveFilters(key), convertToLightBusinessFilter(newValue));
    },
});

export const expandedBusinessFilterSelector = selectorFamily<
  boolean,
  { key: DataViewKey; filterId: BusinessFilter["filter"]["id"] }
>({
  key: "ExpandedBusinessFilterSelector",
  get:
    ({ key, filterId }) =>
    ({ get }) =>
      get(expandedBusinessFiltersAtom(key)).includes(filterId),
  set:
    ({ key, filterId }) =>
    ({ set }, add: boolean) =>
      set(expandedBusinessFiltersAtom(key), (currVal: BusinessFilter["filter"]["id"][]) =>
        add ? uniq([...currVal, filterId]) : currVal.filter((cv) => cv !== filterId),
      ),
});
