import React from "react";

import { useSheetFields } from "@keepeek/commons";
import { Autocomplete, FormControl, LinearProgress, TextField } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import { Widget, WidgetProps } from "@rjsf/core";
import { getI18n, useTranslation } from "react-i18next";

import { KAdminFields } from "../../../../models/configuration/types/kAdminFields";
import { KAdminMediaFields } from "../../../../models/configuration/types/kAdminMediaFields";
import { convertToFieldList, getTranslatedAndSortedMediaFields } from "../../../field-utils";

const CustomFieldsSelectWidget: Widget = function ({
  label,
  options,
  onChange,
  required,
  value,
  schema,
}: WidgetProps) {
  const { loading, sheetFields } = useSheetFields();
  const { t } = useTranslation();

  const translatedAndSortedMediaFields: KAdminMediaFields[] = getTranslatedAndSortedMediaFields();

  const fieldOrSection = schema["fieldOrSection"];

  if (loading) {
    return <LinearProgress />;
  }

  let completeFieldList: KAdminFields[] = [];
  if (options?.fieldListType) {
    if (options.fieldListType === "functional") {
      completeFieldList = convertToFieldList(sheetFields, []);
    } else if (options.fieldListType === "technical") {
      completeFieldList = convertToFieldList([], translatedAndSortedMediaFields);
    }
  } else {
    completeFieldList = convertToFieldList(sheetFields, translatedAndSortedMediaFields);
  }

  // Add a field for unknown values to manage backward compatibility
  const valueIsInList = !!completeFieldList.find((f) => f.id === value);
  if (!valueIsInList && value !== undefined) {
    completeFieldList.push({
      id: value,
      name: value,
      type: getI18n().t("unknownField"),
    });
  }

  const labelRender = required && label ? `${label}*` : label;

  const formControlStyle: SxProps<Theme> = {
    marginTop: 1,
    marginBottom: 1,
  };

  if (!fieldOrSection || fieldOrSection === "field") {
    const valueComputed = () => {
      const field = completeFieldList.find((f) => f.id === value);
      if (field) {
        return `${field.name} (${field.type})`;
      }
      return value;
    };
    return (
      <FormControl sx={formControlStyle}>
        <Autocomplete
          options={completeFieldList.map((field) => ({
            id: field.id,
            label: `${field.name} (${field.type})`,
          }))}
          renderInput={(params) => <TextField {...params} label={labelRender} />}
          onChange={(_e, value) => onChange(value?.id)}
          value={valueComputed()}
          openOnFocus
          isOptionEqualToValue={(option, value) => option?.label === value}
        />
      </FormControl>
    );
  } else {
    return (
      <FormControl sx={formControlStyle}>
        <TextField
          value={value}
          label={labelRender}
          helperText={t("admin:fieldsSectionsItem.section.value.description")}
          onChange={({ target }) => onChange(target.value)}
        />
      </FormControl>
    );
  }
};

export default CustomFieldsSelectWidget;
