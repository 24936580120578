import useFrontEditActions, { UseFrontEditActions } from "./useFrontEditActions";
import useFrontEditContext, { UseFrontEditContext } from "./useFrontEditContext";

type UseFrontEdit = {} & UseFrontEditContext & UseFrontEditActions;

export default function useFrontEdit(): UseFrontEdit {
  const frontEditContext = useFrontEditContext();
  const frontEditActions = useFrontEditActions();

  return {
    ...frontEditContext,
    ...frontEditActions,
  };
}
