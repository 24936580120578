import React, { FC } from "react";

import { Box } from "@mui/material";
import { ArrayFieldTemplateProps } from "@rjsf/core";
import { ArrayFieldTemplate } from "@rjsf/material-ui";
import { useTranslation } from "react-i18next";

/**
 * Custom array field template to change add item label waiting for
 * https://github.com/rjsf-team/react-jsonschema-form/pull/2348/files
 */
export const CustomArrayFieldTemplate: FC<ArrayFieldTemplateProps> = function (
  props: ArrayFieldTemplateProps,
) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        ".array-item-add": {
          fontSize: "0",
          "&:after": {
            content: `"${t("action.add").replace('"', "")}"`,
            fontSize: "1rem",
          },
        },
      }}
    >
      <ArrayFieldTemplate {...props} />
    </Box>
  );
};
