import { SanitizeHtml } from "@keepeek/refront-components";
import { AjvError, Field, FormProps } from "@rjsf/core";
import { TFunction } from "i18next";
import { JSONSchema7 } from "json-schema";
import isObject from "lodash/isObject";
import transform from "lodash/transform";
import { getI18n } from "react-i18next";

import CustomCheckbox from "./CustomCheckbox";
import CustomColorPicker from "./CustomColorPicker";
import CustomDate from "./CustomDate";
import CustomEditor from "./CustomEditor";
import CustomFieldHiddenObject from "./CustomFieldHiddenObject";
import CustomFieldId from "./CustomFieldId";
import CustomFieldsSelectWidget from "./CustomFieldsSelectWidget";
import CustomFolderWidget from "./CustomFolderField";
import CustomFormsSelectWidget from "./CustomFormsSelectWidget";
import CustomNumberWidget from "./CustomNumberWidget";
import CustomSelectResource from "./CustomSelectResource";
import CustomWidgetId from "./CustomWidgetId";
import CustomWidgetSelector from "./CustomWidgetSelector";
import CustomWidgetUrlText from "./CustomWidgetUrlText";
import CustomWysiwyg from "./CustomWysiwyg";

/**
 * Adapt JSON Schema for i18n :
 * - Find "i18n-*" key in jsonschema, and put in value the associated translation.
 * - Rename "i18n-*" key to delete "i18n-" in order to be compatible for "rjsf" library
 */
const i18nStartKey = "i18n-";
export const i18nJsonSchema = function (jsonSchema: any, t: TFunction): JSONSchema7 {
  return transform(jsonSchema, function (result: JSONSchema7, value, key) {
    let adaptedKey = key;
    let adaptedValue = value;
    if (typeof adaptedKey === "string" && adaptedKey.startsWith(i18nStartKey)) {
      adaptedKey = adaptedKey.substr(i18nStartKey.length);
      adaptedValue = t(`admin:${value}`);
    }
    result[adaptedKey] = isObject(adaptedValue) ? i18nJsonSchema(adaptedValue, t) : adaptedValue;
  });
};

export const customWidgets: FormProps<any>["widgets"] = {
  ColorWidget: CustomColorPicker,
  wysiwyg: CustomWysiwyg,
  editor: CustomEditor,
  date: CustomDate,
  customNumber: CustomNumberWidget,
  fields: CustomFieldsSelectWidget,
  folders: CustomFolderWidget,
  fieldId: CustomFieldId,
  widgetId: CustomWidgetId,
  customCheckbox: CustomCheckbox,
  customWidgetUrlText: CustomWidgetUrlText,
  customFormsSelectWidget: CustomFormsSelectWidget,
};

const CustomDescriptionField: Field = function ({ description }) {
  return (description && <SanitizeHtml html={description} />) || null;
};

export const customFields: FormProps<any>["fields"] = {
  widgetSelector: CustomWidgetSelector,
  // Come from the lib, it should stay in CamelCase and with this name to apply it automatically
  DescriptionField: CustomDescriptionField,
  resourceSelect: CustomSelectResource,
  hiddenObject: CustomFieldHiddenObject,
};

/**
 * Tranform errors (applied at field level only)
 * Waiting for a better way to achieve label update: https://github.com/rjsf-team/react-jsonschema-form/issues/2718
 */
export const transformErrors = function (errors: AjvError[]) {
  return errors.map((error) => {
    if (
      error.name === "type" &&
      error.params.type === "number" &&
      error.property.includes("folderIds")
    ) {
      error.message = getI18n().t("admin:folderSelectWidget.empty.error");
    }
    return error;
  });
};
