import React from "react";

import { BaseComponentProps } from "@keepeek/refront-components";
import { Icon, IconProps } from "@mui/material";
import clsx from "clsx";
import Image from "next/image";

import { Icons } from "../../../models/configuration/icons";
import { useConfiguration } from "../../../providers/config/hooks";
import { CONFIGURATION_SECTION_PATH } from "../../layout/adminMenu";

export type KIconProps = {} & IconProps & BaseComponentProps;

/**
 * Keepeek overridable icon
 */
export const KIcon = function ({ children, className, ...rest }: KIconProps) {
  const iconsConfig = useConfiguration<Icons>(CONFIGURATION_SECTION_PATH.ICONS);
  let content = children;
  if (iconsConfig && iconsConfig.icons) {
    const conf = iconsConfig.icons.find((icon: any) => icon.key === children);

    if (conf) {
      if (conf.material) {
        content = conf.material;
      } else if (conf.svg) {
        /* Force 24x24px to match Material UI rule specified in https://material-ui.com/components/icons/ :
         * SVG elements should be scaled for a 24x24px viewport, so the resulting icon can be used as is,
         * or included as a child for other Material-UI components that use icons.
         */
        content = <Image width={24} height={24} src={conf.svg} alt={"icon"} aria-hidden={true} />;
      }
    }
  }

  return (
    <Icon className={clsx("KIcon", className)} {...rest}>
      {content}
    </Icon>
  );
};
