import { useCallback, useEffect, useMemo, useState } from "react";

import {
  API_NOT_ALLOWED,
  getLoggerAtom,
  isClientSide,
  KCConsumerStatus,
  keycloakInstanceAtom,
  keycloakMinValidity,
  PageSecurisation,
  useAuthRefresh,
  useIsAuthenticated,
  useStatsConnectionHit,
  ERROR_CODE_QUERY_PARAM,
} from "@keepeek/commons";
import { useRouter } from "next/router";
import { useRecoilValue } from "recoil";

import { useInitConfig } from "../../config/hooks/init";
import { pageSecurisationAtom } from "../atoms/securisation";
import { useAuthRedirectUri } from "./useAuthRedirectUri";

type UsePageAuthenticated = {
  forceShow: boolean;
  forceHide: boolean;
  status: KCConsumerStatus;
};

export const usePageAuthenticated = (): UsePageAuthenticated => {
  const { isAuthenticated, loading } = useIsAuthenticated();
  const { redirectUri } = useAuthRedirectUri();
  const { refresh } = useAuthRefresh();
  const pageSecurisation = useRecoilValue(pageSecurisationAtom);
  const router = useRouter();
  const { initConfig: initApiConfig } = useInitConfig();
  const keycloackInstance = useRecoilValue(keycloakInstanceAtom);

  const status = useCallback(() => {
    switch (pageSecurisation) {
      case PageSecurisation.Full:
        if (isAuthenticated) {
          return KCConsumerStatus.Authorized;
        }
        if (loading) {
          return KCConsumerStatus.Loading;
        }
        if (isClientSide()) {
          location.href = redirectUri;
        }
      case PageSecurisation.Hybrid:
      default:
        return KCConsumerStatus.Authorized;
    }
  }, [isAuthenticated, loading, pageSecurisation, redirectUri]);

  const forceHide = useMemo(() => pageSecurisation === PageSecurisation.None, [pageSecurisation]);
  const forceShow = useMemo(
    () => router.pathname === "/redirect" || keycloackInstance === undefined,
    [router.pathname, keycloackInstance],
  );

  // Send Connection stat hit
  const [hitSent, setHitSent] = useState(false);
  const { sendConnectionHit } = useStatsConnectionHit({ ip: initApiConfig?.clientIp });

  useEffect(() => {
    if (
      isAuthenticated &&
      router.query[ERROR_CODE_QUERY_PARAM] !== API_NOT_ALLOWED &&
      !hitSent &&
      initApiConfig
    ) {
      sendConnectionHit();
      setHitSent(true);
    } else if (!isAuthenticated && initApiConfig && hitSent) {
      sendConnectionHit();
    }
  }, [hitSent, initApiConfig, isAuthenticated, router.query, sendConnectionHit]);

  // Check if we have to refresh the token on router change
  const logger = useRecoilValue(getLoggerAtom);
  const checkRefreshOnRouteChange = useCallback(() => {
    try {
      if (
        pageSecurisation === PageSecurisation.Full &&
        !loading &&
        keycloackInstance?.isTokenExpired(keycloakMinValidity) &&
        keycloackInstance.authenticated
      ) {
        refresh();
      }
    } catch (error) {
      logger && logger().debug(`${error} ("Should be considered as a normal error in FF...")`);
    }
  }, [keycloackInstance, loading, logger, pageSecurisation, refresh]);

  useEffect(() => {
    checkRefreshOnRouteChange();
  }, [checkRefreshOnRouteChange, router.pathname, router.query]);

  return {
    forceShow,
    forceHide,
    status: status(),
  };
};
