import { FC } from "react";

import { useShare } from "@keepeek/commons";
import { BaseComponentProps } from "@keepeek/refront-components";
import { useRouter } from "next/router";

import { ShareCheckAccess } from "./ShareCheckAccess";

export type ShareProps = {} & BaseComponentProps;

/**
 * Manage share access
 */
const Share: FC<ShareProps> = function ({ children }) {
  const router = useRouter();
  const { isShare } = useShare(router.pathname, router.query.tokenId);

  if (!isShare) {
    // Not a public share
    return <>{children}</>;
  }
  return <ShareCheckAccess>{children}</ShareCheckAccess>;
};

export default Share;
