import {
  KpkApiAdvancedSearchFilter,
  KpkApiAdvancedSearchFilterFieldType,
  KpkApiAdvancedSearchFilterModifier,
  KpkApiAdvancedSearchFilterType,
} from "@keepeek/api-client";

import { PermanentFilter } from "../models/configuration/global";

export function convertToPermanentFilters(
  permanentFilters: PermanentFilter[] | undefined,
): Array<KpkApiAdvancedSearchFilter> {
  if (!permanentFilters) {
    return [];
  }
  return permanentFilters.map((f) => {
    return {
      internalFieldName: f.internalFieldName,
      fieldType: f.fieldType as unknown as KpkApiAdvancedSearchFilterFieldType,
      modifier: f.modifier as unknown as KpkApiAdvancedSearchFilterModifier,
      showSub: f.showSub,
      type: f.type as unknown as KpkApiAdvancedSearchFilterType,
      values: f.values,
    };
  });
}
