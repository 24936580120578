import { useEffect, useState } from "react";

import { KButton } from "@keepeek/refront-components";
import { Container, Paper, Typography, Box } from "@mui/material";
import { Field, FieldProps } from "@rjsf/core";
import "filepond/dist/filepond.min.css";
import { useSnackbar } from "notistack";
import { FilePond } from "react-filepond";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import { v4 as uuidv4 } from "uuid";

import { resourceUploadLoadingState } from "../../../../providers/admin/resource/atoms";
import { useFileResource } from "../../../../providers/resource/hooks";
import logger from "../../../logger-utils";
import { resourceType } from "./utils";

/**
 * Custom field to display a resource selector in any admin page
 */
const CustomSelectResource: Field = function (props: FieldProps) {
  const { t } = useTranslation();

  const setResourceUploadLoading = useSetRecoilState(resourceUploadLoadingState);
  const { enqueueSnackbar } = useSnackbar();
  const { createResource, replaceResource } = useFileResource();

  const [currentResourceUrl, setCurrentResourceUrl] = useState(props.formData.url);
  const [currentResourceId, setCurrentResourceId] = useState(props.formData.id);

  useEffect(() => {
    setCurrentResourceUrl(props.formData.url);
    setCurrentResourceId(props.formData.id);
  }, [props.formData.id, props.formData.url]);

  async function handlerOnAddFile(file) {
    if (file) {
      if (!currentResourceUrl) {
        const myUuid = uuidv4();
        let id = file.filenameWithoutExtension.replace(/[^a-zA-Z0-9]/g, "");
        id = id.concat("_").concat(myUuid.substring(0, 5));
        const url = await createResource(file.file, resourceType, id);
        if (url) {
          setResourceUploadLoading(false);
          enqueueSnackbar(t("admin:resource.create.success"), {
            variant: "success",
          });
          return { id, url };
        } else {
          setResourceUploadLoading(false);
        }
      } else {
        const url = await replaceResource(file.file, resourceType, currentResourceId);
        if (url) {
          setResourceUploadLoading(false);
          enqueueSnackbar(t("admin:resource.replace.success"), {
            variant: "success",
          });
          return { id: currentResourceId, url };
        } else {
          setResourceUploadLoading(false);
        }
      }
    }
  }

  const handleRemove = () => {
    props.onChange({ id: "", url: "" });
  };

  return (
    <Container component={Paper}>
      <Typography variant="h6">
        {props.schema.title ? t(props.schema.title) : t("admin:labels.translation.resource.label")}
      </Typography>
      {currentResourceUrl && (
        <Box
          sx={{
            gap: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginBottom: 2,
            overflowWrap: "break-word",
            wordBreak: "break-word",
          }}
        >
          <Typography variant="subtitle1" component="div">
            <a target="_blank" href={currentResourceUrl} rel="noreferrer">
              <b>{t("admin:resource.url.label")}</b>
              {currentResourceUrl}
            </a>
          </Typography>
          <KButton variant="outlined" onClick={handleRemove}>
            {t("admin:resource.url.remove")}
          </KButton>
        </Box>
      )}
      <FilePond
        onaddfilestart={() => setResourceUploadLoading(true)}
        allowMultiple={false}
        name="files"
        labelIdle={t("admin:resource.upload.label") as string}
        onaddfile={async (error, file) => {
          const resource = await handlerOnAddFile(file);
          if (resource) {
            props.onChange({ id: resource.id, url: resource.url });
          }
        }}
        onerror={(status, file) => {
          logger.error(
            "Error while loading file ",
            file?.filename,
            " with error code ",
            status.code,
          );
        }}
        credits={false}
      />
    </Container>
  );
};

export default CustomSelectResource;
