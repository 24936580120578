import React, { FC } from "react";

import { ComponentOverrideKey } from "@keepeek/refront-customers";

import { getCustomerOverrideComponent } from "../../lib/overrides";

type KScriptsProps = {};

const KScripts: FC<KScriptsProps> = function () {
  // Help to add override scripts in customers
  return <></>;
};

export default getCustomerOverrideComponent<KScriptsProps>(ComponentOverrideKey.KScripts, KScripts);
