import React, { FC } from "react";

import { EmotionCache } from "@emotion/cache";
import { CacheProvider, Global } from "@emotion/react";
import { CssBaseline, ThemeProvider } from "@mui/material";

import { UseCustomerTheme, useCustomerTheme } from "../../lib/theme-utils";

type CustomerThemeProviderProps = {
  emotionCache: EmotionCache;
};
const CustomerThemeProvider: FC<CustomerThemeProviderProps> = function ({
  emotionCache,
  children,
}) {
  const { theme, customFonts } = useCustomerTheme();

  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <CustomFontProvider customFonts={customFonts} />
        {children}
      </ThemeProvider>
    </CacheProvider>
  );
};

const CustomFontProvider: FC<Pick<UseCustomerTheme, "customFonts">> = function ({ customFonts }) {
  /**
   * there is currently a bug in MUI5 which no longer allows to use an array of custom fonts in the MUI theme
   *         (following the change of the "emotion" style engine).
   *         To see :
   *           https://jira.keepeek.com/browse/RF-1252
   *           https://github.com/mui/material-ui/issues/24966
   *         Because of this, we have to manually manage the injection of these custom fonts
   */
  return customFonts ? (
    <>
      {customFonts.map((font) => (
        <Global
          key={font?.fontFamily}
          styles={{
            "@font-face": {
              ...font,
            },
          }}
        />
      ))}
    </>
  ) : null;
};

export default CustomerThemeProvider;
