import {
  KpkApiField,
  KpkApiForm,
  KpkApiFormType,
  KpkApiMedia,
  KpkApiResponseUtils,
  KpkApiSheetField,
  KpkApiSheetFieldType,
} from "@keepeek/api-client";
import { KpkFolder, KpkMedia, KpkMetaField, KpkMetaFieldType, MediaType } from "@keepeek/commons";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import { getI18n } from "react-i18next";

import logger from "../lib/logger-utils";
import {
  Field,
  Metamodel,
  MetamodelTypeEnum,
  MetamodelTypeObject,
} from "../models/configuration/definitions/field";
import {
  Fields,
  Metamodel as MetamodelFields,
  MetamodelTypeObject as MetamodelTypeObjectFields,
} from "../models/configuration/definitions/fields";
import {
  FieldsSections,
  FieldsSectionsItemSchema,
  Metamodel as MetamodelFieldsSections,
  MetamodelTypeObject as MetamodelTypeObjectFieldsSections,
  Type,
} from "../models/configuration/definitions/fieldsSections";
import { Language, SectionSchema } from "../models/configuration/pages/element";
import { FieldType, VisualAlertsSchema } from "../models/configuration/pages/search";
import { KAdminFields } from "../models/configuration/types/kAdminFields";
import { KAdminMediaFields } from "../models/configuration/types/kAdminMediaFields";

/**
 * Thumbnail type
 */
export enum ThumbnailType {
  XLARGE = "xlarge",
  WHR = "whr",
}

/**
 * KPK Media Exhaustive list of links type
 */
export enum MediaLinksField {
  FORM = "kpk:form",
  SUB_STATUS = "kpk:custom-status",
  PREVIEW = "preview",
  WHR = "kpk:whr",
  SMALL = "kpk:small",
  MEDIUM = "kpk:medium",
  LARGE = "kpk:large",
  XLARGE = "kpk:xlarge",
}

/**
 * Embedded fields in media ressource
 */
export enum EmbeddedMediaField {
  ATTACHMENT = "attachments",
  FOLDERS = "folders",
}

/**
 * Mandatory MediaLinksField.
 */
export enum MandatoryLinksField {
  FORM = MediaLinksField.FORM,
  PREVIEW = MediaLinksField.PREVIEW,
  WHR = MediaLinksField.WHR,
  XLARGE = MediaLinksField.XLARGE,
}

/**
 * Exhaustive list of media fields.
 * Allows special processing when a media field is detected (like formatting technical field)
 */
export enum MediaField {
  ID = "id",
  STATUS = "status",
  PERMISSION = "permission",
  ANNOTATION = "annotation",
  WIDTH = "width",
  HEIGHT = "height",
  RESOLUTION = "resolution",
  MEDIA_TYPE = "mediaType",
  FILE_SIZE = "fileSize",
  FILE_SIZE_STRING = "fileSizeString",
  ORIGINAL_FILE_NAME = "originalFileName",
  CREATION_DATE = "creationDate",
  UPDATE_DATE = "updateDate",
  IMPORT_DATE = "importDate",
  TRASH_DATE = "trashDate",
  DURATION = "duration",
  DURATION_IN_SECONDS = "durationInSeconds", // Since Backoffice V.4.60.0
  PAGE_COUNT = "pageCount",
  COLORSPACE = "colorSpace",
  LATITUDE = "latitude",
  LONGITUDE = "longitude",
  ADDRESS = "address",
  DUPLICATION_STATUS = "duplicationStatus",
  THUMBNAIL_GENERATION_STATUS = "thumbnailGenerationStatus",
  XMP_SPHERICAL = "xmpSpherical",
  XMP_SPHERICAL_STEREO_MODE = "xmpSphericalStereoMode",
  XMP_SPHERICAL_PROJECTION_TYPE = "xmpSphericalProjectionType",
  CHECKSUMS = "checkSums",
  FOLDER_ID = "folderId",
  EXTENSION = "extension",
  META_MODEL_TYPE = "metaModelType",
  META_MODEL_ID = "metamodelId",
  PROVIDER_NAME = "richMediaInformation.providerName",
}

export interface KpkAllFields {
  id?: string;
  title?: string;
  type: string;
}

/**
 * Mandatory metafields.
 * Used for example when we retrieve an element to obtain at least these metafields
 */
export enum MandatoryMetaField {
  TITLE = "title",
  ORIGINAL_FILE_NAME = "originalFileName",
}

/**
 * Internal technical fields.
 * Technical field necessary for the proper functioning of the application
 */
export const internalTechnicalFields: Array<string> = [MediaField.ID];

/**
 * Return true if it's a media field
 * @param fieldName media field name (ex: creationDate)
 */
export function isMediaField(fieldName: string): boolean {
  return Object.values(MediaField).includes(fieldName as MediaField);
}

export function isMediaLinksField(fieldName: string): boolean {
  return Object.values(MediaLinksField).includes(fieldName as MediaLinksField);
}

export function isEmbeddedField(fieldName: string): boolean {
  return Object.values(EmbeddedMediaField).includes(fieldName as EmbeddedMediaField);
}

/**
 * Get KpkMetaFieldType by field name
 * @param fieldName
 */
function getKpkMetaFieldTypeByFieldName(
  kpkApiSheetFields: KpkApiSheetField[] | undefined,
  fieldName: string,
): KpkMetaFieldType {
  if (isMediaField(fieldName)) {
    if (
      fieldName === MediaField.CREATION_DATE ||
      fieldName === MediaField.IMPORT_DATE ||
      fieldName === MediaField.TRASH_DATE ||
      fieldName === MediaField.UPDATE_DATE
    ) {
      return KpkMetaFieldType.Date;
    } else {
      return KpkMetaFieldType.Text;
    }
  } else {
    const index =
      kpkApiSheetFields?.findIndex((apiSheetField) => apiSheetField.id === fieldName) ?? -1;
    if (index >= 0 && kpkApiSheetFields?.[index]) {
      return convertToKpkMetaFieldType(kpkApiSheetFields[index].type);
    }

    return KpkMetaFieldType.Text;
  }
}

/**
 * Get field label by field name
 * Do not use for media related resources because media resources have their own label that may
 * differ from field one.
 */
export function getKpkMetaFieldLabelByFieldName(
  kpkApiSheetFields: KpkApiSheetField[] | undefined,
  fieldName: string,
): string {
  const fieldWithoutLocale: string = getInternalNameWithoutLocale(fieldName);
  if (isMediaField(fieldName)) {
    return getI18n().t(`mediafield_${fieldWithoutLocale}`);
  } else {
    const sheetField: KpkApiSheetField | undefined =
      kpkApiSheetFields &&
      kpkApiSheetFields.find((apiSheetField) => apiSheetField.id === fieldWithoutLocale);
    if (sheetField && sheetField.title) {
      return sheetField.title;
    }

    return "";
  }
}

const convertToKpkMetaFieldType = (
  kpkApiSheetFieldType: KpkApiSheetFieldType | undefined,
): KpkMetaFieldType => {
  switch (kpkApiSheetFieldType) {
    case KpkApiSheetFieldType.Date:
      return KpkMetaFieldType.Date;
    case KpkApiSheetFieldType.Datetime:
      return KpkMetaFieldType.Datetime;
    case KpkApiSheetFieldType.Thesaurus:
      return KpkMetaFieldType.Thesaurus;
    case KpkApiSheetFieldType.CheckBox:
      return KpkMetaFieldType.CheckBox;
    case KpkApiSheetFieldType.Email:
      return KpkMetaFieldType.Email;
    case KpkApiSheetFieldType.Geo:
      return KpkMetaFieldType.Geo;
    case KpkApiSheetFieldType.HTML:
      return KpkMetaFieldType.HTML;
    case KpkApiSheetFieldType.MultipleList:
      return KpkMetaFieldType.MultipleList;
    case KpkApiSheetFieldType.Number:
      return KpkMetaFieldType.Number;
    case KpkApiSheetFieldType.PersonList:
      return KpkMetaFieldType.PersonList;
    case KpkApiSheetFieldType.SimpleList:
      return KpkMetaFieldType.SimpleList;
    case KpkApiSheetFieldType.Text:
      return KpkMetaFieldType.Text;
    case KpkApiSheetFieldType.TextArea:
      return KpkMetaFieldType.TextArea;
    case KpkApiSheetFieldType.URL:
      return KpkMetaFieldType.URL;
    default:
      return KpkMetaFieldType.Text;
  }
};

const convertToKpkMetaFields = (
  kpkApiMedia: KpkApiMedia,
  kpkApiSheetFields: KpkApiSheetField[] | undefined,
): Array<KpkMetaField> => {
  const ret: Array<KpkMetaField> = [];

  Object.values(MediaField).forEach((field) => {
    switch (field) {
      case MediaField.FOLDER_ID:
        // Do not push the id
        break;
      case MediaField.CHECKSUMS:
        const checkSumsValue = kpkApiMedia[MediaField.CHECKSUMS]?.md5;
        if (checkSumsValue) {
          ret.push({
            id: MediaField.CHECKSUMS,
            type: KpkMetaFieldType.Text,
            name: MediaField.CHECKSUMS,
            value: checkSumsValue,
          });
        }
        break;
      case MediaField.EXTENSION:
        // Convert original file name to extension
        const extensionValue = kpkApiMedia.originalFileName?.split(".");
        const extension = extensionValue?.[extensionValue?.length - 1] ?? "";
        ret.push({
          id: MediaField.EXTENSION,
          type: KpkMetaFieldType.Text,
          name: MediaField.EXTENSION,
          value: extension,
        });
        break;
      default:
        ret.push(convertToKpkMetaField(kpkApiMedia, kpkApiSheetFields, field));
        break;
    }
  });

  kpkApiMedia._embedded?.metadata.forEach((metadata) => {
    if (metadata.id) {
      ret.push(convertToKpkMetaField(kpkApiMedia, kpkApiSheetFields, metadata.id));
    }
  });

  return ret;
};

/**
 * Convert a KpkApiMedia with a list of specific fields name to a KpkMedia
 * @param kpkApiMedia kpk api media to convert
 */
export const convertToKpkMedia = (
  forms: KpkApiForm[] | null,
  kpkApiMedia: KpkApiMedia,
  kpkApiSheetFields: KpkApiSheetField[] | undefined,
  thumbnail: ThumbnailType = ThumbnailType.XLARGE,
): KpkMedia => {
  if (kpkApiMedia.id && kpkApiMedia._links) {
    let previewThumbnail = kpkApiMedia._links[`kpk:${thumbnail}`];
    if (!previewThumbnail) {
      // fallback to xlarge for users not granted on higher resolution
      previewThumbnail = kpkApiMedia._links[MediaLinksField.XLARGE];
    }

    const previewPdfUrl = getPreviewPdfUrl(kpkApiMedia, forms);
    const kpkMedia: KpkMedia = {
      id: kpkApiMedia.id,
      title: convertToKpkMetaField(kpkApiMedia, kpkApiSheetFields, MandatoryMetaField.TITLE),
      originalFileName: kpkApiMedia?.originalFileName ?? "",
      previewUrl: previewThumbnail.href,
      fields: convertToKpkMetaFields(kpkApiMedia, kpkApiSheetFields),
      formId: convertMediaLinkForm(kpkApiMedia),
      subStatusId: convertMediaLinkSubStatus(kpkApiMedia),
      folders: convertMediaFolders(kpkApiMedia),
      mediaType: kpkApiMedia.mediaType || "",
      formType: convertToMediaType(kpkApiMedia.formType, previewPdfUrl),
    };
    if (previewPdfUrl) {
      kpkMedia.previewPdfUrl = previewPdfUrl;
    }
    const previewAudioUrl = getPreviewAudioUrl(kpkApiMedia, forms);
    if (previewAudioUrl) {
      kpkMedia.previewAudioUrl = previewAudioUrl;
    }
    kpkMedia.attachmentCounts = kpkApiMedia.attachmentCounts;

    return kpkMedia;
  } else {
    logger.error(`kpkApiMedia do not contains mandatory field`);
    throw new Error(`kpkApiMedia do not contains mandatory field`);
  }
};

function getPreviewPdfUrl(
  kpkApiMedia: KpkApiMedia,
  forms: KpkApiForm[] | null,
): string | undefined {
  let isPdfMediaType = false;
  forms?.map((form) => {
    if (
      form.type === KpkApiFormType.Document &&
      form.id !== undefined &&
      kpkApiMedia &&
      kpkApiMedia?._links
    ) {
      const formIdInLink: string | null = KpkApiResponseUtils.extractEntityIdFromHref(
        kpkApiMedia?._links["kpk:form"].href,
      );
      if (formIdInLink === form.id.toString()) {
        isPdfMediaType = true;
      }
    }
  });
  const isPdfPreviewExtension: boolean =
    kpkApiMedia &&
    kpkApiMedia._links &&
    kpkApiMedia._links.preview &&
    kpkApiMedia._links.preview.href &&
    kpkApiMedia._links.preview.href.length > 0 &&
    (kpkApiMedia._links.preview.href as string).endsWith(".pdf");
  if (isPdfMediaType || isPdfPreviewExtension) {
    return kpkApiMedia?._links?.preview.href;
  }
}

function getPreviewAudioUrl(
  kpkApiMedia: KpkApiMedia,
  forms: KpkApiForm[] | null,
): string | undefined {
  let isAudioMediaType = false;
  forms?.map((form) => {
    if (
      form.type === KpkApiFormType.Sound &&
      form.id !== undefined &&
      kpkApiMedia &&
      kpkApiMedia?._links
    ) {
      const formIdInLink: string | null = KpkApiResponseUtils.extractEntityIdFromHref(
        kpkApiMedia?._links["kpk:form"].href,
      );
      if (formIdInLink === form.id.toString()) {
        isAudioMediaType = true;
      }
    }
  });
  if (isAudioMediaType) {
    return kpkApiMedia?._links?.preview.href;
  }
}

const convertMediaLinkForm = (kpkApiMedia: KpkApiMedia): number => {
  const formLink: string | undefined = kpkApiMedia?._links?.[MediaLinksField.FORM]?.href;
  if (formLink) {
    const formId: number = Number(formLink.split("forms/")?.[1] ?? -1);
    return formId;
  }
  return -1;
};

const convertMediaLinkSubStatus = (kpkApiMedia: KpkApiMedia): number => {
  const subStatusLink: string | undefined = kpkApiMedia?._links?.[MediaLinksField.SUB_STATUS]?.href;
  if (subStatusLink) {
    const subStatusId: number = Number(subStatusLink.split("custom-statuses/")?.[1] ?? -1);
    return subStatusId;
  }
  return -1;
};

const convertMediaFolders = (kpkApiMedia: KpkApiMedia): KpkFolder[] => {
  const folders: KpkFolder[] = [];
  if (kpkApiMedia._embedded?.folders) {
    kpkApiMedia._embedded?.folders.forEach((f) => {
      if (f.id) {
        folders.push({ id: f.id, title: f.title });
      }
    });
  }
  return folders;
};

const convertToKpkMetaField = (
  kpkApiMedia: KpkApiMedia,
  kpkApiSheetFields: KpkApiSheetField[] | undefined,
  fieldName: string,
): KpkMetaField => {
  if (isMediaField(fieldName)) {
    return {
      id: fieldName,
      type: getKpkMetaFieldTypeByFieldName(kpkApiSheetFields, fieldName),
      name: getI18n().t(`mediafield_${fieldName}`),
      value: kpkApiMedia[fieldName]?.toString() || "",
    };
  } else {
    const metadata: KpkApiField | undefined = getMetadataValueById(
      fieldName,
      kpkApiMedia._embedded?.metadata || [],
    );

    const type: KpkMetaFieldType = getKpkMetaFieldTypeByFieldName(kpkApiSheetFields, fieldName);
    let value, valueMapping;
    if (type === KpkMetaFieldType.Thesaurus) {
      value = metadata?._embedded?.value?.map((thesaurus) => thesaurus.title) || [];
      valueMapping = metadata?._embedded?.value;
    } else if (type === KpkMetaFieldType.MultipleList) {
      value = metadata?.value?.split(/,|;/).filter((v) => v !== "") || [];
    } else {
      value = metadata?.value || null;
    }

    return {
      id: fieldName,
      type,
      name: metadata?.name || fieldName,
      value,
      valueMapping,
    };
  }
};

const convertToMediaType = (
  kpkApiFormType: KpkApiFormType | undefined,
  previewPdfUrl: KpkMedia["previewPdfUrl"] | undefined,
): MediaType => {
  if (!kpkApiFormType) {
    return MediaType.OTHER;
  }

  switch (kpkApiFormType) {
    case KpkApiFormType.Picture:
      return MediaType.PICTURE;
    case KpkApiFormType.Video:
      return MediaType.VIDEO;
    case KpkApiFormType.Sound:
      return MediaType.AUDIO;
    case KpkApiFormType.RICH_MEDIA:
      return MediaType.RICH_MEDIA;
    case KpkApiFormType.Document:
      if (previewPdfUrl) {
        return MediaType.DOCUMENT;
      }
    default:
      return MediaType.OTHER;
  }
  return MediaType.OTHER;
};

const getMetadataValueById = (
  metadataId: string,
  metadatas: Array<KpkApiField>,
): KpkApiField | undefined => {
  return metadatas.find((m) => m.id === metadataId);
};

/**
 * Return field to display depending on media.
 *
 * @param fieldConfiguration full field configuration
 * @param media media
 * @forms all api forms
 */
export function getFieldToDisplay(
  fieldConfiguration: Field,
  media: KpkMedia,
  forms: KpkApiForm[] | null,
): string | undefined {
  if (!fieldConfiguration || !media) {
    return "";
  }

  if (fieldConfiguration.metamodel) {
    const metamodelConfiguration: Metamodel | undefined = fieldConfiguration.metamodel.find(
      (metamodelConfiguration) => metamodelConfiguration.metamodelId.includes(media.formId),
    );

    if (metamodelConfiguration) {
      return metamodelConfiguration.field;
    }
  }

  if (fieldConfiguration.metamodelType) {
    const metamodelType = getMetamodelType(media, forms);
    if (metamodelType) {
      const metamodelTypeConfiguration: MetamodelTypeObject | undefined =
        fieldConfiguration.metamodelType.find((metamodelTypeConfiguration) =>
          metamodelTypeConfiguration.metamodelType.includes(metamodelType),
        );

      if (metamodelTypeConfiguration) {
        return metamodelTypeConfiguration.field;
      }
    }
  }

  return fieldConfiguration.default;
}

export function getFieldsSectionsToDisplay(
  fieldsSectionsConfiguration: FieldsSections,
  element: KpkMedia,
  forms: KpkApiForm[] | null,
  language: Language,
  sections: SectionSchema[] | undefined,
): FieldsSectionsItemSchema[] | undefined {
  const ret: FieldsSectionsItemSchema[] = [];
  if (!fieldsSectionsConfiguration || !element) {
    return ret;
  }

  if (fieldsSectionsConfiguration.metamodel) {
    const conf: MetamodelFieldsSections | undefined = fieldsSectionsConfiguration.metamodel?.find(
      (conf) => conf.metamodelId.includes(element.formId),
    );

    if (conf) {
      conf.fieldsSections?.forEach((fieldSection) =>
        ret.push(addI18nSection(language, sections, fieldSection)),
      );
      return uniqBy(ret, (ite) => `${ite.value}-${ite.type}`);
    }
  }

  if (fieldsSectionsConfiguration.metamodelType) {
    const metamodelType = getMetamodelType(element, forms);
    if (metamodelType) {
      const conf: MetamodelTypeObjectFieldsSections | undefined =
        fieldsSectionsConfiguration.metamodelType.find((conf) =>
          conf.metamodelType.includes(metamodelType),
        );

      if (conf) {
        conf.fieldsSections?.forEach((fieldSection) =>
          ret.push(addI18nSection(language, sections, fieldSection)),
        );
        return uniqBy(ret, (ite) => `${ite.value}-${ite.type}`);
      }
    }
  }

  fieldsSectionsConfiguration.default?.forEach((conf) =>
    ret.push(addI18nSection(language, sections, conf)),
  );
  return uniqBy(ret, (ite) => `${ite.value}-${ite.type}`);
}

function addI18nSection(
  language: Language,
  sections: SectionSchema[] | undefined,
  fieldsSectionsItem: FieldsSectionsItemSchema,
): FieldsSectionsItemSchema {
  if (fieldsSectionsItem.type === Type.Section) {
    const section =
      sections &&
      sections
        .find((s) => s.id === fieldsSectionsItem.value)
        ?.translation?.find((t) => t.language === language);
    return {
      type: Type.Section,
      value: section?.value || fieldsSectionsItem.value,
    };
  } else {
    return {
      type: Type.Field,
      value: fieldsSectionsItem.value,
    };
  }
}

function getMetamodelType(media: KpkMedia, forms: KpkApiForm[] | null): MetamodelTypeEnum | null {
  if (!media || !forms || forms.length === 0) {
    return null;
  }
  const form = forms.find((f) => f.id === media.formId);
  if (!form) {
    return null;
  }
  return form.type as unknown as MetamodelTypeEnum;
}

/**
 * Return fields to display depending on media.
 *
 * @param fieldsConfiguration full field configuration
 * @param media media
 * @forms all api forms
 */
export function getFieldsToDisplay(
  fieldsConfiguration: Fields,
  media: KpkMedia,
  forms: KpkApiForm[] | null,
): string[] | undefined {
  if (!fieldsConfiguration || !media) {
    return [];
  }

  if (fieldsConfiguration.metamodel) {
    const metamodelConfiguration: MetamodelFields | undefined = fieldsConfiguration.metamodel.find(
      (metamodelConfiguration) => metamodelConfiguration.metamodelId.includes(media.formId),
    );

    if (metamodelConfiguration) {
      return uniq(metamodelConfiguration.fields);
    }
  }

  if (fieldsConfiguration.metamodelType) {
    const metamodelType = getMetamodelType(media, forms);

    if (metamodelType) {
      const conf: MetamodelTypeObjectFields | undefined = fieldsConfiguration.metamodelType.find(
        (conf) => conf.metamodelType.includes(metamodelType),
      );

      if (conf) {
        return uniq(conf.fields);
      }
    }
  }

  return uniq(fieldsConfiguration.default);
}

/**
 * Return flat fields list to request API with all possible fields.
 * For now, there is no other way than sending all fields to API because we cannot
 * guess media form and type search will return.
 *
 * @param fieldsConfiguration full field configuration
 */
export function getFieldsForApi(fieldsConfiguration: Fields, basket?: boolean): string[] {
  if (!fieldsConfiguration) {
    return [];
  }

  let fieldsForApi: string[] = [];
  if (fieldsConfiguration.metamodel) {
    fieldsConfiguration.metamodel.forEach((fields) => {
      fields.fields.forEach((fieldName) => {
        if (fieldName !== MediaField.FOLDER_ID || !basket) {
          if (fieldName === MediaField.FOLDER_ID) {
            fieldsForApi = fieldsForApi.concat(EmbeddedMediaField.FOLDERS);
          } else {
            fieldsForApi = fieldsForApi.concat(fieldName);
          }
        }
      });
    });
  }

  if (fieldsConfiguration.metamodelType) {
    fieldsConfiguration.metamodelType.forEach((fields) => {
      fields.fields.forEach((f) => {
        if (f !== MediaField.FOLDER_ID || !basket) {
          if (f === MediaField.FOLDER_ID) {
            fieldsForApi = fieldsForApi.concat(EmbeddedMediaField.FOLDERS);
          } else {
            fieldsForApi = fieldsForApi.concat(f);
          }
        }
      });
    });
  }
  if (fieldsConfiguration.default) {
    fieldsConfiguration.default.forEach((f) => {
      if (f !== MediaField.FOLDER_ID || !basket) {
        if (f === MediaField.FOLDER_ID) {
          fieldsForApi = fieldsForApi.concat(EmbeddedMediaField.FOLDERS);
        } else {
          fieldsForApi = fieldsForApi.concat(f);
        }
      }
    });
  }

  return fieldsForApi;
}

/**
 * Return flat fields list to request API with all possible fields needed by visual alerts calcul
 *
 * @param visualAlerts visual alerts configuration
 */
export function getVisualAlertsFieldsForApi(visualAlerts: VisualAlertsSchema[]): string[] {
  const fieldsForApi: string[] = [];
  // get fields needed by visual alerts conditions
  const fields = visualAlerts
    .filter((visualAlert) => visualAlert.fieldInternalName)
    .map((visualAlert) => visualAlert.fieldInternalName.trim());
  fieldsForApi.push(...fields);
  // retrieve sub status if at least one visual alert is based on it
  const isSubStatusNeeded = visualAlerts.some(
    (visualAlert) => visualAlert.fieldType === FieldType.SubStatus,
  );
  if (isSubStatusNeeded) {
    fieldsForApi.push(MediaLinksField.SUB_STATUS);
  }
  return fieldsForApi;
}

/**
 * Get internal name without locale
 */
export function getInternalNameWithoutLocale(internalName: string): string {
  if (internalName.indexOf(".") > 0) {
    return internalName.split(".")[0];
  }
  return internalName;
}

export const getTranslatedAndSortedMediaFields = (): KAdminMediaFields[] => {
  const res: KAdminMediaFields[] = [];
  Object.values(MediaField).forEach((field) => {
    res.push({
      id: field,
      name: getI18n().t(`mediafield_${field}`),
    });
  });
  return res.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
  });
};

export function convertToFieldList(
  sheetFields: KpkApiSheetField[] | undefined,
  metaFields: KAdminMediaFields[],
): KAdminFields[] {
  const res: KAdminFields[] = [];
  sheetFields?.map((sheetField) => {
    res.push({
      id: sheetField.id,
      name: sheetField.title,
      type: getI18n().t("metaField"),
    });
  });
  metaFields.map((metaField) => {
    res.push({
      id: metaField.id,
      name: metaField.name,
      type: getI18n().t("mediaField"),
    });
  });
  return res;
}
