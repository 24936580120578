import React, { FC, ReactNode } from "react";

import { EmotionCache } from "@emotion/cache";
import { ERROR_CODE_QUERY_PARAM } from "@keepeek/commons";
import { useRouter } from "next/router";
import { RecoilRoot } from "recoil";

import { CommonPageProps, Message } from "../../lib/commonPagePropsUtil";
import I18nProvider from "../../lib/i18n/I18nProvider";
import { NotistackProvider } from "../../lib/NotistackProvider";
import { useMandatoryInitialRecoilState } from "../../providers/mandatory/hooks";
import KError from "../Errors";
import FrontEditLayout from "../FrontEdit/FrontEditLayout";
import KHead from "../KHead";
// import AuthenticationProvider from "./AuthenticationProviderOld";
import AuthProvider from "./AuthProvider";
import Consumer from "./Consumer";
import CustomerThemeProvider from "./CustomerThemeProvider";
import KScripts from "./KScripts";
import Share from "./Share";
import { getMessagesToDisplay } from "./utils";

export type AppProps = {
  Component: FC;
  pageProps: CommonPageProps & { children?: ReactNode };
  emotionCache: EmotionCache;
};

const App: FC<AppProps> = function ({ Component, emotionCache, pageProps }) {
  const router = useRouter();
  const { setMandatoryInitializeRecoilState } = useMandatoryInitialRecoilState(pageProps);

  const displayMessages: Message[] = getMessagesToDisplay(
    pageProps.displayMessages || [],
    router.query[ERROR_CODE_QUERY_PARAM] ? (router.query[ERROR_CODE_QUERY_PARAM] as string) : "",
  );

  return (
    <RecoilRoot
      initializeState={({ set }) => {
        // Set mandatory initial recoil state
        setMandatoryInitializeRecoilState(set);
      }}
    >
      <I18nProvider initialI18nResourceBundle={pageProps.initialI18nResourceBundle}>
        <CustomerThemeProvider emotionCache={emotionCache}>
          <AuthProvider pageSecurisation={pageProps.pageSecurisation}>
            <KHead title={pageProps.title} description={pageProps.description} og={pageProps.og} />
            <KScripts />
            <NotistackProvider>
              <KError initialMessages={displayMessages}>
                <Share>
                  <Consumer>
                    <FrontEditLayout>
                      <Component {...pageProps} />
                    </FrontEditLayout>
                  </Consumer>
                </Share>
              </KError>
            </NotistackProvider>
          </AuthProvider>
        </CustomerThemeProvider>
      </I18nProvider>
    </RecoilRoot>
  );
};

export default App;
