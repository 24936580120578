import React, { FC } from "react";

import useFrontEditContentEditable from "../../providers/frontEdit/hooks/useFrontEditContentEditable";
import { EditName } from "../../providers/frontEdit/models";

const FrontEditGeneralSettingsEdition: FC = function () {
  const { sectionsEditable } = useFrontEditContentEditable(EditName.THEME);

  return <>{sectionsEditable.map((se) => se.editableContents.map((ec) => ec))}</>;
};

export default FrontEditGeneralSettingsEdition;
