export interface BusinessFilter {
    filters?: Filter[];
}

export interface Filter {
    hideFacetOccurrences?:      boolean;
    name?:                      string;
    orderDirectionFacetValues?: OrderDirectionFacetValues;
    orderTypeFacetValues?:      OrderTypeFacetValues;
    type?:                      Type;
}

export enum OrderDirectionFacetValues {
    Asc = "ASC",
    Desc = "DESC",
}

export enum OrderTypeFacetValues {
    Alphabetic = "alphabetic",
    Occurrences = "occurrences",
}

export enum Type {
    Advanced = "advanced",
    Facet = "facet",
}
