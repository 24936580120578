import { isClientSide, isServerSide } from "@keepeek/commons";
import { dayjs } from "@keepeek/refront-components";
import Axios, { AxiosError, AxiosInstance } from "axios";
import axiosRetry, { IAxiosRetryConfig } from "axios-retry";
import dayjsEn from "dayjs/locale/en";
import dayjsFr from "dayjs/locale/fr";
import getConfig from "next/config";
import { OptionsObject } from "notistack";

import { getCustomerConfig } from "../config/customer-config-utils";
import logger from "../logger-utils";
import addAxiosInterceptors from "./interceptors";

const { publicRuntimeConfig } = getConfig();

export function getAxiosClientInstance(): AxiosInstance {
  if (isServerSide()) {
    throw new Error("can't get client axios instance for server");
  }
  if (publicRuntimeConfig.apiRetryOnError) {
    axiosRetry(Axios, retryConfiguration);
  }

  // we can't create a specific Axios instance here because the "customer" project must be able to access it in order to produce requests on the client API :
  return Axios;
}

export type AdaptedHttpError = {
  errorKey: string;
  snackbarOpt: OptionsObject;
};

export function adaptAxiosHttpError(error): AdaptedHttpError | null {
  const status: number = error?.response?.status;
  const url: string = error?.config?.url;
  logger.debug(`API error ${status} for url ${url}`);
  if (error?.config?.handleErrorLocally === true) {
    logger.debug(`No notification for API error ${url} => handleErrorLocally is true`);
    return null;
  }

  // Ignore network error because they may occurs when browser cancels XHR requests on page change
  if (!status) {
    logger.debug(`No notification for network error ${url}`);
    return null;
  }

  if (error?.config?.disableNotificationErrorCodes?.includes(status)) {
    logger.debug(
      `No notification for API error ${url} => disableNotificationErrorCodes include http code ${status}`,
    );
    return null;
  }
  const snackbarOpt: OptionsObject = {
    preventDuplicate: true,
    variant: "error",
    autoHideDuration: 10000,
    persist: false,
  };
  let errorKey = "error.api.network";
  if (`${status}`.startsWith("5")) {
    errorKey = "error.api.5xx";
  } else if (`${status}`.startsWith("4")) {
    switch (`${status}`) {
      case "401":
        errorKey = "error.api.401";
        break;
      case "429":
        errorKey = "error.api.429";
        snackbarOpt.variant = "info";
        snackbarOpt.autoHideDuration = 30000;
        break;
      default:
        errorKey = "error.api.4xx";
    }
  }
  return {
    errorKey,
    snackbarOpt,
  };
}

export function interceptAxiosErrors(axiosInstance: AxiosInstance): any[] {
  const errors: any[] = [];
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      errors.push(error);
      return Promise.reject(error);
    },
  );
  return errors;
}

const MAX_RETRY_NBR: number = 5;
const retryConfiguration: IAxiosRetryConfig = {
  retries: MAX_RETRY_NBR,
  retryDelay: (retryNumber, axiosError) => {
    logger.info(
      `detect API Error : let's retry request... [retryNumber=${retryNumber}/${MAX_RETRY_NBR}] [URL=${axiosError.config.url}] [Method=${axiosError.config.method}]`,
      axiosError.toJSON(),
    );
    return axiosRetry.exponentialDelay(retryNumber) + 2000;
  },
};

export function getAxiosServerInstance({
  jwtToken,
  locale,
}: {
  jwtToken?: string;
  locale?: string;
}): AxiosInstance {
  if (isClientSide()) {
    throw new Error("can't get server axios instance for client");
  }
  const headers: any = {};
  if (jwtToken) {
    headers.Authorization = `${jwtToken}`;
  }
  if (locale) {
    headers["Accept-Language"] = locale;
  }

  const instance = Axios.create({
    headers,
  });
  if (publicRuntimeConfig.apiRetryOnError) {
    axiosRetry(instance, retryConfiguration);
  }
  initAxios(instance);
  return instance;
}

export function initAxios(axiosInstance: AxiosInstance) {
  addBaseUrl(axiosInstance);
  addXKpkClientIdHeader(axiosInstance);
  addAxiosInterceptors(axiosInstance);
}

const customer = getCustomerConfig();

/**
 * This will set URL server side and client side
 */
function addBaseUrl(axiosInstance: AxiosInstance) {
  axiosInstance.defaults.baseURL = publicRuntimeConfig.apiEndpoint;
}

function addXKpkClientIdHeader(axiosInstance: AxiosInstance) {
  if (publicRuntimeConfig.keycloak && publicRuntimeConfig.keycloak.clientId) {
    axiosInstance.defaults.headers;
    axiosInstance.defaults.headers.common["X-KPK-CLIENT-ID"] =
      publicRuntimeConfig.keycloak.clientId;
  }
}

export function isAxiosError(error: AxiosError | Error): error is AxiosError {
  return (error as AxiosError).response !== undefined;
}

/**
 * Warning this code must be called client side only, if it is set server side, locale header
 * will be shared by all users.
 */
export function updateBrowserLocale(locale: string | undefined) {
  if (locale === "fr") {
    dayjs.locale(dayjsFr.name);
  } else {
    dayjs.locale(dayjsEn.name);
  }
  if (isClientSide()) {
    if (locale) {
      getAxiosClientInstance().defaults.headers.common["Accept-Language"] = locale;
    } else if (customer.customerConfig.dataLocale) {
      getAxiosClientInstance().defaults.headers.common["Accept-Language"] =
        customer.customerConfig.dataLocale;
    }
  }
}

/**
 * Warning this code must be called client side only, if it is set server side authorization header
 * will be shared by all users compromising security.
 */
export function updateBrowserJwtToken(jwtToken: string) {
  if (isClientSide()) {
    getAxiosClientInstance().defaults.headers.common.Authorization = `Bearer ${jwtToken}`;
  }
}
