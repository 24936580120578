import {
  isClientSide,
  isRightAllowed,
  isServerSide,
  toUserRight,
  UserRight,
} from "@keepeek/commons";
import { UiSchema } from "@rjsf/core";
import { JSONSchema7Definition } from "json-schema";
import cloneDeep from "lodash/cloneDeep";

import { CONFIGURATION_SECTION_PATH } from "../../components/layout/adminMenu";
import { getAxiosClientInstance } from "../../lib/axios/axios-utils";
import Problem from "../../lib/problems/Problem";
import {
  checkBody,
  getConfigSchema,
  getConfigUiSchema,
  GET_CONFIG_UI_SCHEMA_TYPE,
} from "../../lib/schema-utils";
import { filterJSONSchemaAgainstRule } from "../config/utils";
import { apiDecode } from "./utils/api-encode";

export const axiosFetch = async (url: string): Promise<any> => {
  if (isServerSide()) {
    throw new Error("axiosFetch can't be call server side");
  }
  return (
    await getAxiosClientInstance().get(url, {
      baseURL: location.origin,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    })
  ).data;
};

export const replaceItemAtIndex = (arr, index, newValue) => {
  return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)];
};

const FRONT_EDIT_NAMESPACE_SCHEMA = "frontEdit";
const FRONT_EDIT_RIGHT_PROPERTY_SCHEMA = "right";

export const foundUserRightInUISchema = (uiSchema: UiSchema, userRight: UserRight): boolean => {
  if (
    isRightAllowed(
      userRight,
      toUserRight(uiSchema[`${FRONT_EDIT_NAMESPACE_SCHEMA}:${FRONT_EDIT_RIGHT_PROPERTY_SCHEMA}`]),
    )
  ) {
    return true;
  } else {
    for (const key of Object.keys(uiSchema)) {
      if (typeof uiSchema[key] === "object") {
        return foundUserRightInUISchema(uiSchema[key], userRight);
      }
    }
  }
  return false;
};

/**
 * Used to validate the data structure against the JSON schema corresponding to the configuration section passed as a parameter.
 * Also allows you to filter data that would be in excess of the JSON schema.
 * Note that the JSON Schema is transformed according to the user's rights (thanks to the method "filterJSONSchema").
 * @param section the configuration section to check
 * @param userRight user's right
 * @param jsonData the data to validate against the schema
 * @return data filtered against JSON schema
 */
export const parseJsonData = async <T = any>(
  section: CONFIGURATION_SECTION_PATH,
  userRight: UserRight | undefined,
  jsonData: T,
  decode: boolean | undefined = false,
): Promise<T> => {
  if (isClientSide()) {
    throw new Error("checkDataValidityAndGetFilteredData can't be call client side");
  }
  let clonedJsonData = cloneDeep(jsonData);
  // Check data validity based on user right and JSONSchema filtered :
  const schema = await getConfigSchema(section);
  const uiSchema = await getConfigUiSchema(section, GET_CONFIG_UI_SCHEMA_TYPE.FRONT_EDIT);
  checkBody(section, filterJSONSchema(schema, uiSchema, userRight), clonedJsonData, {
    removeAdditional: "all",
  });

  // Decode API data
  if (decode) {
    try {
      clonedJsonData = apiDecode(schema, uiSchema, clonedJsonData);
    } catch (error) {
      throw new Problem(
        "/problems/admin/config/invalid-config-data",
        "The data you sent to the server is invalid",
        400,
        "Cannot decode API data",
        undefined,
      );
    }
  }

  return clonedJsonData as T;
};

/**
 * Used to filter a JSON schema based on user rights.
 * The properties to be filtered are defined in the "UI" schema via the "frontEdit:right" namespace.
 * @param schemaToFilter JSON schema to filter
 * @param uiSchema UI Schema to use (will contain fields to filter according to user rights)
 * @param userRight User's right
 * @return a filtered JSON schema with the allowed properties corresponding to the user right
 */
export const filterJSONSchema = (
  schemaToFilter: JSONSchema7Definition,
  uiSchema: UiSchema,
  userRight: UserRight | undefined,
): JSONSchema7Definition => {
  if (userRight === undefined) {
    throw new Error("Can't filter JSON Schema without user right");
  }
  const clonedSchema = cloneDeep(schemaToFilter);
  const ruleCallback = (uiSchemaCB: UiSchema): boolean => {
    return foundUserRightInUISchema(uiSchemaCB, userRight);
  };
  filterJSONSchemaAgainstRule({
    schemaToFilter: clonedSchema,
    uiSchema,
    ruleCallback,
    checkOnRequired: true,
  });
  return clonedSchema;
};
