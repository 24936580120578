import { KpkApiResource } from "@keepeek/api-client";

import { Resource } from "../../../../providers/resource/hooks";

/**
 * Resource type corresonding to a folder name in BO with all refront resources
 */
export const resourceType: string = "reFront";

export function convertToKpkResources(kpkApiResources: KpkApiResource[]): Resource[] {
  const result: Resource[] = [];
  kpkApiResources.forEach(async (r) => {
    const resource: Resource = { id: r.id, fileName: r.fileName };
    result.push(resource);
  });
  return result;
}
