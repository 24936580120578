import React from "react";

import { Box, Typography } from "@mui/material";
import { Widget, WidgetProps } from "@rjsf/core";
import dynamic from "next/dynamic";
import { useTranslation } from "react-i18next";

// Can't load wysiwyg from SSR because of "window" use
const WysiwygNoSsr = dynamic(() => import("./Wysiwyg"), { ssr: false });

const CustomWysiwyg: Widget = function ({ onChange, value, label }: WidgetProps) {
  const { t } = useTranslation("admin");

  return (
    <Box data-testid="CustomWysiwyg">
      <Typography variant="subtitle1">{t(label)}</Typography>
      <WysiwygNoSsr value={value} onChange={onChange} />
    </Box>
  );
};

export default CustomWysiwyg;
