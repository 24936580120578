import React, { FC, ReactNode } from "react";

import { BaseComponentProps } from "@keepeek/refront-components";
import { Box, CircularProgress } from "@mui/material";
import clsx from "clsx";

export type KWaiterProps = { children?: ReactNode } & BaseComponentProps;

/**
 * Keepeek waiter
 * TODO: Should be removed in favor of the loader from KC
 */
export const KWaiter: FC<KWaiterProps> = function ({ className, ...rest }: KWaiterProps) {
  return (
    <Box
      className={clsx("KWaiter", className)}
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
      {...rest}
    >
      <CircularProgress />
    </Box>
  );
};
