import { translationNamespaces } from "@keepeek/refront-components";
import i18next, { i18n } from "i18next";
import Backend from "i18next-chained-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
// @ts-ignore : webpack alias. See "next.config.js"
import backend from "I18nextBackend";
import getConfig from "next/config";
import { initReactI18next } from "react-i18next";
const { publicRuntimeConfig } = getConfig();

import logger from "../../../lib/logger-utils";

export type Translations = { [key: string]: string };
// this cookie is used to save selected locale from LanguageSwitcher component
// (see https://nextjs.org/docs/advanced-features/i18n-routing#leveraging-the-next_locale-cookie)
export const LOCALE_COOKIE_NAME = "NEXT_LOCALE";

// Whatever we do, start initializing immediately.
const instance = i18next.createInstance();
const instanceInitialization = instance
  .use(initReactI18next)
  .use(Backend)
  .init(
    {
      backend: {
        backends: [
          LocalStorageBackend, // primary backend
          backend, // fallback backend
        ],
        backendOptions: [
          {
            /* options for primary backend */
            versions: publicRuntimeConfig.coreVersion,
          },
          {
            /* options for secondary backend */
          },
        ],
      },
      fallbackLng: false,
      ns: ["common", "admin", ...translationNamespaces],
      defaultNS: "common",
      keySeparator: false, // We use the json flat format for translations to make it easier to override by customers.
      interpolation: {
        escapeValue: false,
      },
      react: {
        useSuspense: false,
      },
    },
    () => logger.info("i18n initialized"),
  );

/**
 * Returns a promise that resolves to the i18next instance once it's initialized and the given language is loaded.
 */
export async function getInstance(language: string): Promise<i18n> {
  if (!instance.isInitialized) {
    await instanceInitialization;
  }

  await instance.changeLanguage(language);
  return instance;
}

/**
 * Get the i18next instance immediately, even if it's not initialized
 */
export function getInstanceImmediate(): i18n {
  return instance;
}
