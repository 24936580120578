import { useEffect } from "react";

import { Resource } from "i18next";
import { useRouter } from "next/router";
import { useSSR } from "react-i18next";

import { getInstance } from "./i18next/i18next-instance";

export default function useI18nLanguageSwitch(initialI18nResourceBundle: Resource) {
  const { locale: currentLocale, defaultLocale } = useRouter();

  const locale: string = currentLocale || defaultLocale || "";

  useSSR(initialI18nResourceBundle, locale);

  useEffect(() => {
    getInstance(locale);
  }, [locale]);

  return locale;
}
