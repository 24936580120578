export interface Mosaic {
    backgroundColor?:  string;
    carousel?:         boolean;
    centeredMosaics?:  boolean;
    content?:          TranslationSchema[];
    detailFullScreen?: boolean;
    folderIds:         number[];
    id:                string;
    showSubTitle?:     boolean;
    size:              Size;
    title?:            string;
}

export interface TranslationSchema {
    language:    Language;
    ressources?: any;
    value:       string;
}

export enum Language {
    En = "en",
    Fr = "fr",
}

export enum Size {
    L = "L",
    M = "M",
    S = "S",
    Xl = "XL",
}
