import React, { FC } from "react";

import { styled } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";

const PREFIX = "NotistackProvider";

const classes = {
  variantSuccess: `${PREFIX}-variantSuccess`,
  variantError: `${PREFIX}-variantError`,
  variantInfo: `${PREFIX}-variantInfo`,
  variantWarning: `${PREFIX}-variantWarning`,
};

const StyledSnackbarProvider = styled(SnackbarProvider)(({ theme: { palette } }) => ({
  [`& .${classes.variantSuccess}`]: {
    backgroundColor: `${palette.success.dark} !important`,
  },

  [`& .${classes.variantError}`]: {
    backgroundColor: `${palette.error.dark} !important`,
  },

  [`& .${classes.variantInfo}`]: {
    backgroundColor: `${palette.info.dark} !important`,
  },

  [`& .${classes.variantWarning}`]: {
    backgroundColor: `${palette.warning.dark} !important`,
  },
}));

export const NotistackProvider: FC<any> = function ({ children }) {
  return (
    <StyledSnackbarProvider
      maxSnack={5}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      classes={classes}
    >
      {children}
    </StyledSnackbarProvider>
  );
};
