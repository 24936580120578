import { CONFIGURATION_SECTION_PATH } from "../components/layout/adminMenu";

export function parseConfigSection(
  routeQuery?: string | string[],
): CONFIGURATION_SECTION_PATH | "" | undefined {
  let path: CONFIGURATION_SECTION_PATH | "" | undefined;
  const regexp = /[^A-Za-z0-9]*/g;
  if (typeof routeQuery === "string") {
    path = routeQuery.replace(regexp, "") as CONFIGURATION_SECTION_PATH | "";
  } else if (Array.isArray(routeQuery)) {
    path = routeQuery.map((route) => route.replace(regexp, "")).join("/") as
      | CONFIGURATION_SECTION_PATH
      | "";
  }

  return path;
}

/**
 * Check and parse for a numeric parameter
 *
 * @param queryParameter parameter value
 * @returns parsed value if valid number
 */
export function parseNumericParameter(queryParameter?: string | string[]): number | null {
  const regexp = /[^0-9]*/g;
  let num;
  if (typeof queryParameter === "string") {
    num = queryParameter;
  } else if (Array.isArray(queryParameter)) {
    num = queryParameter[0];
  }

  if (num == null || !num.match(regexp)) {
    return null;
  }

  return parseInt(num);
}

/**
 * Check and parse for a string parameter
 *
 * @param queryParameter parameter value
 * @returns parsed string value
 */
export function parseStringParameter(queryParameter?: string | string[]): string | undefined {
  let stringParameter: string | undefined;
  if (typeof queryParameter === "string") {
    stringParameter = queryParameter;
  } else if (Array.isArray(queryParameter)) {
    stringParameter = queryParameter[0];
  }
  return stringParameter;
}
